declare var DDP_ENV: any;

import { ActivatedRoute} from '@angular/router';
import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from '../../../shared/services/base.service';
@Injectable({ providedIn: 'root' })
export class MediaPressService extends BaseService {
    private jsonUrl = 'assets/json/media-press.json';  // Path to the JSON file
    constructor(private http: HttpClient, private route: ActivatedRoute) {
        super(http, route);
    }

    public getMediaPressLinks(){
        return this.http.get<any>(this.jsonUrl);
    }

}