import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthssoService } from './authsso.service';
import { AuthenticationService } from './auth.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InactivityTimerService {
  private inactivityTime = 1000 * 60 * 30;
  private warningTime = 1000 * 60 * 28;
  private warningTimeout: any;
  private logoutTimeout: any;
  getsessionActive: Subject<any> = new Subject();
  sessionActive: any;
  constructor(private authenticationService:AuthenticationService,private authService: AuthssoService, private router: Router, private ngZone: NgZone) {
    
  }

  startInactivityTimer() {
    this.resetTimer();
    this.getsessionActive.subscribe(x=>{
      if(x != null){
       this.sessionActive = x
      }
    })
    // Listen for user activity events (mousemove, keypress, click)
    window.addEventListener('mousemove', this.resetTimer.bind(this));
    window.addEventListener('keypress', this.resetTimer.bind(this));
    window.addEventListener('click', this.resetTimer.bind(this));
  }

  private resetTimer() {
    if (this.warningTimeout) {
      clearTimeout(this.warningTimeout);
    }
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
    }

    this.ngZone.runOutsideAngular(() => {
      this.warningTimeout = setTimeout(() => {
        this.ngZone.run(() => this.showWarningAlert());
      }, this.warningTime); 

      this.logoutTimeout = setTimeout(() => {
        this.ngZone.run(() => this.logoutUser());
      }, this.inactivityTime); 
    });
  }

  private logoutUser() {
    if(this.sessionActive){
      this.stopInactivityTimer()
      return;
    }
    console.log('User has been inactive for 20 minutes. Logging out...');
    this.authenticationService.logout(true)
    this.stopInactivityTimer()
  }
  showWarningAlert(){
    if(this.sessionActive){
      this.stopInactivityTimer()
      return;
    }
    this.authenticationService.setrefreshtokenBlock(true);
  }

  stopInactivityTimer() {
    if (this.warningTimeout) {
      clearTimeout(this.warningTimeout);
    }
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
    }
    window.removeEventListener('mousemove', this.resetTimer.bind(this));
    window.removeEventListener('keypress', this.resetTimer.bind(this));
    window.removeEventListener('click', this.resetTimer.bind(this));
  }
}