
<table class=font-property width="1000" style="margin:auto;border:1px solid #ddd;">
  <tr width="100%" style="box-shadow:0px 0px 11px 1px rgba(221, 221, 221, 0.5);">
       <th width="50%" align="left" style="padding: 20px 30px;">
           <img alt="American Heart Association" src="https://pmp.heart.org/assets/logo/aha-email-logo.png" style="max-width: 150px; height: auto;">       		
       </th>
       <th width="50%" align="right" style="padding: 20px 30px;">
           <img alt="Precision Medicine Platform" src="https://pmp.heart.org/assets/logo/pmp-email-logo.png" style="max-width: 150px;height: auto;">
       </th>	
   </tr>
   <tr>
       <td colspan="3" style="padding: 20px 30px;">
           <br>
           <br>
           <h1>Advance Your R&D with Unparalleled Data and Analytics</h1>
           At Nashville Biosciences, a subsidiary of Vanderbilt University Medical Center, we leverage real-world clinical and genomic data to assist in the discovery and development of new, more personalized therapeutics and diagnostics as well as assist in Real World Evidence (RWE)/Health Economic and Outcomes Research (HEOR)studies.
<BR><BR>
We have access to one of the highest quality databanks of genetically-linked electronic medical records (EMRs) ever created. BioVU® uses advanced algorithms to link de-identified records with DNA samples, providing a rich repository of billing codes, medications, labs, clinical notes, and genetic data.
<BR><BR>
3.0M EMRs with an average of 12 years of longitudinal data per sample; 265,000 DNA samples.
<BR><BR>
BioVU®: Our massive database of patient genetic and health record data contains a broad sampling of disease phenotypes and other demographic breakdowns.
<BR><BR>
PheWAS/GWAS: Utilizing powerful computational methodologies, we leverage the phenotypic information within the electronic health record as well as genetic data for drug discovery and potential side-effects.
<BR><BR>
Disease Cohorts: With BioVU®, we can develop detailed unique disease cohorts for RWE and HEOR studies to identify new pathways for drug discovery, inform clinical trial selection, and build virtual cohorts for synthetic placebo control studies.
<BR><BR>         
       </td>
   </tr>
</table>  