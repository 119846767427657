import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler, HttpHeaders } from '@angular/common/http';

import { tap } from 'rxjs/operators';
import { RequestCache } from './request-cache.service';
import { of, Observable } from 'rxjs';
import { CacheTypes } from './base.service';


@Injectable()
export class CachingInterceptor implements HttpInterceptor {
    constructor(private cache: RequestCache) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const uiCache: CacheTypes | string = req.headers.get('uicache');
        let reqClone;

        if (uiCache) {
            const headerObj = {};
            // Getting all headers.
            for (const key of req.headers.keys()) {
                // Excluding uicache header.
                if ('uicache' !== key) {
                    headerObj[key] = req.headers.get(key);
                }
            }
            // Setting cloned request with new Headers
            reqClone = req.clone({
                headers: new HttpHeaders(headerObj)
            });
        } else {
            reqClone = req.clone();
        }

        // Cache response for GET Request
        // With addCache Header.
        if (reqClone.method === 'GET' && uiCache) {
            if (uiCache === 'refresh') {
                // Refresh the cached response by sending request.
                return this.sendRequest(reqClone, next, this.cache);
            } else if (uiCache === 'add') {
                const cachedResponse = this.cache.get(reqClone);
                // Check if cached response is present else send request.
                return cachedResponse ? of(cachedResponse) : this.sendRequest(reqClone, next, this.cache);
            } else {
                return next.handle(reqClone);
            }
        } else {
            return next.handle(reqClone);
        }
    }

    sendRequest(
        req: HttpRequest<any>,
        next: HttpHandler,
        cache: RequestCache): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    cache.put(req, event);
                }
            })
        );
    }
}