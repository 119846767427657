import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../shared/services/base.service';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class SystemFailureService extends BaseService {

    constructor(private http: HttpClient, private route: ActivatedRoute) {
        super(http, route);
    }

    public serviceHealth() {
      const url = this.getBaseUrl() + 'health_service';
      const headers = this.buildHeaders();
      return of({"status": "system_healthy"}).pipe(
        map(
          // To return boolean value for system failure
          res => res.status && res.status.toLowerCase() === 'system_down'
          )
      );
    }
}
