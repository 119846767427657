// For GWTG-AFib V3 - Harvested June 2024

export var statisticData = [
    {
        "name": "COLLECTION DATES",
        "value": "2013-2023"
    },
    {
        "name": "RECORDS",
        "value": "199K"
    },
    {
        "name": "SITES",
        "value": "249"
    }
]


export var raceEthData = [
    {
        "name": "Non-Hispanic White",
        "value": 84.76,
        "extra": {
            "count": 165421
        }
    },
    {
        "name": "Non-Hispanic Black",
        "value": 7.39,
        "extra": {
            "count": 14420
        }
    },
    {
        "name": "Hispanic",
        "value": 3.99,
        "extra": {
            "count": 7787
        }
    },
    {
        "name": "Asian",
        "value": 1.08,
        "extra": {
            "count": 2111
        }
    },
    {
        "name": "Native American",
        "value": 0.51,
        "extra": {
            "count": 991
        }
    },
    {
        "name": "Pacific Islander",
        "value": 0.14,
        "extra": {
            "count": 272
        }
    },
    {
        "name": "UTD",
        "value": 2.13,
        "extra": {
            "count": 4160
        }
    }
]
export var ageData = [
    {
        "name": "18-29",
        "value": 0.56,
        "extra": {
            "count": 1107
        }
    },
    {
        "name": "30-39",
        "value": 1.47,
        "extra": {
            "count": 2930
        }
    },
    {
        "name": "40-49",
        "value": 4.28,
        "extra": {
            "count": 8519
        }
    },
    {
        "name": "50-59",
        "value": 13.32,
        "extra": {
            "count": 26538
        }
    },
    {
        "name": "60-69",
        "value": 27.88,
        "extra": {
            "count": 55526
        }
    },
    {
        "name": "70-79",
        "value": 31.33,
        "extra": {
            "count": 62407
        }
    },
    {
        "name": "80-89",
        "value": 17.0,
        "extra": {
            "count": 33854
        }
    },
    {
        "name": "90-99",
        "value": 4.06,
        "extra": {
            "count": 8089
        }
    },
    {
        "name": "\u2265 100",
        "value": 0.11,
        "extra": {
            "count": 220
        }
    }
]
export var genderData = [
    {
        "name": "Male",
        "value": 56.21,
        "extra": {
            "count": 111506
        }
    },
    {
        "name": "Female",
        "value": 43.79,
        "extra": {
            "count": 86865
        }
    }
]
export var diagnosisData = [
    {
        "name": "Atrial Fibrillation",
        "value": 86.02,
        "extra": {
            "count": 171350
        }
    },
    {
        "name": "Atrial Flutter",
        "value": 10.22,
        "extra": {
            "count": 20357
        }
    },
    {
        "name": "Both",
        "value": 3.76,
        "extra": {
            "count": 7483
        }
    }
]
export var ablationData = [
    {
        "name": "Neither",
        "value": 67.37,
        "extra": {
            "count": 134197
        }
    },
    {
        "name": "AFib Ablation",
        "value": 18.48,
        "extra": {
            "count": 36819
        }
    },
    {
        "name": "Both",
        "value": 9.52,
        "extra": {
            "count": 18956
        }
    },
    {
        "name": "AFlutter Ablation",
        "value": 4.63,
        "extra": {
            "count": 9218
        }
    }
]
