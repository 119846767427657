import { provideRouter, RouterModule, Routes } from '@angular/router';
import { importProvidersFrom } from '@angular/core';
import { HomeComponent } from './modules/home/home/home.component';
import { AboutComponent } from './modules/home/about/about.component';
import { ContactComponent } from './modules/home/contact/contact.component';
import { TosComponent } from './modules/home/tos/tos.component';
import { LoggedInComponent } from './modules/home/loggedin/loggedin.component';
import { AuthGuard } from './core/guards/auth.guard';
import { SupportGuard } from './core/guards/support.guard';
import { TechnicalSupportComponent } from './modules/home/technical-support/technical-support.component';
import { MediaPressComponent } from './modules/home/media-press/media-press.component';
import { RgrSnapshotComponent } from './modules/public/rgr-snapshot/rgr-snapshot.component';
import { DataResourceComponent } from './modules/public/data-resource/data-resource.component';
import { GwtgStrokeComponent } from './modules/public/data-resource/gwtg-stroke/gwtg-stroke.component';
import { GwtgCadComponent } from './modules/public/data-resource/gwtg-cad/gwtg-cad.component';
import { GwtgAfibComponent } from './modules/public/data-resource/gwtg-afib/gwtg-afib.component';
import { GwtgHfComponent } from './modules/public/data-resource/gwtg-hf/gwtg-hf.component';
import { GwtgResusComponent } from './modules/public/data-resource/gwtg-resus/gwtg-resus.component';
import { LpaComponent } from './modules/home/lpa/lpa.component';
import { GcrffDataChallengeComponent } from './modules/home/gcrff-data-challenge/gcrff-data-challenge.component';
import { PtfiDataChallengeComponent } from './modules/home/ptfi-data-challenge/ptfi-data-challenge.component';
import { PtfiDataResourceComponent } from './modules/home/ptfi-data-resource/ptfi-data-resource.component';
import { PricingComponent } from './modules/home/pricing/pricing.component';
import { ToolsComponent } from './modules/home/tools/tools.component';
import { BackgroundHistoryComponent } from './modules/background-history/background-history.component';
import { PressResourcesComponent } from './modules/press-resources/press-resources.component';
import { GettingStartedComponent } from './modules/home/getting-started/getting-started.component';
import { UserGuideComponent } from './modules/home/user-guide/user-guide.component';
import { FaqComponent } from './modules/home/faq/faq.component';
import { AuthSsoComponent } from './core/auth-sso/auth-sso.component';
import { GwtgBiovuComponent } from './modules/public/data-resource/gwtg-biovu/gwtg-biovu.component';
import { GwtgUmichComponent } from './modules/public/data-resource/gwtg-umich/gwtg-umich.component';
import { GwtgHihgwasComponent } from './modules/public/data-resource/gwtg-hihgwas/gwtg-hihgwas.component';
import { AdLoggedinComponent } from './modules/home/ad-loggedin/ad-loggedin.component';
import { AdgroupLoginComponent } from './modules/home/adgroup-login/adgroup-login.component';
import { AddiLoginComponent } from './modules/home/addi-login/addi-login.component';
import { GwtgSdohCadComponent } from './modules/public/data-resource/gwtg-sdoh-cad/gwtg-sdoh-cad.component';
import { HelpComponent } from './core/mfa/help/help.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  {path: 'sso',component: AuthSsoComponent},
  {path: 'help',component: HelpComponent},
  { path: 'Biovu', component: GwtgBiovuComponent},
  { path: 'umich-data', component: GwtgUmichComponent},
  { path: 'ih-gwas', component: GwtgHihgwasComponent},
  {path: 'azuread-loggedin',component: AdgroupLoginComponent},
  { path: 'adgroup-loggedin', component: AdLoggedinComponent},
  {path: 'addi-login',component: AddiLoginComponent},
  { path: 'lpadatachallenge', component: LpaComponent, data: { title: 'LPA Data Challenge' } },
  { path: 'gcrffdatachallenge', component: GcrffDataChallengeComponent, data: { title: 'GCRFF Data Challenge' } },
  { path: 'ptfidatachallenge', component: PtfiDataChallengeComponent, data: { title: 'PTFI Data Challenge' } },
  { path: 'ptfi-data-resource', component: PtfiDataResourceComponent, data: { title: 'PTFI Data Resources' } },
  { path: 'faq', component: FaqComponent, data: { title: 'Frequently Asked Questions' } },
  { path: 'background-history', component: BackgroundHistoryComponent, data: { title: 'Background History' } },
  { path: 'lpa-data-challenge', redirectTo: 'lpadatachallenge' },
  { path: 'learn', loadChildren: () => import('./modules/learn/learn.module').then(m => m.LearnModule), data: { title: 'Learn Mor' } },
  { path: 'request', loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule), data: { title: 'Data' } },
  { path: 'workspace', loadChildren: () => import('./modules/workspace/workspace.module').then(m => m.WorkspaceModule), data: { title: 'Workspace' } },
  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'jupyter_notebooks', loadChildren: () => import('./modules/jupyter/jupyter.module').then(m => m.JupyterModule), data: { title: 'Jupyter Notebooks' } },
  { path: 'rgr-snapshot', component: RgrSnapshotComponent, data: { title: 'Research Goes Red Data Snapshot' } },
  { path: 'gwtg-stroke-data-resource', component: GwtgStrokeComponent, data: { title: 'GWTG Stroke Data Resource' } },
  { path: 'gwtg-afib-data-resource', component: GwtgAfibComponent, data: { title: 'GWTG AFIB Data Resource' } },
  { path: 'gwtg-cad-sdoh-data-resource', component: GwtgSdohCadComponent, data: { title: 'GWTG SDOH CAD Data Resource' } },
  { path: 'gwtg-cad-data-resource', component: GwtgCadComponent, data: { title: 'GWTG CAD Data Resource' } },
  { path: 'gwtg-hf-data-resource', component: GwtgHfComponent, data: { title: 'GWTG HF Data Resource' } },
  { path: 'gwtg-resus-data-resource', component: GwtgResusComponent, data: { title: 'GWTG RESUS Data Resource' } },
  { path: 'about', component: AboutComponent, data: { title: 'About' } },
  { path: 'technical-support', component: TechnicalSupportComponent, data: { title: 'Technical Support' } },
  { path: 'pricing', component: PricingComponent, data: { title: 'Uses & Pricing' } },
  { path: 'getting-started', component: GettingStartedComponent, data: { title: 'Getting Started' } },
  { path: 'user-guide', component: UserGuideComponent, data: { title: 'User guide' } },
  { path: 'tools', component: ToolsComponent, data: { title: 'Tools & Features' } },
  { path: 'tos', component: TosComponent, data: { title: 'Terms of Service' } },
  { path: 'media-press', component: MediaPressComponent, data: { title: 'Press Resources' } },
  { path: 'loggedin', component: LoggedInComponent },
  { path: 'request-access', loadChildren: () => import('./modules/request-access/request-access.module').then(m => m.RequestAccessModule), canActivate: [AuthGuard], data: { title: 'Request Datasets' } },
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard], data: { title: 'Admin' } },
  { path: 'support', loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule), canActivate:[SupportGuard], data: { title: 'Support' } },
  { path: 'approver', loadChildren: () => import('./modules/approver/approver.module').then(m => m.ApproverModule), data: { title: 'Approver' } },
  { path: 'gcrff', loadChildren: () => import('./modules/gcrff/gcrff.module').then(m => m.GCRFFModule), canActivate: [AuthGuard], data: { title: 'GCRFF' } },
  { path: 'duke-ninds', loadChildren: () => import('./modules/duke-ninds/duke-ninds.module').then(m => m.DukeNindsModule), data: { title: 'Duke Ninds' } },
  { path: 'newrequest', loadChildren: () => import('./modules/sftp/sftp.module').then(m => m.SftpModule), canActivate: [AuthGuard], data: { title: 'SFTP Request' } },
  { path: 'profile/user', loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard], data: { title: 'My Profile' } },
  { path: 'published/:id', loadChildren: () => import('./modules/published/published.module').then(m => m.PublishedModule), data: { title: '' } },
  { path: 'security-information', loadChildren: () => import('./modules/security-information/security-information.module').then(m => m.SecurityInformationModule), data: { title: 'Security Information' } },
  { path: '**', redirectTo: '' }
];

export const appRoutingProviders = [];

export const routing = RouterModule.forRoot(routes, { useHash: true });
