import { Component } from '@angular/core';

@Component({
  selector: 'app-para-block',
  templateUrl: './para-block.component.html',
  styleUrls: ['./para-block.component.scss']
})
export class ParaBlockComponent {

  constructor() { }

}
