<div class="container container-wide ptfi-container">

  <div class="row">
    <div class="col-9">
      <div class="ptfi-header">
        <img src="assets/images/ptfi/PTFI_logo.png" class="ptfi-logo" />
        <h1 class="ptfi-header-text">Periodic Table of Food Initiative Data Exploration Challenge</h1>
      </div>

      <div class="ptfi-article-main">
        <p>
          The <a href="https://foodperiodictable.org/" target="_blank">Periodic Table of Food Initiative</a> (PTFI) invites researchers to explore the initial launch of data that has been collected 
          and submit a research analysis which will be considered for a cash prize.  The winning project will be eligible to receive 
          a $20,000 USD cash prize.
        </p>

        <p>Diet-related diseases account for 1 in 5 deaths around the world and dominant farming practices compromise the health of our planet.  
          The PTFI is enabling a global ecosystem to provide standardized, comprehensive, and robust data on food biomolecular composition 
          through an open-access data platform for a wide variety of data users. We need to transform food systems through data-driven 
          solutions for healthier people and a healthier planet. </p>

        <p>Participants will have access to the PTFI dataset consisting of the first 500 foods collected via the American Heart Association’s <a href="https://pmp.heart.org" target="_blank">Precision Medicine Platform</a>.  
          The Precision Medicine Platform is a cloud-based system that allows researchers to collaborate and analyze datasets from any computer in the world using a 
          secure environment and the power of machine learning.  Researchers can also bring their own data to help better understand the role food 
          plays in medicine.  </p>

        <hr>

        <h2>POTENTIAL SCIENTIFIC QUESTIONS FOR DATA EXPLORATION (including use cases)</h2>

        

        <ul>
          <li>Identify the foods that provide the greatest diversity and quantity of specific food components that are associated with decreased risk of disease (heart disease, Alzheimer’s, dementia, cancer, diabetes, autoimmune diseases).</li>
          <li>
              Explore the associations between specific food components and risk factors including but not limited to sleep and blood pressure.
          </li>
          <li>Explore the emerging trends in functional medicine and precision nutrition and identify the foods that individuals might include in their diet to replace a dependency on supplements (i.e. magnesium).</li>
          <li>Identify factors in metadata that impact diversity and quantity of food components.
          </li>
        </ul>
        <p>*Participants must use data provided in the PTFI data and may further choose to bring their own data.  </p>  

        <hr>

        <h2>DATA</h2>

          <ul>
            <li>PTFI first 500 foods
              <ul>
                <li>Review our <a routerLink="/ptfi-data-resource" >PTFI data resource page</a> on the Precision Medicine Platform website for more information.</li>
                <li>Review the <a href="https://ptfi.community.versobio.com/c/knowledgebase/data-dictionary/100" target="_blank">data dictionary</a>.</li>
              </ul>
            </li>
            <li>Participants are also encouraged to bring their own data (BYOD) to use in conjunction with the dataset provided.  Each participant can upload data into their own private workspace on the Precision Medicine Platform; no one else will have access to your data.</li>
            <li>IMPORTANT: All data must be uploaded and analyzed in a workspace on the Precision Medicine Platform to meet the data exploration challenge submission requirements.</li>
          </ul>

        <hr>

        <h2>HOW TO PARTICIPATE</h2>

        <ol>
          <li><a href="https://app.smartsheet.com/b/form/028ca62da18d4a2ba798592fec8b5efd" target="_blank">Register</a> for the PTFI data exploration challenge.
            <ol>
              <li type="a">If approved, you will receive a confirmation with instructions on how to request a workspace and the dataset on the Precision Medicine Platform.
                <ol>
                  <li type="i">You will need to acknowledge and accept the PTFI Terms and Conditions of Data Use.</li>
                </ol>
              </li>
              <li type="a">Each challenge participant/team will be provided with a workspace on the AHA’s Precision Medicine Platform at no cost to conduct all analyses. Use of the workspace is mandatory for participation in the challenge. </li>
            </ol>
          </li>
          <li>Submit your research analysis by the deadline of <u>November 1, 2024, by 5 pm CT.</u></li>

          
        </ol>

        <hr>

        <h2>WHO SHOULD APPLY</h2>

        <ul>
          <li>Researchers from non-profit institutions interested in exploring food composition data to improve human and planetary health.
            <ul>
              <li>Must be able to code using Python, R, or SAS.  Please review the <a href="https://pmp.heart.org/workspace/about" target="_blank">workspace tools</a> available on the Precision Medicine Platform for more information.</li>
              <li>Diversity of perspectives and experiences is crucial to our success, so we encourage all who meet the above criteria to apply.</li>
            </ul>
          </li>
        </ul>

        <hr>

        <h2>CHALLENGE TIMELINE</h2>

        <ol>
          <li><span class="ptfi-subtitle">April 24, 2024:</span> <a href="https://app.smartsheet.com/b/form/028ca62da18d4a2ba798592fec8b5efd" target="_blank"> Registration</a> opens.</li>
          <li><span class="ptfi-subtitle">November 1, 2024 by 5 pm CT:</span> Data Exploration Challenge Submissions due.  Refer to submission requirements below.</li>
          <li><span class="ptfi-subtitle">December 2, 2024:</span> Finalists will be selected and notified by this date.</li>
          <li><span class="ptfi-subtitle">December 2nd and beyond: </span>
              <ol>
                <li type="a">Finalists and other participants (if they desire) work towards abstract and publication.</li>
                <li type="a">Participants may submit an abstract to an upcoming AHA conference (i.e. EPI Lifestyle 2025, Scientific Sessions 2025) or other industry conferences of their choice. 
                </li>
              </ol>
              
          </li>
        </ol>

        <hr>

        <h2>PRIZES AND EVALUATION</h2>

        <h3>Prizes</h3>

        <p>Three prizes will be awarded to the top submissions. Recipients of the grand, second or third place prize will be required to publish a manuscript detailing the winning solutions to receive the cash prize.   </p>

        <ul>
          <li>Grand prize: $20,000
          </li>
          <li>Second place: $5,000
          </li>
          <li>Third place: $5,000</li>
        </ul>

        <h3>Evaluation</h3>

        <p>Data Exploration Challenge submissions will be reviewed by a peer review committee that includes experts and volunteers from the PTFI. Reviewers will evaluate the novel information learned from the analyses that address the focus of the research question.  Specifically, the committee will assess the:</p>

        <ul>
          <li>Findings or results of the data analyses
          </li>
          <li>Data and data analyses (methods that support the findings)</li>
          <li>Novel information learned</li>
          <li>Overall impact of the findings and analyses on the question’s focus</li>
        </ul>

        <hr>

        <h2>DATA CHALLENGE SUBMISSION</h2>

        <h3>Submission Requirements</h3>

        <p>The Precision Medicine Platform provides an easy-to-use, web-based user interface that allows participants to
          access a secure, cloud-based environment. It contains a variety of standard software and packages such as
          Python
          and R. The platform workspace leverages <a href="https://pmp.heart.org/jupyter_notebooks/about"
            target="_blank">Jupyter Notebooks</a> and <a href="https://bookdown.org/yihui/rmarkdown/notebook.html"
            target="_blank">RStudio</a> to allow users to create notebooks to
          document and display results.</p>

        <p style="text-decoration: underline;">Each participant must submit a notebook from their individual workspace
          in
          the Precision Medicine Platform that
          includes the following:</p>

        <ul>
          <li>Full name of corresponding applicant and team members </li>
          <li>Institution (if applicable)</li>
          <li>Email address of corresponding applicant</li>
          <li>Phone number of corresponding applicant</li>
          <li>A 250-word description of the findings and how they can be applied.</li>
          <li>Description of data analysis and datasets used for establishing, testing, and validating models.</li>
          <li>Figures or tables (if applicable)</li>
          <li>Summary</li>
        </ul>

        <p>Please <a href="/assets/downloads/lpa/PMP_Notebook_instructions_Data_Challenge.pdf" target="_blank">click
            here</a> to review the detailed instructions for how to submit your notebook.</p>

        <p>Precision Medicine Platform workspaces will remain available to participants after the challenge is over 
          if you are working towards publication of a manuscript.  If your data challenge submission is not selected as a 
          finalist and you do not want to publish your research, we will decommission your workspace shortly after the conclusion 
          of the data challenge.
        </p>

        <hr>

        <h2>Contact Information</h2>

        <p>Please email <a href="mailto:pmp@heart.org">pmp&#64;heart.org</a> with any questions regarding the data
          challenge.
        </p>



      </div>
    </div>
  </div>
</div>