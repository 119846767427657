import { Component, Input } from '@angular/core';
import { settings } from '../chart-common';
import { SharedModule } from '../../../../../shared/shared.module';
@Component({
  selector: 'app-horizontal-bar',
  templateUrl: './horizontal-bar.component.html',
  styleUrls: ['./horizontal-bar.component.scss', '../chart-common.scss'],
  imports: [SharedModule],
  standalone: true
})
export class HorizontalBarComponent {
  @Input() chartData: any;

  @Input() scheme = settings.redToWhite;
  @Input() showXAxis = settings.showXAxis;
  @Input() showYAxis = settings.showYAxis;
  @Input() showGridLines = settings.showGridLines;
  @Input() showLegend = settings.showLegend;
  @Input() legendTitle = settings.legendTitle;
  @Input() showXAxisLabel = settings.showXAxisLabel;
  @Input() showYAxisLabel = settings.showYAxisLabel;
  @Input() xAxisTickFormatting = settings.formatPercentage;
  @Input() yAxisTickFormatting = settings.formatPercentage;
  @Input() trimYAxisTicks = settings.trimYAxisTicks;
  @Input() xAxisLabel = settings.xAxisLabel;
  @Input() yAxisLabel = settings.yAxisLabel;
  @Input() showDataLabel = settings.showDataLabel;
  @Input() barPadding = settings.barPadding;
  @Input() dataLabelFormatting = settings.formatPercentage;
  @Input() tooltipFormatting = settings.tooltipFormatting;

  view = settings.view;
  
  constructor() { 
  }

}
