import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { finalize, Observable, tap, throwError } from 'rxjs';
import jwt_decode from 'jwt-decode'; 
import { AuthenticationService } from '../../shared/services/auth.service';
import { AuthssoService } from '../../../app/shared/services/authsso.service';
import { InactivityTimerService } from '../../../app/shared/services/inactivity-timer-service.service';
import { LoaderService } from '../../../app/shared/services/loaderService';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    urlsToIgnore: any = ['/logout', 'api.ipify.org'];
    constructor(private inactivityTimerService:InactivityTimerService,private authenticationService: AuthenticationService,private authssoService:AuthssoService, private loaderService: LoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authssoService.currentUserValue;
        if (currentUser && currentUser['IdToken']) {
            // Check token expiration before sending the request
            const decodedToken: any = jwt_decode(currentUser['IdToken']);
            const currentTime = new Date().getTime();
            
            if (decodedToken.exp * 1000 < currentTime) {
              // Token is expired, log out the user
              this.authenticationService.logout(true)
              this.inactivityTimerService.stopInactivityTimer()
              return throwError(() => new Error('Session expired'));
            }
          }
        if (currentUser && currentUser['IdToken']) {
            if( !this.canIgnoreUrl(request.url)) {
                if(!request.url.includes("s3.amazonaws.com")){
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${currentUser['IdToken']}`
                        }
                    });
                }else if(request.url.includes("s3.amazonaws.com")){
                    this.inactivityTimerService.getsessionActive.next(true)
                }
                
            }
        }
        if(this.loaderService.pageLoaderEnable){
            this.loaderService.showLoader();
        }
        return next.handle(request).pipe(
            tap({
              next: (event) => {
                if (event instanceof HttpResponse) {
                    this.loaderService.hideLoader();
                  }
               },
              error: () => {
                if(request.url.includes("s3.amazonaws.com")){
                    this.inactivityTimerService.getsessionActive.next(false)
                }
                this.loaderService.hideLoader();
              },
              complete: () =>{
                if(request.url.includes("s3.amazonaws.com")){
                    this.inactivityTimerService.getsessionActive.next(false)
                }
                // Hide loader when request completes
                this.loaderService.hideLoader();
            }
            }),
            finalize(() => {
                // Ensure loader is hidden regardless of success or error
                this.loaderService.hideLoader();
              })
          );;
    }

    canIgnoreUrl(requestUrl){
        for(let url of this.urlsToIgnore){
            if( requestUrl.indexOf(url) != -1)
                return true;
        }
        return false;
    }
}