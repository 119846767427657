import { Component } from '@angular/core';
import { ScriptService} from '../../scripts.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true
})
export class FooterComponent {

  constructor(public script: ScriptService) { }

}
