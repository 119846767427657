import { Component } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
@Component({
  selector: 'app-background-history',
  templateUrl: './background-history.component.html',
  styleUrls: ['./background-history.component.scss'],
  imports: [SharedModule], 
  standalone: true
})
export class BackgroundHistoryComponent {

  constructor() { }
}
