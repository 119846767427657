// For GWTG-HF V7 -- Generated 2024-22-28 17:22:41 
export var statisticData = [
    {
        "name": "COLLECTION DATES",
        "value": "2005-2023"
    },
    {
        "name": "RECORDS",
        "value": "2.5M"
    },
    {
        "name": "SITES",
        "value": "1.3K"
    }
] 

export var raceEthData = [
    {
        "name": "Non-Hispanic White",
        "value": 60.76,
        "extra": {
            "count": 1575080
        }
    },
    {
        "name": "Non-Hispanic Black",
        "value": 20.87,
        "extra": {
            "count": 540904
        }
    },
    {
        "name": "Hispanic",
        "value": 7.78,
        "extra": {
            "count": 201572
        }
    },
    {
        "name": "Asian",
        "value": 2.11,
        "extra": {
            "count": 54789
        }
    },
    {
        "name": "Pacific Islander",
        "value": 0.54,
        "extra": {
            "count": 14046
        }
    },
    {
        "name": "Native American",
        "value": 0.39,
        "extra": {
            "count": 10096
        }
    },
    {
        "name": "UTD",
        "value": 7.56,
        "extra": {
            "count": 195874
        }
    },
    
    
    
] 

export var ageData = [
    {
        "name": "18-29",
        "value": 0.58,
        "extra": {
            "count": 14939
        }
    },
    {
        "name": "30-39",
        "value": 2.05,
        "extra": {
            "count": 53083
        }
    },
    {
        "name": "40-49",
        "value": 5.59,
        "extra": {
            "count": 144886
        }
    },
    {
        "name": "50-59",
        "value": 12.74,
        "extra": {
            "count": 330278
        }
    },
    {
        "name": "60-69",
        "value": 19.81,
        "extra": {
            "count": 513470
        }
    },
    {
        "name": "70-79",
        "value": 24.36,
        "extra": {
            "count": 631446
        }
    },
    {
        "name": "80-89",
        "value": 25.34,
        "extra": {
            "count": 656898
        }
    },
    {
        "name": "90-99",
        "value": 9.27,
        "extra": {
            "count": 240196
        }
    },
    {
        "name": "\u2265 100",
        "value": 0.28,
        "extra": {
            "count": 7165
        }
    },
] 

export var genderData = [
    {
        "name": "Male",
        "value": 52.48,
        "extra": {
            "count": 1313538
        }
    },
    {
        "name": "Female",
        "value": 47.52,
        "extra": {
            "count": 1189614
        }
    },
] 

export var diagnosisData = [
    {
        "name": "Reduced \u2264 40%",
        "value": 47.13,
        "extra": {
            "count": 1110085
        }
    },
    {
        "name": "Mildly Reduced 41-49%",
        "value": 9.57,
        "extra": {
            "count": 225421
        }
    },
    {
        "name": "Preserved \u2265 50%",
        "value": 43.3,
        "extra": {
            "count": 1020036
        }
    },
] 

