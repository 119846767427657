import { Component } from '@angular/core';
import { HorizontalBarComponent } from '../charts/horizontal-bar/horizontal-bar.component';
import { VerticalBarComponent } from '../charts/vertical-bar/vertical-bar.component';
import { DataResourceComponent } from '../data-resource.component';
import { DrSidebarComponent } from '../dr-sidebar/dr-sidebar.component';
import { DrTileComponent } from '../dr-tile/dr-tile.component';
import { ageData, raceEthData, genderData, diagnosisData, statisticData } from './gwtg-stroke-data';
import { DrRowComponent } from '../dr-row/dr-row.component';
import { SharedModule } from '../../../../shared/shared.module';
import { SectionHeaderComponent } from '../../rgr-snapshot/section-header/section-header.component';
@Component({
  selector: 'app-gwtg-stroke',
  templateUrl: './gwtg-stroke.component.html',
  styleUrls: ['./gwtg-stroke.component.scss', '../data-resource.component.scss'],
  providers: [DataResourceComponent, HorizontalBarComponent, VerticalBarComponent,
    DrSidebarComponent, DrTileComponent, DrRowComponent],
    imports: [SharedModule, VerticalBarComponent, HorizontalBarComponent, DataResourceComponent,DrSidebarComponent, SectionHeaderComponent ],
  standalone: true
})
export class GwtgStrokeComponent {
  ageData: any;
  raceEthData: any;
  genderData: any;
  diagnosisData: any;
  statisticData: any;
  constructor() {
    Object.assign(this, { ageData, raceEthData, genderData, diagnosisData, statisticData })
  }
}
