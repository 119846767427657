import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    pageLoaderEnable=true;
    private loaderSubject = new BehaviorSubject<boolean>(false);
    loaderState = this.loaderSubject.asObservable();

    showLoader() {
        this.loaderSubject.next(true);
    }

    hideLoader() {
        this.loaderSubject.next(false);
    }
}
