import { Injectable, Injector } from '@angular/core';
import { CognitoUserPool, CognitoUser, CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import { Router } from '@angular/router';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import QRCode from 'qrcode';
import { DomSanitizer } from '@angular/platform-browser';
import { LoaderService } from './loaderService';



const poolData = {
  UserPoolId: environment.cognito.UserPoolId,
  ClientId: environment.cognito.ClientId, 
};

const userPool = new CognitoUserPool(poolData);
@Injectable({
  providedIn: 'root'
})
export class AuthssoService {
  private auth: CognitoAuth;
  public signupSuccessEvent = new Subject<string>();
  public signupFailEvent = new Subject<string>();
  public signupVerificationConfirmEvent = new Subject<string>();
  public signInerrorAlert = new Subject<any>()
  public signInMfaerrorAlert = new Subject<any>()
  public resetPasswordAlert = new Subject<any>()
  public currentUserSubject: BehaviorSubject<any>;
  public sendPathUrl = new BehaviorSubject<any>('')
  public getSecretCode = new Subject<any>();
  public mfaEnabled = new BehaviorSubject<any>('')
  public loggedinUser;
  public currentUser: Observable<any>;
  private refreshCounter = 0;
  private readonly maxRefreshAttempts = 8;

  authData: {
    ClientId: string; AppWebDomain: string;
    TokenScopesArray: string[];
    RedirectUriSignIn: string; RedirectUriSignOut: string; userPoolId: string; IdentityProvider: string; response_type: string; responseType: string; ResponseType: string;
  };  
  public authenticationResult: any;
  userDetailsData: any;
  azureadlogintokens: any;
  refreshTimer: any;
  signInType: any;
  cognitoUser: CognitoUser;
  qrCodeImage: string;
  mfaType: string;
  verifieduser: boolean =false;
  setData(authenticationResult: any) {
    this.authenticationResult = authenticationResult;
  }
  getData() {
    return this.authenticationResult;
  }
  setAzureadlogintokens(azureadlogintokens:any){
    this.azureadlogintokens = azureadlogintokens
  }
  getAzureadlogintokens() {
    return this.azureadlogintokens;
  }
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
}
constructor(private loaderService:LoaderService,private injector: Injector,private router: Router,private sanitizer: DomSanitizer) {
  const appClientId = environment.cognito.ClientId;
    const cognitoDomain = environment.cognito.cognitoDomain;
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.authData = {
      ClientId: appClientId,
      AppWebDomain: cognitoDomain,
      TokenScopesArray: ['email', 'openid', 'profile'],
      RedirectUriSignIn: window.location.protocol + '//' + window.location.host + '/azuread-loggedin',
      RedirectUriSignOut:  window.location.protocol + '//' + window.location.host,
      userPoolId: environment.cognito.UserPoolId,
      IdentityProvider: 'AzureAD',
      response_type: 'code',
      responseType: 'code',
      ResponseType: 'code'
    };
    this.auth = new CognitoAuth(this.authData);

    this.auth.userhandler = {
      onSuccess: (result) => {
        //this.azureadlogintokens= result
        this.scheduleTokenRefresh();
        this.azureadlogintokens = {"AccessToken":result.getAccessToken().getJwtToken(),
          "ExpiresIn":result.getIdToken().payload.auth_time,
          "IdToken":result.getIdToken().getJwtToken(),
          "refreshToken":result.getRefreshToken().getToken(),
        };
        if(result.getAccessToken()){
        this.getADUserDetails(result.idToken.payload)
        }
      },
      onFailure: (err) => {
        console.error('Error:', err);
      }
    };
  }

  signUp(data) {
    const attributeList = [];
    let username = data.emailId;
    let password = data.password;
    let attributes = { "given_name": data.firstName, "family_name": data.lastName, "custom:organization":data.organization};
    for (const key in attributes) {
      attributeList.push(new CognitoUserAttribute({ Name: key, Value: attributes[key] }));
    }

    userPool.signUp(username, password, attributeList, [], (err:any, result:any) => {
      if (err) {
        this.signupFailEvent.next(err.message)
        console.error(err);
        return err;
      }
      if (result) {
        this.signupSuccessEvent.next(result.user['username'])
      }
    });
  }

  loginwithad(type) {
    this.signInType=type;
    if(this.signInType === 'ADDI_LOGIN'){
      this.auth.RedirectUriSignIn = window.location.protocol + '//' + window.location.host + '/addi-login'
      this.auth.IdentityProvider = environment.cognito.identityProvider
    }
    this.auth.responseType = "CODE"
    this.auth.getSession()
  }
  resetPassword(oldpassword,newpassword){
    this.loaderService.showLoader()
    const user = this.getCurrentUser();
    if(user){
      user.getSession((err: any, session: any) => {
        if (err) {
          this.loaderService.hideLoader()
          console.error("Error fetching session: ", err);
          return;
        }
      })
    }
    user.changePassword(oldpassword, newpassword, (err: any, result: any) => {
      if (err) {
        this.loaderService.hideLoader()
        this.resetPasswordAlert.next(false)
        console.error("Error changing password: ", err);
        return;
      }else{
        this.loaderService.hideLoader()
        this.resetPasswordAlert.next(true)
        console.log("Password changed successfully: ", result);
      }
      
    });
  }


  signOut(): Observable<any> {
    return new Observable((observer) => {

      this.auth.signOut()
      .then(() => {
        localStorage.clear();
        clearTimeout(this.refreshTimer);
        this.refreshCounter = 0;
        this.router.navigate(['/home']);
      })
      observer.complete();
    })
  }

  forgotPassword(username: string): Observable<void> {
    this.loaderService.showLoader()
    return new Observable(observer => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      });
      cognitoUser.forgotPassword({
        onSuccess: (success) => {
          this.loaderService.hideLoader()
          observer.next(success); // Emit a success signal
          observer.complete(); // Mark the observable as complete
          console.log('Password reset code sent successfully.');
          return success;
        },
        onFailure: (err) => {
          this.loaderService.hideLoader()
          //observer.error(err); // Emit an error signal
          observer.next(err['code']); // Emit a success signal
          observer.complete(); // Mark the observable as complete
          console.error('Error during password reset:', err);
          return err;
        },
      });
    })
  }

  confirmPassword(username: string, verificationCode: string, newPassword: string) : Observable<any> {
    return new Observable(observer => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: (success) => {
        observer.next("success"); // Emit a success signal
        console.log('Password reset successful.');
      },
      onFailure: (err) => {
        observer.next(err.message); // Emit a success signal
        console.error('Error confirming password:', err);
      },
    });
  });
  }
  confirmUser(username: string, confirmationCode: string) {
    const userData = {
      Username: username, // User's username
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
      if (err) {
        if (err.message.includes("Unrecognizable")) {
          this.signupVerificationConfirmEvent.next("SUCCESS");
          console.log('Confirmation successful:');
        } else {
          console.error('Error during confirmation:', err.message || JSON.stringify(err));
        }
      } else {
        this.signupVerificationConfirmEvent.next("SUCCESS")
        console.log('Confirmation successful:', result);
      }
    });
  }

  signIn(username: string, password: string): Promise<any> {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,      
    });

    const userData = {
      Username: username,
      Pool: userPool,
    };

    this.cognitoUser = new CognitoUser(userData);
    this.cognitoUser.setAuthenticationFlowType('USER_SRP_AUTH');
    return new Promise((resolve, reject) => {
      this.cognitoUser.authenticateUser(authenticationDetails,this.cognitoCallbacks)
    });
  }
  cognitoCallbacks = {
    onSuccess: (result) => {
        console.log('user first level sign in is successfull !!!!!!!!!');
        this.scheduleTokenRefresh();
        this.authenticationResult = {"AccessToken":result.getAccessToken().getJwtToken(),
          "ExpiresIn":result.getIdToken().payload['auth_time'],
          "IdToken":result.getIdToken().getJwtToken(),
          "refreshToken":result.getRefreshToken().getToken(),
        };
      if (this.authenticationResult) {
         this.getUserDetails()
         this.setData(this.authenticationResult)
       }
    },
    onFailure: (err) => {
     this.signInerrorAlert.next(err)
    }, 
    mfaSetup: (challengeName: string, challengeParameters: any) => {
        this.cognitoUser?.associateSoftwareToken(this.cognitoCallbacks);
    },
    associateSecretCode: async (secretCode: string) => {
      const name = 'AHA Precision Medicine Platform';
      const uri = `otpauth://totp/${encodeURIComponent(name)}?secret=${secretCode}`;
      
      try {
        const image = await QRCode.toDataURL(uri);
        this.qrCodeImage = this.sanitizer.bypassSecurityTrustUrl(image) as string;
        this.mfaEnabled.next(true)
        this.sendPathUrl.next(this.qrCodeImage)
        this.getSecretCode.next(secretCode);
      } catch (err) {
        console.error('QR code generation error:', err);
      }
    },
    selectMFAType:(challengeName, challengeParameters) =>{
      this.mfaType = "SOFTWARE_TOKEN_MFA";
      this.cognitoUser.sendMFASelectionAnswer(this.mfaType, this.cognitoCallbacks);
    },
    mfaRequired: (challengeName, session) => {

      const totpCode = 'Please input the TOTP code from your authenticator app:'
      this.cognitoUser.verifySoftwareToken(
        totpCode, // TOTP code provided by the user
        'MyDevice', // Optional device name
        {
          onSuccess: (response) => {
            console.log('TOTP verified successfully:', response);
          },
          onFailure: (err) => {
            console.error('Error verifying TOTP:', err);
          },
        },
      );
    },
    totpRequired: () => {
      this.verifieduser = true;
      this.mfaEnabled.next(true)
  },
   
  };
      defaultmfaSelection(uri){
      this.mfaType = "SOFTWARE_TOKEN_MFA";
      this.cognitoUser.sendMFASelectionAnswer(
      this.mfaType,
      {
        onSuccess: async (result) => {
        console.log("MFA selection succeeded:", result);
        const image = await QRCode.toDataURL(uri);
        this.qrCodeImage = this.sanitizer.bypassSecurityTrustUrl(image) as string;
        this.mfaEnabled.next(true)
        this.sendPathUrl.next(this.qrCodeImage)
          // Navigate to the next step, such as prompting for the OTP
        },
        onFailure: (err) => {
          console.error("MFA selection failed:", err);
        },
      }
    );
  }
  selectTypeOFMFA(type){
    this.mfaType = "SOFTWARE_TOKEN_MFA";
    console.log(this.cognitoUser)
    this.mfaType =type; 
    this.cognitoUser.sendMFASelectionAnswer(
      this.mfaType,
      {
        onSuccess: (result) => {
          console.log("MFA selection succeeded:", result);
          // Navigate to the next step, such as prompting for the OTP
        },
        onFailure: (err) => {
          console.error("MFA selection failed:", err);
        },
      }
    );
  }
  totpAuthentication(totpCode){
     this.mfaType = "SOFTWARE_TOKEN_MFA";
    //this.cognitoUser.sendMFASelectionAnswer(this.mfaType, this.cognitoCallbacks);
    if (!this.verifieduser && totpCode) {
      this.cognitoUser.verifySoftwareToken(
        totpCode, // The TOTP code entered by the user
        "DeviceName", // The device name associated with the MFA setup
        {
          onSuccess: (result) => {
            this.loaderService.hideLoader()
            this.scheduleTokenRefresh();
            this.authenticationResult = {"AccessToken":result.getAccessToken().getJwtToken(),
              "ExpiresIn":result.getIdToken().payload['auth_time'],
              "IdToken":result.getIdToken().getJwtToken(),
              "refreshToken":result.getRefreshToken().getToken(),
            };
          if (this.authenticationResult) {
             this.getUserDetails()
             this.setData(this.authenticationResult)
           }
           
            console.log("MFA verified successfully", result);
          },
          onFailure: (err) => {
            this.loaderService.hideLoader()
            this.signInMfaerrorAlert.next(err)
            console.error("MFA verification failed", err);
          }
        }
      );
      
  }
  if (this.verifieduser && totpCode) {
    this.cognitoUser?.sendMFACode(
      totpCode, // The TOTP code entered by the user
      {
        onSuccess: (result) => {
          this.loaderService.hideLoader()
          this.verifieduser = false;
          console.log("TOTP verification succeeded:", result);
          console.log('user first level sign in is successfull !!!!!!!!!');
          this.scheduleTokenRefresh();
          this.authenticationResult = {"AccessToken":result.getAccessToken().getJwtToken(),
            "ExpiresIn":result.getIdToken().payload['auth_time'],
            "IdToken":result.getIdToken().getJwtToken(),
            "refreshToken":result.getRefreshToken().getToken(),
          };
        if (this.authenticationResult) {
           this.getUserDetails()
           this.setData(this.authenticationResult)
         }
        },
        onFailure: (err) => {
          this.loaderService.hideLoader()
          this.signInMfaerrorAlert.next(err)
        },
      },
      'SOFTWARE_TOKEN_MFA'
    );  

 }

}

  getUserDetails(): Promise<any> {
    const user = this.getCurrentUser();
    if (!user) {
      return Promise.reject('User not logged in');
    }

    return new Promise((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          user.getUserAttributes((err, attributes) => {
            if (err) {
              reject(err);
            } else {
              const userAttributes = {};
              attributes.forEach(attribute => {
                userAttributes[attribute.Name] = attribute.Value;
              });
              if (attributes) {
                let userObject: any = {};
                userObject = attributes.reduce((acc, item) => {
                  acc[item.Name] = item.Value;
                  return acc;
                }, {});
                  userObject["type"] = "Researcher"
                  this.userDetailsData = userObject;
                  localStorage.setItem("userInfo", JSON.stringify(userObject));
                  this.router.navigateByUrl("/loggedin")
                    }
              resolve(userAttributes);
            }
          });
        }
      });
    });
  }

   getADUserDetails(userDetails) {
      if (userDetails) {
        localStorage.setItem("userInfo", JSON.stringify(userDetails));
        this.router.navigate(['/adgroup-loggedin']); // Navigate to the home page or another route
      }
  }
  getUserProfileDetails(): Promise<any> {
    const user = this.getCurrentUser();
    if (!user) {
      return Promise.reject('User not logged in');
    }

    return new Promise((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          user.getUserAttributes((err, attributes) => {
            if (err) {
              reject(err);
            } else {
              const userAttributes = {};
              attributes.forEach(attribute => {
                userAttributes[attribute.Name] = attribute.Value;
              });
              if (attributes) {
                let userObject: any = {};
                userObject = attributes.reduce((acc, item) => {
                  acc[item.Name] = item.Value;
                  return acc;
                }, {});
                  this.userDetailsData = userObject;
                  localStorage.setItem("userInfo", JSON.stringify(userObject));
                    }
              resolve(userAttributes);
            }
          });
        }
      });
    });
  }
  getCurrentUser(): CognitoUser | null {
    return userPool.getCurrentUser();
  }
  async updateUserDetails(attributes) {

    const user = this.getCurrentUser();
    attributes = this.transformToUserAttributes(attributes)
    const cognitoAttributes = Object.keys(attributes).map((key) => {
      return new CognitoUserAttribute({
        Name: attributes[key].Name,
        Value: attributes[key].Value,
      });
    });
    return new Promise((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          user.updateAttributes(cognitoAttributes, (err, result) => {
            if (err) {
              reject(err);
            } else {
              resolve(result);
            }
          });
        }
      });
    });
  }
  
  transformToUserAttributes(data: any): { Name: string; Value: string }[] {
    const userAttributes = [];

    for (const [key, value] of Object.entries(data)) {
      const formattedValue = Array.isArray(value) ? value.join(',') : value;
      const attributeName = (key == "family_name" || key == "given_name") ? key : `custom:${key}`;
      userAttributes.push({ Name: attributeName, Value: formattedValue });
    }

    return userAttributes;
  }
  parseCognitoCallback() {
      this.auth.parseCognitoWebResponse(window.location.href);
  }



  refreshToken(): void {
    console.log("refreshtoken")

    const user = this.getCurrentUser();
    if (user) {
      user.getSession((err: any, session: any) => {
        if (err) {
          console.error("Error getting session:", err);
          return;
        }
        // Check if the session is still valid
        if (session && session.isValid()) {
          // Get the refresh token from the session
          const refreshToken = session.getRefreshToken();
          // Refresh the session using the refresh token
          user.refreshSession(refreshToken, (refreshErr: any, refreshedSession: any) => {
            if (refreshErr) {
              console.error("Error refreshing session:", refreshErr);
              return;
            }
            this.azureadlogintokens = {"AccessToken":refreshedSession.getAccessToken().getJwtToken(),
              "ExpiresIn":refreshedSession.getIdToken().payload.auth_time,
              "IdToken":refreshedSession.getIdToken().getJwtToken(),
              "refreshToken":refreshedSession.getRefreshToken().getToken(),
            };
            localStorage.setItem('currentUser', JSON.stringify(this.azureadlogintokens));
            this.currentUserSubject.next(this.azureadlogintokens);
            console.log('this.azureadlogintokens',this.azureadlogintokens)
            // Schedule the next refresh
            this.scheduleTokenRefresh();
          });
        }
      });
    }
  }
  private scheduleTokenRefresh(): void {
    this.refreshCounter++;
    if (this.refreshCounter > this.maxRefreshAttempts) {
      this.signOut();
    }else{
    const user = this.getCurrentUser();
    if (user) {
      user.getSession((err: any, session: any) => {
        if (err || !session) {
          console.error("Unable to get session:", err);
          return;
        }
        const expirationTime = session.getAccessToken().getExpiration() * 1000;
        const currentTime = new Date().getTime();
        const refreshTime = expirationTime - currentTime - 60000; // Refresh 1 minute before expiration
        // Clear any existing timer and set a new one
        clearTimeout(this.refreshTimer);
        this.refreshTimer = setTimeout(() => {
          this.refreshToken();
        },refreshTime);
      });
    }
  }
  }
}


