import { Component } from '@angular/core';
import { Location } from '@angular/common';
@Component({
selector: 'app-help',
standalone:true,
templateUrl: './help.component.html',
styleUrls: ['./help.component.scss'],
})
export class HelpComponent {


constructor(private location: Location) {}
ngOnInit(): void {

}
goBack(): void {
    this.location.back(); // Navigates to the previous page
  }
}