import { Component, OnInit, isDevMode, Input, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterModule } from '@angular/router';
declare var $: any;
declare var jQuery: any;
import {AuthenticationService} from '../../shared/services/auth.service';
import { faUser, faCaretDown } from '@fortawesome/free-solid-svg-icons';
declare var ga: Function;
import { ScriptService} from '../../scripts.service';
import { environment } from '../../../environments/environment';
import { filter } from 'rxjs/operators';
import { InactivityTimerService } from '../../../app/shared/services/inactivity-timer-service.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../app/shared/shared.module';
import { UploadAlertComponent } from '../upload-alert/upload-alert.component';
import { RefreshTokenAlertComponent } from '../refresh-token-alert/refresh-token-alert.component';
import { SystemFailureComponent } from '../system-failure/system-failure.component';
import { AuthssoService } from '../../../app/shared/services/authsso.service';
import { BaseService } from '../../../app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { bool } from 'aws-sdk/clients/redshiftdata';

/// <reference types="node" />

declare global {
  interface Window {
      dataLayer: any[];
  }
}

@Component({
  selector: 'app-pre-login-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    UploadAlertComponent,
    RefreshTokenAlertComponent,
    SystemFailureComponent // Add CommonModule here
    // other imports
  ]
})

export class PreLoginHeaderComponent extends BaseService implements OnInit {
  @Input() linkColor: string = '#333';
  @Input() showVideo: boolean = false;
  @Input() isHomePage: boolean;
  faUser = faUser;
  faCaretDown = faCaretDown;
  isApprover: boolean = false;
  gaProperty = environment.google_analytics;
  adminRole = environment.adminRole;
  supportRole = environment.supportRole;
  disableStr = 'ga-disable-' + this.gaProperty;
  scriptLoaded = false;
  cookieConsentName = 'cookieconsent_status';
  cookieConsent: any = '';
  isSupportMenuOpen = false;
  isLoginMenuOpen = false;
  isAboutMenuOpen = false;
  isUserFeatureOpen = false;
  constructor(private authsso:AuthssoService,private router: Router,private inactivityTimerService:InactivityTimerService,
    private route: ActivatedRoute,
    public authenticationService: AuthenticationService,
    private script: ScriptService, private http:HttpClient) { 
      super(http, route)
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isSupportMenuOpen = false;
          this.isAboutMenuOpen = false;
          this.isUserFeatureOpen = false;
        }
      });
    }

  ngOnInit() {
    $('body').on('click', function(event){
      $('#getInvolvedLi').removeClass('active'); //
      $('#aboutUsContent').removeClass('show');
      $('#aboutUsLi').removeClass('active');
      $('#profileContent').removeClass('show');
      $('#dataContent').removeClass('show');
      $('#helpContent').removeClass('show');
    });
    this.script.load('googleAnalytics').then(data => {
      this.enableAnalytics();
      this.script.load('analytics').then(data => {
        this.scriptLoaded = true;
        this.acceptCookies();
      }).catch(error => console.log(error));
    }).catch(error => console.log(error));

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)  
    ).subscribe((event: NavigationEnd) => {
      // If the url is '/', then we're on the home page
      if(event.url.includes('contact')){
        this.scrollToElement('aha-footer');
      }
    });
    this.waitUntilUserLoggedIn();
    this.cookieConsent = this.getCookie(this.cookieConsentName);
    
  }

  waitUntilUserLoggedIn(): void {
    const interval = setInterval(() => {
      const result = this.isLoggedIn();
      if (result !== null) {
        this.approverCheck()
        clearInterval(interval); 
      }
    }, 2_000); 
  }

  scrollToElement(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      setTimeout(() => {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Adjust the delay time as needed
    }else{
      this.router.navigateByUrl('/').then(() => {
        // Wait for navigation to complete, then scroll to the element
        this.scrollToElement('aha-footer')
      });
    }
  }

  public optOut(){
    this.disableCookies();
    this.setCookie(this.cookieConsentName,'deny',365);
    $('#cookies-consent').hide();

  }

  public acceptCookies(){
    this.enableCookies();
    this.setCookie(this.cookieConsentName,'allow',365);
    $('#cookies-consent').hide();
  }

  getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
          c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
          }
      }
      return "";
  }
  
  setCookie(name, value, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString(); 
      const secureAttr = isDevMode() ? '' : ';secure';
      document.cookie = name + "=" + value + ";" + expires + ";path=/" + secureAttr;
  }

  disableCookies() {
      var cookies = ['_ga','_gid','_gat','NID','pmp-csrf-token'];
      for(var i = 0; i < cookies.length; i++) {
        this.delete_cookie(cookies[i]);
      }
      window[this.disableStr] = true;
      window['gtag']('consent', 'update', {
          'analytics_storage': 'denied'
      });
  }

  enableCookies() {
    // // Setting up Google Analytics 4
    // window.dataLayer = window.dataLayer || [];
    // // Declare the gtag function with a type assertion
    // const gtag: (...args: any[]) => void = function() {
    //   window.dataLayer.push(arguments);
    // }
    // gtag('js', new Date());
    // gtag('config', this.gaProperty, {
    //   'cookie_flags': 'secure'
    // });
}

  delete_cookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  public isLoggedIn(){
    return this.authsso.currentUserValue;
  }

  public isAdmin(){
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if(userInfo && userInfo.hasOwnProperty("custom:ad_groups") && userInfo['custom:ad_groups'].includes(this.adminRole)){
      return true;
    }
    return false;
  }

  public isSupport(){
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if(userInfo && userInfo.hasOwnProperty("custom:ad_groups") && userInfo['custom:ad_groups'].includes(this.supportRole)){
      return true;
    }
    return false;
  }

  public navigateToView(view){
    this.router.navigate(['/learn']);
  }

  public logout(){
    this.authenticationService.logout(true);
    this.inactivityTimerService.stopInactivityTimer()
  }

  public enableAnalytics(){
    let cookiesConsent = this.getCookie(this.cookieConsentName);
    if( cookiesConsent === 'allow'){
      this.enableCookies();
    }
  }

  

  public signIn(){
    //this.auth.login().subscribe();
      let checkDevMode = isDevMode();
      let signinUrl = "https://pmp-dev.heartblr.org"
      if(!this.isLoggedIn()){
        window.location.href = signinUrl + "/sso/oauth/authorize?client_id=acme&redirect_uri=" + window.location.origin + "/loggedin&response_type=code&state=g6hju7";
      }
    
  }

  public showAboutUs(){
      $('#aboutUsContent').toggleClass('show');
      $('#profileContent').removeClass('show');
      $('#dataContent').removeClass('show');
      $('#helpContent').removeClass('show');
      event.stopPropagation();
  }

  public showUser(){
      $('#aboutUsContent').removeClass('show');
      $('#profileContent').toggleClass('show');
      $('#dataContent').removeClass('show');
      $('#helpContent').removeClass('show');
      event.stopPropagation();
  }

  public showData(){
      $('#aboutUsContent').removeClass('show'); //
      $('#profileContent').removeClass('show');
      $('#dataContent').toggleClass('show');
      $('#helpContent').removeClass('show');
      event.stopPropagation();
  }

  public showHelp(){
      $('#aboutUsContent').removeClass('show'); //
      $('#profileContent').removeClass('show');
      $('#dataContent').removeClass('show');
      $('#helpContent').toggleClass('show');
      event.stopPropagation();
  }
  toggleSupportMenu() {
    this.isSupportMenuOpen = !this.isSupportMenuOpen;
    this.isAboutMenuOpen = false;
    this.isUserFeatureOpen = false;
    this.isLoginMenuOpen = false;
  }
  toggleAboutMenu() {
    this.isAboutMenuOpen = !this.isAboutMenuOpen;
    this.isSupportMenuOpen = false;
    this.isUserFeatureOpen = false;
    this.isLoginMenuOpen = false;
  }

  toggleUserMenu() {
    this.isSupportMenuOpen = false;
    this.isAboutMenuOpen = false;
    this.isUserFeatureOpen = false;
    this.isLoginMenuOpen = !this.isLoginMenuOpen;
  }

  toggleUserFeatureMenu(){
    this.isUserFeatureOpen = !this.isUserFeatureOpen;
    this.isSupportMenuOpen = false;
    this.isAboutMenuOpen = false;
    this.isLoginMenuOpen = false;
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInside = (event.target as HTMLElement).closest('.dropdown-menu, .nav-link');
    if (!clickedInside || this.isLinkActive(event)) {
      this.isSupportMenuOpen = false;
      this.isAboutMenuOpen = false; 
      this.isUserFeatureOpen = false;
    }
  }

  isLinkActive(event: MouseEvent): boolean {
    const targetLink = (event.target as HTMLElement).closest('.nav-link');
    const submenuClick = (event.target as HTMLElement).closest('.dropdown-item');
    if (targetLink) {
      return this.router.url === targetLink.getAttribute('routerLink');
    }
    if (submenuClick) {
      return this.router.url === submenuClick.getAttribute('routerLink');
    }
    return false;
  }

  async approverCheck(){
    await this.getVerifyApprover().subscribe(x=>{
      if(x['approver']){
        this.isApprover = true
      }
    })
  }
  
  
    getVerifyApprover(){
      let url =this.getBaseUrl() + "consumer/approverVerify"
      return this.http.get(url, {
          headers: this.buildHeaders()
      });
    }
    navigateDashboard(event : Event){
      event.preventDefault()
      if(this.isAdmin()){
        this.router.navigate(["/admin/workspace"])
      }else if(this.isApprover){
        this.router.navigate(["/approver/workspace-request"])
      }else if(this.isSupport()){
        this.router.navigate(["/support/workspace"])
      }
    }
  
}
