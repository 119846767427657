import { Component } from '@angular/core';
import { HorizontalBarComponent } from '../charts/horizontal-bar/horizontal-bar.component';
import { VerticalBarComponent } from '../charts/vertical-bar/vertical-bar.component';
import { DataResourceComponent } from '../data-resource.component';
import { DrSidebarComponent } from '../dr-sidebar/dr-sidebar.component';
import { DrTileComponent } from '../dr-tile/dr-tile.component';
import { ageData, raceEthData, genderData, diagnosisData, statisticData, ablationData } from './gwtg-afib-data';
import { DrRowComponent } from '../dr-row/dr-row.component';
import { SectionHeaderComponent } from '../../rgr-snapshot/section-header/section-header.component';
@Component({
  selector: 'app-gwtg-afib',
  templateUrl: './gwtg-afib.component.html',
  styleUrls: ['./gwtg-afib.component.scss', '../data-resource.component.scss'],
  providers: [DataResourceComponent, HorizontalBarComponent, VerticalBarComponent,
    DrSidebarComponent, DrTileComponent, DrRowComponent],
    imports: [DataResourceComponent, HorizontalBarComponent, VerticalBarComponent,
      DrSidebarComponent, DrTileComponent, DrRowComponent, SectionHeaderComponent],
      standalone: true
})
export class GwtgAfibComponent {
  ageData: any;
  raceEthData: any;
  genderData: any;
  diagnosisData: any;
  statisticData: any;
  ablationData: any;
  constructor() {
    Object.assign(this, { ageData, raceEthData, genderData, diagnosisData, statisticData, ablationData })
  }

}