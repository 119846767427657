import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MichDataset } from "assets/html/mich-datasets";
@Component({
  selector: 'app-gwtg-umich',
  templateUrl: './gwtg-umich.component.html',
  styleUrls: ['./gwtg-umich.component.scss']
})
export class GwtgUmichComponent implements OnInit {

  constructor(    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private el: ElementRef) { }
    filteredHtml: SafeHtml;
    code_hide = true;
  ngOnInit() {
    this.filteredHtml = this.sanitizer.bypassSecurityTrustHtml(MichDataset);
  }
  ngAfterViewInit() {
    const toggleLink = document.getElementById("toggleLink");
    if (this.code_hide) {
      this.toggleElementsByClass("input", false);
      this.toggleElementsByClass("prompt", false);
    }
    if (toggleLink) {
      toggleLink.addEventListener("click", (event) => {
        event.preventDefault();
        this.code_showhide_toggle();
      });
    }
  }
  code_showhide_toggle() {
    this.code_hide = !this.code_hide;
    this.toggleElementsByClass("input", !this.code_hide);
    this.toggleElementsByClass("prompt", !this.code_hide);
  }

  private toggleElementsByClass(className: string, show: boolean) {
    const elements = this.el.nativeElement.querySelectorAll(`.${className}`);
    elements.forEach((element: any) => {
      if (className === "input") {
        this.renderer.setStyle(element, "display", show ? "flex" : "none");
      } else {
        this.renderer.setStyle(element, "display", show ? "block" : "none");
      }
    });
  }

}
