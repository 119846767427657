import { Component } from '@angular/core';

@Component({
  selector: 'app-feature-grid',
  templateUrl: './feature-grid.component.html',
  styleUrls: ['./feature-grid.component.scss']
})
export class FeatureGridComponent {

  constructor() { }
}
