
import dayjs from 'dayjs';

export function isEllipsToolTipDisabled(elementId) {
    // Get the HTML Element
    const element = document.getElementById(elementId);
    // Checking for width and always returning boolean value
    return element && (element.scrollWidth <= element.offsetWidth) ? true : false;
}

export function intersectionWith(a, b, comp) {
    return a.filter(x => b.findIndex(y => comp(x, y)) !== -1)
}

export function getPropArray(dataArray: any[], propName) {
    return dataArray.map(elem => elem[propName]);
}

export function getUniqueArray(stringArray: string[]) {
    return stringArray.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
}

export function formatdate(date: string | number, format: string): string {
    return date ? dayjs(date).format(format) : '';
}


export function getTimeStamp(date: string | number): number {
    return date ? dayjs(date).unix() : Infinity;
}


export function humanFileSize(bytes: number): string {
    const thresh = 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}