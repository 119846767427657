<div class="container-fluid container-fluid-home para-block-container">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
      <p class="para-block">
        The Precision Medicine Platform is a cloud-based system that allows researchers to collaborate and analyze large
        datasets from any computer in the world using a secure environment and the power of machine learning.
      </p>
      <p class="para-block">
        Within the research interface, users have access to assorted datasets, including the industry-changing Get With
        The
        Guidelines® registry data to accelerate findings into impactful discoveries.
      </p>
    </div>
  </div>
</div>