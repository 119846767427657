import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { BaseService } from './base.service';

const maxAgeSec = 600;

@Injectable()
export class RequestCache extends BaseService {

    cache = new Map();

    /**
    * @description Gets a record from CACHE Map.
    * Req URL as key is used to get RESPONSE object from CACHE Map.
    *  @param 1 => REQUEST: HttpRequest
    */
    get(req: HttpRequest<any>): HttpResponse<any> | undefined {
        const { url } = req;

        // Get response from CACHE Map
        const cached = this.cache.get(url);

        if (!cached) {
            return undefined;
        }

        // Check if response has expired.
        const isExpired = cached.lastRead < (Date.now() - (maxAgeSec * 1000));
        return isExpired ? undefined : cached.response;
    }

    /**
    * @description Puts a new record in CACHE Map.
    * Req URL as key and RESPONSE as value
    *  @param 1 => REQUEST: HttpRequest
    *  @param 2 => Response: HttpResponse
    */
    put(req: HttpRequest<any>, response: HttpResponse<any>): void {
        const { url } = req;

        // Creating CACHE entry in CACHE Map.
        const entry = { url, response, lastRead: Date.now() };
        // Seting CACHE entry in CACHE Map.
        this.cache.set(url, entry);

        const expired = Date.now() - (maxAgeSec * 1000);

        // Delete All expired Entries.
        this.cache.forEach(expiredEntry => {
            if (expiredEntry.lastRead < expired) {
                this.cache.delete(expiredEntry.url);
            }
        });
    }

    /**
    * @description Deletes a record(s) from CACHE Map.
    * Deletes response of URL passed from CACHE Map.
    *  @param 1 => url: string or array of string
    *  @param 2 (optional) => noBase: if you dont want base url to be added automatically.
    */
    delete(url: string | string[], noBase?: boolean): void {
        const baseUrl = noBase ? '' : this.getBaseUrl();
        if (typeof url === 'string') {
            this.cache.delete(baseUrl + url);
        } else {
            url.forEach(u => this.cache.delete(baseUrl + u));
        }
    }

    /**
    * @description Deletes a record(s) from CACHE Map using RegExp.
    * Deletes response from CACHE Map if key(URL) passes given RegExp test.
    *  @param 1 => regEx: RegExp String
    */
    deleteRegEx(regEx: string): void {
        if (regEx) {
            const regExpfunc = new RegExp(regEx);
            this.cache.forEach((value, key) => {
                if (regExpfunc.test(key)) {
                    this.cache.delete(key);
                }
            });
        }
    }
}