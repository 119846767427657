import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MediaPressService } from './media-press.service';
declare var toggle: any;
declare var createCards: any;
declare var createRowDiv: any;
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'app-media-press',
  templateUrl: './media-press.component.html',
  styleUrls: ['./media-press.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [SharedModule],
  standalone: true
})
export class MediaPressComponent implements OnInit {

  data: any;
  pressReleases: any;
  constructor(private mediaPressService: MediaPressService) { }

  ngOnInit() {
  this.mediaPressService.getMediaPressLinks().subscribe(data => {

      this.pressReleases = data['links'].find(item => item.name === 'Press').childs.map(child => {
        return {year: child.name, childs: child.childs};
      });

      this.data = data['links'].filter(item => item.name !== 'Press');
      
    }, error => {
      console.log(error);
    });

    
  }
}
