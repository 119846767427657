import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../../app/shared/services/auth.service';
import { AuthssoService } from '../../../../app/shared/services/authsso.service';
import { InactivityTimerService } from '../../../../app/shared/services/inactivity-timer-service.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-addi-login',
  templateUrl: './addi-login.component.html',
  styleUrls: ['./addi-login.component.scss']
})
export class AddiLoginComponent implements OnInit {
  showError: boolean=false;
  
  //  tokenEndpoint = `https://${environment.cognito.cognitoDomain}/oauth2/token`;
  //  clientId = "1fjnho437d7tmrhisqgqlbg0ai";
  //  redirectUri= window.location.protocol + '//' + window.location.host + '/addi-login'
   constructor(private inactiveService:InactivityTimerService,private auth: AuthenticationService, private route: ActivatedRoute,private authsso:AuthssoService,
     private http: HttpClient,
     private router: Router) { }

  ngOnInit() {
    const code = this.route.snapshot.queryParamMap.get('code');
    if (code) {
      this.exchangeCodeForTokens(code);
    } else {
      console.error('Authorization code not found in URL');
    }
  }
  private exchangeCodeForTokens(code: string) {
    const body = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('client_id', environment.cognito.ClientId)
      .set('code', code)
      .set('redirect_uri', window.location.protocol + '//' + window.location.host + '/addi-login');

    this.http
      .post("https://"+environment.cognito.cognitoDomain+"/oauth2/token", body.toString(), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .subscribe(
        (result: any) => {
          this.authsso.azureadlogintokens = {"AccessToken":result.access_token,
            "ExpiresIn":'',
            "IdToken":result.id_token,
            "refreshToken":result.refresh_token,
          };
          if(result){
            if (result) {
              const payload = this.decodeToken(result.id_token);
              console.log(payload);
              localStorage.setItem("userInfo", JSON.stringify(payload));
            }
            const authData = this.authsso.getAzureadlogintokens()
            if(authData != undefined){
        
            this.auth.getAzureToken(authData).subscribe({
              next: (user) => {
                    this.inactiveService.startInactivityTimer()
                    let redirectRoute = '/workspace/launch-workspace';
                    if(JSON.parse(localStorage.getItem("path"))){
                      redirectRoute = JSON.parse(localStorage.getItem("path"));
                    }
                    this.router.navigate([redirectRoute]); 
              },
              error: (err) => {
                this.showError = true;
                console.error('Error storing token:', err);
              }
            });
          }
          }  
        },
        (error) => console.error('Token exchange failed', error)
      );
  }
  decodeToken(token: string): any {
    const payload = token.split('.')[1];
    const decodedPayload = atob(payload); 
    return JSON.parse(decodedPayload); 
  }
}
