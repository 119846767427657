import { Component, Input } from '@angular/core';
interface Section {
  ref: any;
  name: string;
}
@Component({
  selector: 'app-content-navigation',
  templateUrl: './content-navigation.component.html',
  styleUrls: ['./content-navigation.component.scss']
})
export class ContentNavigationComponent {
  @Input() sections: Section[] = [];
  constructor() { }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

}
