
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
  standalone: true
})
export class SectionHeaderComponent {

  @Input() section_title: string;

  constructor() { }

}

