<app-title-subtitle [title]="'Getting Started'"
  ></app-title-subtitle>


  <div class="root">
    <div class="wrapper">
      <app-content-navigation [sections]="[
      {'ref': platformOverview, 'name': 'Platform Overview'},
      {'ref': obtainAPmpWorkspace, 'name': 'Obtain a PMP Workspace'},
      {'ref': BringYourOwnData, 'name': 'Data'}
    ]"></app-content-navigation>
      <div class="user-guide-container documentation">
        <div class="section">
          <h2 #platformOverview>Platform Overview</h2>
          <p>The Precision Medicine Platform (PMP) is a cloud-based technology solution that enables the medical research community to accelerate biomedical breakthroughs. The platform provides access to diverse datasets and power computing in a 
            <a  routerLink="/security-information" target="_blank">secure</a> and FedRAMP Low compliant environment equipped with state of the art tools for data analysis.
          </p>
          <ul>
            <li>Learn more about the Precision Medicine Platform <a routerLink="/workspace"
                >here</a></li>
            <li>Explore the capabilities of Precision Medicine Platform workspaces <a
              routerLink="/workspace/about" >here</a></li>
          </ul>
          <h5>Terms of Use</h5>
          <p>Please refer to the <a routerLink="/tos" target="_blank">Terms of Service</a> when
            using the PMP portal and workspace. Any inventions, intellectual property, or patents resulting from AHA funding (e.g.
            grants, data challenges) are governed by the
            <a href="https://professional.heart.org/professional/ResearchPrograms/AwardsPolicies/UCM_320256_Policies-Governing-All-Research-Awards.jsp"
              target="_blank">AHA Patent, Intellectual Property and Technology Transfer Policy</a>
          </p>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Collaboration
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                <p>The platform facilitates collaboration and reproducibility by enabling users to share workspaces
                  and conduct analyses in a private, secure cloud environment.
                  Users can also collaborate through traditional development methods such as github.</p>
                <p>Collaboration is made easy with the PMP because all data and analyses reside in a secure
                  workspace for which only the participant/team representative has access,
                  unless the participant/team representative chooses to collaborate with additional colleagues and share the
                  workspace with them.</p>
                <p><em><b>Note: </b>It is the participant's
                    responsibility to ensure collaborators have the appropriate data access approvals when
                    sharing.</em></p>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Power Compute In the PMP
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                <p>There are multiple ways to take advantage of power compute in the Precision Medicine Platform,
                  namely:</p>
                <ul>
                  <li><b>Elastic, High Performance Computing </b>
                    <ul>
                      <li>Analyses that utilize need to optimize software through traditional parallel computing methods can leverage the EC2, GPU and CPU architecture options via AWS SageMaker for Jupyter, and RStudio and SAS Studio based EC2 instances.
                      </li>
                    </ul>
                  </li>
                  <li><b>EMR, Automatic Scaling through Spark</b>
                    <ul>
                      <li>Analyses that use Spark based packages or software can leverage the EMR workspace
                        auto-scaling cluster architecture to optimize performance
                      </li>
                    </ul>
                  </li>
                </ul>
                <p> Default workspace architectures are designed to fit the needs of most users, however architecture sizes can be adjusted and customized as needed. Workspace owners can request a change to the configuration for an application via the Workspace portal page.  
                </p>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
  
        <div class="section">
          <h2 #obtainAPmpWorkspace>Obtain a PMP Workspace</h2>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Registering for a PMP Account
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                <span class="content subsection">A new user must <a href="https://pmp.heart.org/sso/"
                    target="_blank">Register</a>
                  prior to being given
                  access to the PMP. Fill out the form to create a free, PMP account.</span>
                <ul>
                  <li>Users can register for a PMP portal account by clicking on the Sign Up or Sign In buttons.
                  </li>
                  <li>Passwords must have at least 12 characters, to include one upper-case, one
                    lower-case, one number, and one special character.
                  </li>
                  <li>Both the email address and password are case sensitive. Once the sign up form is completed click on
                    Register.
                  </li>
                  <li>The system will send an email with a code to the email address used in the registration process for verification. Follow the instructions within the email to finalize the registration process.
                  </li>
                  <li>Once you are a registered user, you will be required to use <a href="https://pmp.heart.org/help"
                    target="_blank">Multi-Factor Authentication (MFA)</a>  to sign in to the portal.</li>
                </ul>
                <img style="width: 100%;" class="guide-img" src="assets/images/sign-up.png" alt="screenshot of registering for a PMP workspace">
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Requesting Data and/or a Workspace
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                <p>After successfully registering, click on Sign In and use your new login
                  credentials to sign into the portal. Remember, the email address and password are case sensitive. Upon signing in the first time, navigate to the Data page to begin the process of requesting workspace applications and/or datasets.
                  <em><b>Note:</b> Should you leave the session idle for more than 20 minutes,
                    it will time out.</em>
                </p>
                <p>A registered user can request workspace configurations and datasets by clicking the <em>Request</em> button at the
                  top
                  of the page. If you do not request a dataset along with your initial workspace request, datasets
                  can be requested at any time. Simply check the box within the dataset card and click the <em>Request</em>
                  button to proceed.
                </p>
                
                <p>After clicking the Request button, you will be asked a series of questions in addition to agreeing to Precision Medicine Platform Terms of Service.
                </p>
                <p>For the workspace configuration, you have the option to select access to the applications that you need.  For instance, if you only code in Python, you might only need to request Jupyter.  If you are exclusively a SAS user, you might only need to request SAS.  If you plan to use multiple applications for your research project, you can select more than one application (i.e. Jupyter and RStudio).  We ask that users request only what they need, as this helps to decrease the AHA’s operational costs to run the platform.  You can always put in a request later if you need to add an application.

                </p>
                <ul>
                  <li><b>Please keep the default configuration for each application unless you are familiar with AWS sizing and know that you require something different than our default offering.  You can always request a change to your configuration later if necessary.</b>
                  </li>
                </ul>
                <img class="guide-img" src="assets/images/request_workspace.png" style="width:100%"
                  alt="screenshot of create a new workspace options leave as default and check I agree">
                <p>After completing all fields on the request form, you will
                  be taken to the Research Details portion of the consent form if you have simultaneously requested access to a dataset. Approval is required prior to the release of a protected dataset.
                </p>
                <b style="font-size: 1rem;">Research Details</b>
                <ul>
                  <li>Research User Statement (RUS): A brief description of the applicant's proposed
                    use of the dataset(s). The RUS will be reviewed by all parties responsible for data covered
                    by the Data Access
                    Request.The RUS should be one or two paragraphs in length and include research objectives,
                    the study design, and an
                    analysis plan. Please include the phenotypic characteristics that will be tested for
                    association with genetic
                    variants. If you are requesting multiple datasets, please describe how you will use each of
                    them.
                  </li>
                  <li>Non-Technical Summary: Describe the RUS using layman's terms, suitable for understanding by
                    the general public.
                  </li>
                  <li>Type of Research: Provide additional comments as needed and indicate the type of research by
                    choosing from:
                    <ul>
                      <li>Disease-related studies</li>
                      <li>Methods development and validation studies</li>
                      <li>Controls</li>
                      <li>Population structure or normal variations studies</li>
                    </ul>
                  </li>
                  <li>Disease Area(s) of Focus: choose from the drop-down menu
                    the disease area(s) your study focuses on.
                  </li>
                </ul>
                <p><b>Research Purpose</b>: A list of Yes/No questions are presented. Respond by clicking in the
                  appropriate box.
                </p>
                <p><b>Attestation Statement</b>: Review the statements in this section and check the box to the
                  left of the "I attest to the following."
                </p>
  
  
                <div><span style="font-size:1.5rem; color:#C10E21"><i class="fa fa-smile"></i> Once you have submitted your request, please note the following: </span>
                  <ul>
                    <li>The AHA PMP Administrator will review your request for a workspace and approve or deny your request.  Once approved, your workspace will be available within 30 minutes.</li>
                    <li>If you also submitted a request for a dataset, the data owner will approve or deny your request.  Once approved, you will have immediate access once your workspace is available.
                      <ul>
                        <li>Please note that AHA PMP Administrators review platform requests that are submitted during regular business hours (ET).  These include workspace, dataset, change application configuration, and workspace share requests.  If you submit your request outside of these hours, on weekends, or holidays, it will be reviewed on the next business day.</li>
                      </ul>
                    </li>
                    <li>You will receive an email notification when the workspace applications you requested are available for use.</li>
                    <li>Check out the PMP <a routerLink="/user-guide">User Guide</a> for helpful information about how to use the workspace portal page and workspace.</li>
                  </ul>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
  
        <div class="section">
          <h2 #BringYourOwnData>Data</h2>

          <h5>Bring Your Own Data</h5>
          <p>Users may bring their own de-identified data sets to the PMP. Additionally, publicly available data can be accessed in a variety of ways on the PMP, including downloading data directly and pulling/cloning data from repositories. Information on how to transfer data to the platform is provided in the Transfer Files In and Out of the PMP section of the  
            <a routerLink="/user-guide">User Guide</a>.
          </p>
          <a class="anchor" id="search"></a>
          <h5>Datasets on the Data Page</h5>
          <p> A variety of datasets are available for request through the Data page. The PMP enables
            registered users to review and request the datasets available to determine if any of the datasets offered can help address their specific research question.
          </p>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Data Page Overview
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                <p>The Data page acts as a data
                  marketplace where you can find and request datasets to conduct research, perform analyses or build
                  analytic pipelines and tools.
                  Each card on this page represents a dataset and contains information about the dataset such as the data
                  contributor,
                  number of records and years available (if applicable), type of data, and a link to more documentation
                  about
                  the
                  dataset.</p>
                <h3>Protected vs. Public Data</h3>
                <p><img class="guide-img" src="assets/images/documentation/pmp_dataset_public_private.png"
                    alt="screenshot of protected and public dataset icons"><br>
                  The lock icon indicates if the dataset is protected or public; the data contributor of protected
                  datasets
                  must approve
                  access via a formal request process.
                  The AHA serves as a trusted third party to ensure data security and privacy by facilitating the exchange
                  between the
                  data user and the data contributor.
                  The process is streamlined through our Data Access Request system that allows users to request access to a protected dataset with the data contributor having direct access to review and approve or deny an access request for their dataset.
                  The system ensures that researchers using the datasets honor the data use restrictions.
                  </p>
                  <h3>AHA Datasets</h3>
                  <p>
                    Many of the datasets listed from the American Heart Association require the researcher to submit a research proposal which must be approved, and a data use agreement fully executed before a user can request access to the dataset on the PMP.</p>
                    <div class="section">
                      <p>
                        Please visit the following links for more information about the process for submitting a proposal for these specific datasets. 
                      </p>
                      <ul>
                        <li><a href="https://www.heart.org/en/professional/quality-improvement/quality-research-and-publications/national-level-program-data-research-opportunities" target="_blank">Get With The Guidelines®</a></li>
                        <li><a href="https://www.goredforwomen.org/en/get-involved/research-goes-red/for-researchers" target="_blank">Research Goes Red</a></li>
                      </ul>
                    
                    </div>
                <img class="guide-img" src="assets/images/dataset_list.png" style="width:100%"
                  alt="screenshot explore and datasets and request workspace page">
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>


        
        
      </div>
    </div>
  </div>