import { Component } from '@angular/core';

@Component({
  selector: 'app-gcrff-data-challenge',
  templateUrl: './gcrff-data-challenge.component.html',
  styleUrls: ['./gcrff-data-challenge.component.scss']
})
export class GcrffDataChallengeComponent {

  constructor() { }

}
