import { Component } from '@angular/core';
// *** Change data source each update ***
import { participantCounts, ageData, raceEthData, geoDistData, top10MedData, haStrokeData } from './rgr-snapshot-v6-data';
import { SharedModule } from '../../../shared/shared.module';
import { SectionHeaderComponent } from './section-header/section-header.component';
// import { GoogleChartsModule } from 'angular-google-charts';
@Component({
  selector: 'app-rgr-snapshot',
  templateUrl: './rgr-snapshot.component.html',
  styleUrls: ['./rgr-snapshot.component.scss'],
  imports: [SharedModule, SectionHeaderComponent],
  standalone: true
})
export class RgrSnapshotComponent {
  ageData: any;
  raceEthData: any;
  top10MedData: any;
  haStrokeData: any;
  participantCounts: any;
  
  redToWhite = {domain: ['#800000', '#aa0000', '#aa0000', '#ff0000', '#ff2a2a', '#ff5555', '#ff8080', '#ffaaaa', '#ffcccc', '#ffd5d5']}
  
  // Common ngx-chart Options
  showLegend = false;
  showLabels = true;
  showXAxis = true;
  showYAxis = true;
  showGridLines = true;
  showDataLabel = true;
  trimXAxisTicks = false;
  trimYAxisTicks = false;
  maxYAxisTickLength = 30;

  // Adds percentage symbol to data labels on column/bar charts
  formatPercentage (value) {
    return value + '%'
  }

  // Race & Ethnicity Horizontal Bar Chart Options
  raceEthShowXAxisLabel = false;
  raceEthShowYAxisLabel = false;
  raceEthXAxisLabel = 'Percentage';
  raceEthYAxisLabel = 'Race/Ethnicity';


  // Age Column Chart Options
  gradient = true;
  ageShowXAxisLabel = true;
  ageShowYAxisLabel = false;
  xAxisLabel = 'Age Groups';
  yAxisLabel = 'Percentage';

  // GeoChart Options
  geoChart = 'GeoChart';
  geoDistData: any;
  geoColumns: any = ["Code", "Value", {role: 'tooltip', p:{html:true}}]
  geoOptions = {
    region: 'US',
    displayMode: 'regions',
    resolution: 'provinces',
    colorAxis:  {minValue: 0, colors: ['#FFFFFF', '#36454F']},
    legend: {
      numberFormat:'# Participants',
      textStyle: {
        fontName: 'Montserrat, Sans-serif',
        fontSize: 11,
        italic: true,
      }
    },
    tooltip: {
      isHtml: true,
    }
  }

  // Reproductive Information Cards Options
  cardView = [600, 500]
  cardColor = '#232837';
  innerPadding = [10, 0, 0, 10]
  

  constructor() {
    Object.assign(this, { participantCounts, ageData, raceEthData, geoDistData, top10MedData, haStrokeData })
  }

}

