<app-data-resource [statisticData]="statisticData"
 url="https://www.heart.org/en/professional/quality-improvement/get-with-the-guidelines/get-with-the-guidelines-heart-failure"
 logoUrl="/assets/images/data-resource/gwtghf.svg" pageTitle="Heart Failure"
 [sections]="[
    {'ref': diversity, 'name': 'Diversity'},
    {'ref': diagnosis, 'name': 'Diagnosis'}
  ]">
  <!-- Diversity Statistics -->
  <div #diversity>
    <app-section-header section_title="Diversity"></app-section-header>
    <div app-dr-row>
      <div app-dr-tile>
        <h3> Race & Ethnicity </h3>
        <app-horizontal-bar [chartData]="raceEthData" xAxisLabel="Percentage" yAxisTickFormatting="" [showYAxisLabel]="false"></app-horizontal-bar>
      </div>

      <div app-dr-tile>
        <h3> Age </h3>
        <app-vertical-bar [chartData]="ageData" [showYAxisLabel]="false" xAxisLabel="Age Group"></app-vertical-bar>
      </div>

      <div app-dr-tile>
        <h3> Gender </h3>
        <app-vertical-bar [chartData]="genderData" barPadding="16" [yAxisTicks]=[0,20,40,60,80,100]></app-vertical-bar>
      </div>
    </div>
  </div>

  <!-- Diagnosis Statistics -->
  <div #diagnosis>
    <app-section-header section_title="Diagnosis"></app-section-header>
    <div app-dr-row>
      <div app-dr-tile>
        <h3> Heart Failure Diagnosis </h3>
        <app-horizontal-bar [chartData]="diagnosisData" xAxisLabel="Percentage" yAxisLabel="Heart Failure with Ejection Fraction %" yAxisTickFormatting=""></app-horizontal-bar>
      </div>
    </div>
  </div>

  <!-- Right Sidebar -->
  <app-dr-sidebar
    sidebar
    collectionForm="/assets/documents/data-resource/hf/HF_Full_CRF_Updated_Sept_2022.pdf" 
    factSheet="https://www.heart.org/-/media/Files/Professional/Quality-Improvement/Get-With-the-Guidelines/Get-With-The-Guidelines-HF/Educational-Materials/HF-Fact-SheetUpdated-011119V2updated-3521KM-002.pdf">
  </app-dr-sidebar>

</app-data-resource>