declare var DDP_ENV: any;

import { ActivatedRoute} from '@angular/router';
import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from '../../../shared/services/base.service';
@Injectable({ providedIn: 'root' })
export class ContactService extends BaseService {

    constructor(private http: HttpClient, private route: ActivatedRoute) {
        super(http, route);
    }

    public submitContactUsRequest(request: any){
      let url = this.getBaseUrl() + "public/contactUs";
      return this.http.post(url, JSON.stringify(request), {
          headers: this.buildHeaders()
      })
    }
}