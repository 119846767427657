
import { routes } from './app.routes';

import { ApplicationConfig } from '@angular/core';
import { provideRouter,  withDebugTracing } from '@angular/router';
import { importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';

import { AppComponent } from './app.component';



import { MatDividerModule } from '@angular/material/divider';

import { SharedModule } from './shared/shared.module';



// Import services

import { ContactService } from './shared/components/contact-box/contact.service';
import { AuthenticationService } from './shared/services/auth.service';
import { PMPErrorLoggingService } from './shared/services/pmp-logging.service';
import { SystemFailureService } from './core/system-failure/system-failure.service';
import { RequestCache } from './shared/services/request-cache.service';
import { CachingInterceptor } from './shared/services/cachingInterceptor.service';
import { AwsUploadService } from './shared/services/aws-upload.service';
import { JwtInterceptor } from './core/guards/interceptor';
import { ErrorInterceptor } from './core/guards/error';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(
      BrowserModule,
      HttpClientModule,
      HttpClientXsrfModule.withOptions({
        cookieName: 'My-Xsrf-Cookie',
        headerName: 'My-Xsrf-Header',
      }),
      SharedModule,
      BrowserAnimationsModule,
      MatDividerModule,
      NgxUiLoaderModule,
      NgxUiLoaderHttpModule.forRoot({
        showForeground: true, 
        excludeRegexp: [
          '/dashboard/*', 
          '/graphql/*', 
          '/dataset/getTimepointData', 
          '/consoleLog', 
          '/downloadGcrffData/*', 
          '/gcrffnew/*', 
          '/workspace/getWorkspaceDetails/*',
          '/workspace/getWorkspaceAppsCredential/*',
          '/*/workspace/getScratchBucket', 
          '/*/workspace/getWorkspaceInfo',
          '/*/workspace/getBillingData',
          '/*/workspace/getResearcherBucketSizes'
        ],
        exclude: ['/dashboard/']
      }),
      // Add other necessary modules
    ),
    AuthenticationService,
    
    ContactService,
    
    SystemFailureService,
    RequestCache,
    AwsUploadService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ]
};

