<div class="chart-view">
  <ngx-charts-bar-horizontal [view]="view" [scheme]="scheme" [results]="chartData" [xAxis]="showXAxis"
    [yAxis]="showYAxis" [showGridLines]="showGridLines" [legend]="showLegend" [legendTitle]="legendTitle"
    [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [yAxisTickFormatting]="yAxisTickFormatting"
    [xAxisTickFormatting]="xAxisTickFormatting" [trimYAxisTicks]="trimYAxisTicks" [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel" [showDataLabel]="showDataLabel" [dataLabelFormatting]="dataLabelFormatting"
    [barPadding]="barPadding">
    <ng-template #tooltipTemplate let-model="model">
      <div class="container pt-3 pl-3 pr-3 pb-2 text-center">
        <div *ngFor="let data of chartData">
          <div *ngIf="data.name == model.name">
            <p> {{data.name}} </p>
            <p>{{tooltipFormatting(data.value, data.extra.count)}}</p>
          </div>
        </div>
      </div>
    </ng-template>
  </ngx-charts-bar-horizontal>
</div>