// For GWTG-CAD V4 - Harvested April 2024
export var statisticData = [
    {
        "name": "COLLECTION DATES",
        "value": "2015-2022"
    },
    {
        "name": "RECORDS",
        "value": "539K" // 8,902,693
    },
    {
        "name": "SITES",
        "value": "705" // 3,045
    }
]

export var raceEthData = [
    {
        "name": "Non-Hispanic White",
        "value": 71.47,
        "extra": {
            "count": 334853
        }
    },
    {
        "name": "Non-Hispanic Black",
        "value": 13.06,
        "extra": {
            "count": 61187
        }
    },
    {
        "name": "Hispanic",
        "value": 9.16,
        "extra": {
            "count": 42895
        }
    },
    {
        "name": "Asian",
        "value": 3.28,
        "extra": {
            "count": 15345
        }
    },
    {
        "name": "UTD",
        "value": 2.28,
        "extra": {
            "count": 10691
        }
    },
    {
        "name": "American Indian or Alaska Native",
        "value": 0.52,
        "extra": {
            "count": 2440
        }
    },
    {
        "name": "Native Hawaiian or Pacific Islander",
        "value": 0.23,
        "extra": {
            "count": 1090
        }
    }
]

export var ageData = [
    {
        "name": "18-45",
        "value": 7.25,
        "extra": {
            "count": 39042
        }
    },
    {
        "name": "46-65",
        "value": 45.13,
        "extra": {
            "count": 243106
        }
    },
    {
        "name": "66-85",
        "value": 41.36,
        "extra": {
            "count": 222763
        }
    },
    {
        "name": "> 85",
        "value": 6.2,
        "extra": {
            "count": 33410
        }
    }
]
export var genderData = [
    {
        "name": "Male",
        "value": 66.89,
        "extra": {
            "count": 357886
        }
    },
    {
        "name": "Female",
        "value": 33.11,
        "extra": {
            "count": 177138
        }
    }
]
export var diagnosisData = [
    {
        "name": "Confirmed AMI - Non-STEMI",
        "value": 55.35,
        "extra": {
            "count": 298691
        }
    },
    {
        "name": "Confirmed AMI - STEMI",
        "value": 43.68,
        "extra": {
            "count": 235748
        }
    },
    {
        "name": "Confirmed AMI - STEMI/Non-STEMI unspecified",
        "value": 0.97,
        "extra": {
            "count": 5220
        }
    }
]
