declare var DDP_ENV: any;

import { ActivatedRoute} from '@angular/router';
import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from '../../../shared/services/base.service';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {AuthenticationService} from '../../../shared/services/auth.service';
@Injectable({ providedIn: 'root' })
export class HomeService extends BaseService {
    private jsonUrl = 'assets/json/banner.json';

    constructor(private http: HttpClient, private route: ActivatedRoute,private authenticationService: AuthenticationService) {
        super(http, route);
    }
    public isLoggedIn(){
        return this.authenticationService.isLoggedIn() ? true : false;
      }
      public getBanner(): Observable<{ banners: any[] }> {
        return this.http.get<any>(this.jsonUrl).pipe(
            map((response) => {
                const currentTime = new Date();
                const banners = response.banners; 
                const filteredBanners = banners.filter((banner) => {
                const expirationDate = new Date(banner.expirationDateTime.replace(' ', 'T'));
                const isNotExpired = expirationDate > currentTime;
                    
                if (this.isLoggedIn()){
                    return banner.loginRequired === true  && isNotExpired;
                }else{
                    return banner.loginRequired === false  && isNotExpired;
                }
                });
                return { banners: filteredBanners };
            })
        );
    }
}