<app-title-subtitle [title]="'Uses & Pricing'"
  [subtitle]="'Research. Grants. Data Challenges. You name it.'"></app-title-subtitle>

<div class="container container-wide">
  <div class="col-12">
<p class="text-center">The Precision Medicine Platform can be leveraged across varying research applications. Although many Precision Medicine Platform users are often engaged or in communication with the American Heart Association, the PMP is available to use by anyone. </p>
</div></div>
<app-pricing-content></app-pricing-content>

<app-pricing-box></app-pricing-box>

<div class="container">
  <div class="row">
    <div class="col-12 col-lg-12 justify-content-center text-center pricing-footer-text">
      Research submissions relating to causes that overlap with the American Heart Association’s mission statement and
      goals may be eligible for situational payment structures, including reduced rates and subsidization.
    </div>
  </div>
</div>
<div class="container" style="margin-top: 100px;">
  <div class="row ">
    <div class="col-12 col-md-6 col-lg-6 d-flex justify-content-center flex-column pricing-contact-section">
      <h3 class="pricing-contact-section-title">Our Data Science Teams Are Available To You</h3>
      <p class="pricing-contact-section-subtitle">The American Heart Association Data Science Team is available to
        support your research goals. From quick coding needs to in-depth problem-solving. Think of it as “renting an
        expert.”
      </p>
    </div>
    <div class="col-12 col-md-6 col-lg-6">
      <app-contact-box theme="light"></app-contact-box>
    </div>
  </div>
</div>