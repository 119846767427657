import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {ContactService} from './contact.service';
import { TechnicalSupportService } from '../../../../app/modules/home/technical-support/technical-support.service';
import { BaseService } from '../../../../app/shared/services/base.service';
@Component({
  selector: 'app-contact-box',
  templateUrl: './contact-box.component.html',
  styleUrls: ['./contact-box.component.scss']
})
export class ContactBoxComponent implements OnInit {
  contactForm: FormGroup;
  message: string;
  errorMessage: string;
  @Input() theme: string = 'dark';
  @Input() title: string = 'Contact Us';
  @Input() type: string = 'contact';

  base64file: any;
  fileName: string;
  fileInputName: any;
  ALLOWED_EXTENSIONS = new Set(['xlsx', 'xls', 'doc', 'pdf', 'docx', 'png', 'jpeg', 'jpg', 'txt']);
  MAX_FILE_SIZE = 5 * 1024 * 1024;
fileValiadtion : any;
  @Input() fontSize: string = '';
  @Input() color: string = '';

  private _htmlContent: string;

  @Input() 
  set subtitle(value: string) {
    this._htmlContent = value;
  }

  get subtitle(): string {
    return this._htmlContent;
  }

  get sanitizedHtmlContent(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this._htmlContent);
  }

  constructor(private technicalService: TechnicalSupportService, private contactUsService: ContactService,private formBuilder: FormBuilder, private sanitizer: DomSanitizer,private baseservice:BaseService) {}

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      firstName: ['',  [Validators.required, Validators['pattern'](this.baseservice.usernamepattern)]],
      lastName: ['', [Validators.required, Validators['pattern'](this.baseservice.usernamepattern)]],
      email: ['', [Validators.required, Validators.email,Validators['pattern'](this.baseservice.emailPattern)]],
      confirmEmail: ['', [Validators.required, Validators.email,Validators['pattern'](this.baseservice.emailPattern)]],
      phone: ['', [Validators.pattern(this.baseservice.nonVulnerablePattern)]],
      country: ['',[Validators.pattern(this.baseservice.nonVulnerablePattern)]],
      organization: ['', [Validators.required, Validators['pattern'](this.baseservice.nonVulnerablePattern)]],
      comment: ['', [Validators.required, Validators['pattern'](this.baseservice.nonVulnerablePattern)]],
      fileName: [''],
    }, {
      validators: this.mustMatch('email', 'confirmEmail')
    });
  }

  changeListener($event) : void {
    this.readThis($event.target);
  }
  
  readThis(inputValue: any): void {
    console.log( inputValue,"read")
    this.fileName = '';
    this.base64file = '';
    this.fileValiadtion = '';
    
    var file:File = inputValue.files[0];
    if( !file )
      return;
    this.fileName = file.name;
    console.log( this.fileName,"read")
    console.log(this.fileName)
    if (!this.isValidFile(file.name)) {
      setTimeout(() => {
        this.fileValiadtion = "File type not supported, allowed file extensions are 'xlsx', 'xls', 'doc', 'pdf', 'docx', 'png', 'jpeg', 'jpg', 'txt'";
      }, 0); 
      return;
    }
    if (file.size > this.MAX_FILE_SIZE) {
      this.fileValiadtion = "The file size exceeds the limit. Please email it to us at 'pmpsupport@heart.org.'";
      return;
    }
    var myReader:FileReader = new FileReader();
  
    myReader.onloadend = (e) => {
      let fileBase64: any = myReader.result;
      this.base64file = fileBase64.split(';base64,')[1];
    }
    myReader.readAsDataURL(file);
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
   isValidFile(filename) {
    const fileExtension = filename.split('.').pop().toLowerCase();
    return this.ALLOWED_EXTENSIONS.has(fileExtension);
}

  onSubmit() {
    this.errorMessage = ''; 
this.message = '';
console.log(this.fileName)
    if (this.contactForm.valid) {
      if(this.type === 'contact'){
        const payload ={
          ...this.contactForm.value,
          contactType : 'CONTACT',
          base64file:''
        }
        //this.contactForm.value.
        this.contactUsService.submitContactUsRequest(payload).subscribe(
          data => {
            this.message = 'Request has been submitted successfully. Someone will get in touch with you soon.';
            this.fileName = '';
            const fileInput = document.getElementById('fileToUpload') as HTMLInputElement;
            if (fileInput) {
              fileInput.value = '';  
            }
            this.contactForm.reset();
          },
          error => {
            this.errorMessage = 'There was an error trying to submit contact us request. Please contact Support';
          }
        )
      }else {
        if( this.fileName  ) {
          this.contactForm.value.base64file = this.base64file; 
        }else{
          this.contactForm.value.base64file = '';
        }
        this.contactForm.value.queryDescription = this.contactForm.value.comment; 
        const payload ={
          ...this.contactForm.value,
          contactType : 'SUPPORT',
          fileName:this.fileName ? this.fileName : ''
        }
        this.technicalService.submitRequest(payload).subscribe(
          data => {
            this.message = 'Request has been submitted successfully. Someone will get in touch with you soon.';
            this.fileName = '';
            const fileInput = document.getElementById('fileToUpload') as HTMLInputElement;
            if (fileInput) {
              fileInput.value = '';  
            }
            this.contactForm.reset();
          },
          error => {
            this.errorMessage = 'There was an error trying to submit technical support request. Please contact Support';
          }
        )
      }
      
    }
  }
  clearFile() {
    this.fileName = '';
    const fileInput = document.getElementById('fileToUpload') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';  
    }
    this.fileValiadtion = '';
  }
}
