// For GWTG-Resus V6 -- Generated 2024-05-28 18:44:10 
export var statisticData = [
    {
        "name": "COLLECTION DATES",
        "value": "2000-2023"
    },
    {
        "name": "RECORDS",
        "value": "1.6M"
    },
    {
        "name": "SITES",
        "value": "1066"
    }
] 

export var countsData = [
    {
        "name": "ARC",
        "value": "78K"
    },
    {
        "name": "CPA",
        "value": "67K"
    },
    {
        "name": "MET",
        "value": "904K"
    },
    {
        "name": "PCAC",
        "value": "5.5K"
    }
] 

export var arc_counts = [
    {
        "name": "Adult",
        "value": 88.84,
        "extra": {
            "count": 69453
        }
    },
    {
        "name": "Neonatal",
        "value": 3.0,
        "extra": {
            "count": 2349
        }
    },
    {
        "name": "Newly-born",
        "value": 4.23,
        "extra": {
            "count": 3307
        }
    },
    {
        "name": "Pediatric",
        "value": 3.93,
        "extra": {
            "count": 3071
        }
    }
] 

export var cpa_counts = [
    {
        "name": "Adult",
        "value": 94.92,
        "extra": {
            "count": 638491
        }
    },
    {
        "name": "Neonatal",
        "value": 1.57,
        "extra": {
            "count": 10550
        }
    },
    {
        "name": "Newly-born",
        "value": 1.83,
        "extra": {
            "count": 12329
        }
    },
    {
        "name": "Pediatric",
        "value": 1.68,
        "extra": {
            "count": 11325
        }
    }
] 

export var met_counts = [
    {
        "name": "Adult",
        "value": 97.06,
        "extra": {
            "count": 873476
        }
    },
    {
        "name": "Neonatal",
        "value": 0.55,
        "extra": {
            "count": 4929
        }
    },
    {
        "name": "Newly-born",
        "value": 0.24,
        "extra": {
            "count": 2164
        }
    },
    {
        "name": "Pediatric",
        "value": 2.15,
        "extra": {
            "count": 19327
        }
    }
] 

export var pcac_counts = [
    {
        "name": "Adult",
        "value": 85.85,
        "extra": {
            "count": 4761
        }
    },
    {
        "name": "Pediatric",
        "value": 7.43,
        "extra": {
            "count": 412
        }
    },
    {
        "name": "Newly-born",
        "value": 2.27,
        "extra": {
            "count": 126
        }
    },
    {
        "name": "Neonatal",
        "value": 4.45,
        "extra": {
            "count": 247
        }
    }
] 

export var pediatric_first_pulseless_rhythm = [
    {
        "name": "Pulseless Electrical Activity (PEA)",
        "value": 49.56,
        "extra": {
            "count": 8062
        }
    },
    {
        "name": "Asystole",
        "value": 39.47,
        "extra": {
            "count": 6421
        }
    },
    {
        "name": "Ventricular Fibrillation",
        "value": 5.7,
        "extra": {
            "count": 927
        }
    },
    {
        "name": "Pulseless Ventricular Tachycardia",
        "value": 5.27,
        "extra": {
            "count": 858
        }
    }
] 

export var pediatric_anyvfpvt = [
    {
        "name": "No",
        "value": 90.25,
        "extra": {
            "count": 29020
        }
    },
    {
        "name": "Yes",
        "value": 9.75,
        "extra": {
            "count": 3135
        }
    }
] 

export var pediatric_defib = [
    {
        "name": "Yes",
        "value": 61.78,
        "extra": {
            "count": 2602
        }
    },
    {
        "name": "No",
        "value": 37.18,
        "extra": {
            "count": 1566
        }
    },
    {
        "name": "No, by advanced directive",
        "value": 1.04,
        "extra": {
            "count": 44
        }
    }
] 

export var pediatric_initial_condition = [
    {
        "name": "Chest compressions before pulseless",
        "value": 18.82,
        "extra": {
            "count": 6063
        }
    },
    {
        "name": "Chest compressions but never pulseless",
        "value": 38.33,
        "extra": {
            "count": 12347
        }
    },
    {
        "name": "Pulseless",
        "value": 42.85,
        "extra": {
            "count": 13804
        }
    },
] 

export var adult_first_pulseless_rhythm = [
    {
        "name": "Pulseless Electrical Activity (PEA)",
        "value": 53.91,
        "extra": {
            "count": 313508
        }
    },
    {
        "name": "Asystole",
        "value": 28.47,
        "extra": {
            "count": 165548
        }
    },
    {
        "name": "Ventricular Fibrillation",
        "value": 9.96,
        "extra": {
            "count": 57904
        }
    },
    {
        "name": "Pulseless Ventricular Tachycardia",
        "value": 7.66,
        "extra": {
            "count": 44554
        }
    }
] 

export var adult_anyvfpvt = [
    {
        "name": "No",
        "value": 67.39,
        "extra": {
            "count": 429247
        }
    },
    {
        "name": "Yes",
        "value": 32.61,
        "extra": {
            "count": 207758
        }
    }
] 

export var adult_defib = [
    {
        "name": "Yes",
        "value": 86.1,
        "extra": {
            "count": 190717
        }
    },
    {
        "name": "No",
        "value": 13.55,
        "extra": {
            "count": 30004
        }
    },
    {
        "name": "No, by advanced directive",
        "value": 0.35,
        "extra": {
            "count": 783
        }
    }
] 

export var adult_initial_condition = [
    {
        "name": "Pulseless",
        "value": 93.01,
        "extra": {
            "count": 593187
        }
    },
    {
        "name": "Chest compressions but never pulseless",
        "value": 1.73,
        "extra": {
            "count": 11014
        }
    },
    {
        "name": "Chest compressions before pulseless",
        "value": 5.26,
        "extra": {
            "count": 33563
        }
    },
] 

