<!-- <div class="banner">
  <video class="video-bg" autoplay muted loop playsinline>
    <source src="assets/video/homebanner-1024.mp4" type="video/mp4" />
  </video>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="banner-content col-6">
          <p class="intro-text">The American Heart Association’s</p>
          <h1 class="banner-title">Precision Medicine Platform</h1>
          <p class="banner-subtitle">Streamlined access to diverse data. Intuitive workspaces with the power of
            A.I.<br />
            A game-changer for researchers.</p>
          <div class="banner-buttons">
            <button class="btn btn-primary" type="button">
              Request a demo
            </button>
            <button class="btn btn-outline-primary" type="button">
              Sign up
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- banner.component.html -->
<div class="banner d-flex">
  <div class="video-container">
    <video class="video-bg" autoplay muted loop playsinline>
      <source src="assets/video/homebanner-1024.mp4" type="video/mp4" />
    </video>
  </div>
  <div class="content-container">
    <div class="container-fluid h-100">
      <div class="row h-100 align-items-center">
        <div class="col-12 col-md-8" style="margin-top: 85px;">
          <div class="banner-content">
            <p class="intro-text">The American Heart Association’s</p>
            <h1 class="banner-title">Precision Medicine Platform</h1>
            <p class="banner-subtitle">Streamlined access to diverse data.<br /> Intuitive workspaces with the power of
              A.I.<br />
              A game-changer for researchers.</p>
            <div class="banner-buttons">
              <button *ngIf="!isLoggedIn()" (click)="authenticationService.signIn('signup')" class="btn btn-outline-primary btn-sign-up btn-transparent-white" type="button">
                SIGN UP
              </button>
              <button (click)="scrollToElement('contactForm')" class="btn btn-primary btn-request-demo btn-transparent-white" type="button">
                REQUEST A DEMO
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>