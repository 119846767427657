
<app-title-subtitle title="Background and History"
subtitle="Grounded in research. Ever-improving."></app-title-subtitle>
<div class="container container-wide background-text">
  <video id="learn-video" class="pmp-video" poster="https://s3.amazonaws.com/aha-pmp-videos/media_press/CustomerAadvocacyAHAsMediaAndPress.png" controls="controls">
    <source id="vid_frame" src="https://s3.amazonaws.com/aha-pmp-videos/media_press/CustomerAadvocacyAHAsMediaAndPress.mp4" type="video/mp4">
      Your browser does not support HTML5 video. Please update your browser to view this media content.
  </video>



  <div class="background-title"> The History Behind the Name </div>
<p>In 2013, The American Heart Association invested in precision medicine and data science through specific grants to advance the science of precision cardiovascular and brain health through investments in technology and research grants. The endeavor has awarded more than 95 grants, totaling upwards of $35M.</p>
<p>As part of that program,  the American Heart Association launched the Precision Medicine Platform in 2016, in collaboration with Amazon Web Services (AWS).  </p>
<p>Along with the scientific community, the American Heart Association has invested in data access solutions and a harmonization framework to drive value for researchers through ongoing use and optimization of the Precision Medicine Platform (often called The PMP). </p>

<div class="background-title background-subtitle"> About The American Heart Association</div>
<p>The American Heart Association (AHA) is devoted to saving people from heart diseases and stroke - two of the leading causes of death in the world. We team with millions of volunteers to fund innovative research, fight for stronger public health policies, and provide lifesaving tools and information to prevent and treat these diseases.</p>


<div class="col-12 col-md-6 col-lg-12 no-padding" style="margin-top: 60px;">
  <app-contact-box theme="light"></app-contact-box>
</div>

</div>
