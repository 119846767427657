declare var DDP_ENV: any;

import { ActivatedRoute } from '@angular/router';
import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export type CacheTypes = 'add' | 'refresh' | '';
@Injectable({ providedIn: 'root' })
export class BaseService {
    nonVulnerablePattern: RegExp = /^(?!.*[<>]).*$/;
    workspacePattern: RegExp = /^[a-zA-Z0-9.@-\s]*$/;
    usernamepattern: RegExp = /^[a-zA-Z0-9.@-\s]*$/;
    emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    constructor(http?: HttpClient, route?: ActivatedRoute) {
    }

    protected getBaseUrl() {
        //return environment.apiEndpointUrl;
        if (isDevMode()) {
            return "/api/";
        } else {
         return environment.apiEndpointUrl;
       }
    }

    /**
    * @description Build header with uiCache Header for Caching strategy.
    *  @param 1 => uiCache: 'add' (To Add Cache) or 'refresh' or (To Refresh and Add Cache) or '' (Default - Reuest wont be cached).
    */
    protected buildHeaders(uiCache: CacheTypes = '') {
        const headerObj = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        if (uiCache) {
            headerObj['uicache'] = uiCache;
        }

        const headers = new HttpHeaders(headerObj);
        return headers;
    }

    public getSSOUrl() {
        return environment.ssoURL;
    }

    public getLoggedInUser() {
        return JSON.parse(localStorage.getItem("userInfo"));
    }
}