import { Component } from '@angular/core';

@Component({
  selector: 'app-timing-banner',
  templateUrl: './timing-banner.component.html',
  styleUrls: ['./timing-banner.component.scss']
})
export class TimingBannerComponent {
  microsoftTeamsTuesdayLink: string = "https://teams.microsoft.com/l/meetup-join/19%3Ameeting_YWExYmJjNDktYzc2MC00Mzc5LThjZTAtODhmZDgzZGM2MDg5%40thread.v2/0?context={%22Tid%22%3A%22ceab0fb5-f7ff-48b4-a0d0-9f76ef96ecf9%22%2C%22Oid%22%3A%2280994c80-2530-41c5-9ba9-5865ab84dfbb%22}"

  microsoftTeamsFridayLink: string = "https://teams.microsoft.com/l/meetup-join/19%3ameeting_YTY4YzM2ZWYtNmY1My00MmY4LWJlNjgtNTA5M2FkMjlmMGNl%40thread.v2/0?context=%7b%22Tid%22%3a%22ceab0fb5-f7ff-48b4-a0d0-9f76ef96ecf9%22%2c%22Oid%22%3a%22142228f1-c0c1-4089-9c1b-6c7bd694ed35%22%7d";

  constructor() { }


  tuesdayTiming(): void {
    window.open(this.microsoftTeamsTuesdayLink, '_blank'); 
  }

  fridayTiming(): void {
    window.open(this.microsoftTeamsFridayLink, '_blank'); 
  }

}
