<app-data-resource [statisticData]="statisticData"
  url="https://www.heart.org/en/professional/quality-improvement/get-with-the-guidelines/get-with-the-guidelines-resuscitation"
  logoUrl="/assets/images/data-resource/gwtgresus.svg" pageTitle="Resuscitation"
  [sections]="[
    {'ref': population, 'name': 'Population'},
    {'ref': cpa, 'name': 'CPA'}
  ]">
  
  <!-- Population Statistics -->
  <div #population>
    <app-section-header section_title="Population"></app-section-header>
    <div app-dr-row>
      <div class="statistic-container">
        <div *ngFor="let item of countsData">
          <p class="statistic-label">{{item.name}}</p>
          <p class="statistic">{{item.value}}</p>
        </div>
      </div>
      <mat-divider style="width: 100%"></mat-divider>
      <div app-dr-tile>
        <h3> ARC </h3>
        <app-vertical-bar [chartData]="arc_counts" xAxisLabel="Age Group" yAxisLabel="Event Count"
          [tooltipFormatting]="countFirstTooltipFormatting" yAxisTickFormatting=""
          dataLabelFormatting=""></app-vertical-bar>
      </div>
      <div app-dr-tile>
        <h3> CPA </h3>
        <app-vertical-bar [chartData]="cpa_counts" xAxisLabel="Age Group" yAxisLabel="Event Count"
          [tooltipFormatting]="countFirstTooltipFormatting" yAxisTickFormatting=""
          dataLabelFormatting=""></app-vertical-bar>
      </div>
      <div app-dr-tile>
        <h3> MET </h3>
        <app-vertical-bar [chartData]="met_counts" xAxisLabel="Age Group" yAxisLabel="Event Count"
          [tooltipFormatting]="countFirstTooltipFormatting" yAxisTickFormatting=""
          dataLabelFormatting=""></app-vertical-bar>
      </div>
      <div app-dr-tile>
        <h3> PCAC </h3>
        <app-vertical-bar [chartData]="pcac_counts" xAxisLabel="Age Group" yAxisLabel="Event Count"
          [tooltipFormatting]="countFirstTooltipFormatting" yAxisTickFormatting=""
          dataLabelFormatting=""></app-vertical-bar>
      </div>

    </div>
  </div>

  <!-- CPA Statistics -->
  <div #cpa>
    <app-section-header section_title="CPA"></app-section-header>
    <div class="population-filter">
      <mat-form-field appearance="fill">
        <mat-label>Population Filter</mat-label>
        <mat-select [(value)]="selected">
          <mat-option value="pediatric">Pediatric</mat-option>
          <mat-option value="adult">Adult</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- Adult charts for cpa (defib) -->
    <div *ngIf="selected === 'adult'" app-dr-row>
      <div app-dr-tile>
        <h3> Adult First Pulseless Rhythm </h3>
        <app-vertical-bar [yAxisTicks]=[0,20,40,60,80,100] [chartData]="adult_first_pulseless_rhythm" xAxisLabel="">
        </app-vertical-bar>
      </div>
      <div app-dr-tile>
        <h3> Adult Any VF or Pulseless VT </h3>
        <app-vertical-bar [yAxisTicks]=[0,20,40,60,80,100] [chartData]="adult_anyvfpvt" xAxisLabel="" barPadding="16">
        </app-vertical-bar>
      </div>
      <div app-dr-tile>
        <h3> Adult Defibrillation Performed </h3>
        <app-vertical-bar [yAxisTicks]=[0,20,40,60,80,100] [chartData]="adult_defib" xAxisLabel="">
        </app-vertical-bar>
      </div>
      <div app-dr-tile>
        <h3> Adult Initial Condition </h3>
        <app-vertical-bar [yAxisTicks]=[0,20,40,60,80,100] [chartData]="adult_initial_condition" xAxisLabel="">
        </app-vertical-bar>
      </div>
    </div>
    <!-- Pediatric charts for cpa (defib)-->
    <div *ngIf="selected === 'pediatric'" app-dr-row>
      <div app-dr-tile>
        <h3> Pediatric First Pulseless Rhythm </h3>
        <app-vertical-bar [yAxisTicks]=[0,20,40,60,80,100] [chartData]="pediatric_first_pulseless_rhythm" xAxisLabel="">
        </app-vertical-bar>
      </div>
      <div app-dr-tile>
        <h3> Pediatric Any VF or Pulseless VT </h3>
        <app-vertical-bar [yAxisTicks]=[0,20,40,60,80,100] [chartData]="pediatric_anyvfpvt" xAxisLabel=""
          barPadding="16">
        </app-vertical-bar>
      </div>
      <div app-dr-tile>
        <h3> Pediatric Defibrillation Performed </h3>
        <app-vertical-bar [yAxisTicks]=[0,20,40,60,80,100] [chartData]="pediatric_defib" xAxisLabel="">
        </app-vertical-bar>
      </div>
      <div app-dr-tile>
        <h3> Pediatric Initial Condition </h3>
        <app-vertical-bar [yAxisTicks]=[0,20,40,60,80,100] [chartData]="pediatric_initial_condition" xAxisLabel="">
        </app-vertical-bar>
      </div>
    </div>
  </div>

  <!-- Right Sidebar -->
  <app-dr-sidebar sidebar
    collectionForm="https://www.heart.org/en/professional/quality-improvement/get-with-the-guidelines/get-with-the-guidelines-resuscitation/get-with-the-guidelines-resuscitation-registry-tool"
    factSheet="https://www.heart.org/-/media/Files/Professional/Quality-Improvement/Get-With-the-Guidelines/Get-With-The-Guidelines-Resuscitation/Resus-Fact-Sheet_20200807.pdf">
  </app-dr-sidebar>

</app-data-resource>