import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class GtmService {
  private renderer: Renderer2;
  constructor(private rendererFactory: RendererFactory2,
    ) { 
     // this.renderer = this.rendererFactory.createRenderer(null, null);
  }
  // injectGTM(gtmId: string): void {
  //   const script = this.renderer.createElement('script');
  //   script.type = 'text/javascript';
  //   script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 
  //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //   })(window,document,'script','dataLayer','${gtmId}');`;
  //   this.renderer.appendChild(window.document.head, script);
  // }
  // injectNoScript(gtmId: string): void {
  //   const noscript = this.renderer.createElement('noscript');
  //   const iframe = this.renderer.createElement('iframe');

  //   this.renderer.setAttribute(iframe, 'src', `https://www.googletagmanager.com/ns.html?id=${gtmId}`);
  //   this.renderer.setAttribute(iframe, 'height', '0');
  //   this.renderer.setAttribute(iframe, 'width', '0');
  //   this.renderer.setAttribute(iframe, 'style', 'display:none;visibility:hidden');
  //   this.renderer.appendChild(noscript, iframe);
  //   this.renderer.appendChild(window.document.body, noscript);
  // }
}
