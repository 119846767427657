
<app-dr-card iconName="&#xf084;" section_title="Request Access" sidebar>
  <p content>1. 
    <a class="inline-link" href="https://www.heart.org/en/professional/quality-improvement/quality-research-and-publications/national-level-program-data-research-opportunities">
      Submit</a> 
    a research proposal</p>
  <p content>2. Once accepted, execute a Non-Disclosure and Data Use Agreement</p>
  <p content>3. 
    <a class="inline-link" href="https://pmp.heart.org/request">
      Request</a> 
    a PMP workspace & dataset</p>
</app-dr-card>
<app-dr-card *ngIf="collectionForm || factSheet" iconName="&#xf481;" section_title="Registry Documentation" sidebar>
  <p content>View the data collection form and program measures and definitions.</p>
  <a class="rss-btn"
    *ngIf="collectionForm"
    target="_blank"
    href="https://www.heart.org/en/professional/quality-improvement/quality-research-and-publications/get-with-the-guidelines-registry-documentation"
    extra>View Documentation</a>
</app-dr-card>
<app-dr-card iconName="&#xf570;" section_title="Research" sidebar>
  <p content>Search for published research on Get With the Guidelines and view current research opportunities</p>
  <a class="rss-btn"
    href="https://www.heart.org/en/professional/quality-improvement/quality-research-and-publications"
    extra>Publications</a>
  <a class="rss-btn"
    href="https://www.heart.org/en/professional/quality-improvement/quality-research-and-publications/national-level-program-data-research-opportunities"
    extra>Opportunities</a>
</app-dr-card>
<app-dr-card *ngIf="python || r || sas" iconName="&#xf5fc;" section_title="Examples" sidebar>
  <a *ngIf="python" class="rss-btn" [href]="python" extra>Python</a>
  <a *ngIf="r" class="rss-btn" [href]="r" extra>R</a>
  <a *ngIf="sas" class="rss-btn" [href]="sas" extra>SAS</a>
</app-dr-card>