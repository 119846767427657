import { Component } from '@angular/core';
import { HorizontalBarComponent } from '../charts/horizontal-bar/horizontal-bar.component';
import { NormalizedHorizontalBarComponent } from '../charts/normalized-horizontal-bar/normalized-horizontal-bar.component';
import { VerticalBarComponent } from '../charts/vertical-bar/vertical-bar.component';
import { DataResourceComponent } from '../data-resource.component';
import { DrSidebarComponent } from '../dr-sidebar/dr-sidebar.component';
import { DrTileComponent } from '../dr-tile/dr-tile.component';
import { statisticData, countsData, arc_counts, cpa_counts, met_counts, pcac_counts, adult_anyvfpvt, adult_defib, adult_first_pulseless_rhythm, pediatric_anyvfpvt, pediatric_defib, pediatric_first_pulseless_rhythm, adult_initial_condition, pediatric_initial_condition } from './gwtg-resus-data';
import { DrRowComponent } from '../dr-row/dr-row.component';
import { SectionHeaderComponent } from '../../rgr-snapshot/section-header/section-header.component';
import { MatDivider } from '@angular/material/divider';
import { MatFormField } from '@angular/material/form-field';
import { MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/select';
@Component({
  selector: 'app-gwtg-resus',
  templateUrl: './gwtg-resus.component.html',
  styleUrls: ['./gwtg-resus.component.scss', '../data-resource.component.scss'],
  providers: [DataResourceComponent, HorizontalBarComponent, VerticalBarComponent, 
    DrSidebarComponent, DrTileComponent, NormalizedHorizontalBarComponent,
    DrRowComponent],
  imports: [DataResourceComponent, HorizontalBarComponent, VerticalBarComponent, 
    DrSidebarComponent, DrTileComponent, NormalizedHorizontalBarComponent,
    DrRowComponent, SectionHeaderComponent, MatDivider, MatFormField, MatLabel, MatSelect,MatOption],
    standalone: true
})
export class GwtgResusComponent {
  arc: any;
  cpa: any;
  met: any;
  pcac: any;
  defib1: any;
  defib2: any;
  statisticData: any;
  countsData: any;

  arc_counts: any;
  cpa_counts: any;
  met_counts: any;
  pcac_counts: any;
  defib1_counts: any;
  defib2_counts: any;

  rhythm_data: any;
  rhythm_distribution: any;
  rhythm_table_data: any;

  adult_first_pulseless_rhythm: any;
  adult_anyvfpvt: any;
  adult_defib: any;
  adult_initial_condition: any;
  pediatric_first_pulseless_rhythm: any;
  pediatric_anyvfpvt: any;
  pediatric_defib: any;
  pediatric_initial_condition: any;
  
  displayedColumns: string[] = ['Post Defib Rhythm', 'Newly-born', 'Neonatal', 'Pediatric', 'Adult']
  tableColumns: string[] = ['name', 'newlyBorn', 'neonatal', 'pediatric', 'adult']

  selected: string = 'adult';
  
  constructor() {
    Object.assign(this, { 
      statisticData, countsData,
      arc_counts, cpa_counts, met_counts, pcac_counts,
      adult_first_pulseless_rhythm,
      adult_anyvfpvt,
      adult_defib,
      adult_initial_condition,
      pediatric_first_pulseless_rhythm,
      pediatric_anyvfpvt,
      pediatric_defib,
      pediatric_initial_condition,
    })
  }

  countFirstTooltipFormatting(value, count) {
    return value + " (" + count + "%)";
  }
}