import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../shared/services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}
    adminRole = environment.adminRole;
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService;
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (currentUser && userInfo) {
            if(state.url.includes("/admin") && !userInfo.hasOwnProperty("custom:ad_groups") && !userInfo["custom:ad_groups"].includes(this.adminRole)){
                return false;
            }
            // authorised so return true         
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.authenticationService.logout(false, state.url);
        return false;
    }
}