import { Component } from '@angular/core';
import { AuthenticationService } from '../../services/auth.service';
@Component({
  selector: 'app-pricing-box',
  templateUrl: './pricing-box.component.html',
  styleUrls: ['./pricing-box.component.scss']
})
export class PricingBoxComponent {

  constructor(public authService: AuthenticationService) { }


}
