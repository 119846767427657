<div class="container container-wide lpa-container">

  <div class="row">
    <div class="col-9">
      <div class="lpa-header">
        <h1>Lp(a) Data Challenge</h1>
      </div>

      <div class="lpa-article-main">
        <p>
          High levels of lipoprotein(a) [Lp(a)] are an independent, predominantly inherited and causal risk factor for
          cardiovascular disease, the leading cause of death and disability worldwide, according to a recent American
          Heart
          Association scientific statement. It is estimated that 1 in 5 Americans have high Lp(a) levels. Studies have
          shown
          that elevated Lp(a) — a low-density lipoprotein variant containing a protein called apolipoprotein(a) — is a
          risk
          factor for atherosclerosis (buildup of fatty material in artery walls) and related diseases, such as coronary
          heart disease and stroke [1],[2]. However, there are no standard management approaches for diagnosis or risk
          assessment, nor any targeted treatments available to lower Lp(a).
        </p>

        <p>The American Heart Association is hosting a data challenge on our <a href="https://pmp.heart.org"
            target="_blank">Precision Medicine Platform</a> to encourage cross
          collaboration among researchers to deepen our understanding of how Lp(a) levels impact the risk of
          cardiovascular
          disease and stroke. Novartis is a proud supporter of the American Heart Association’s Precision Medicine Platform. </p>

        <hr>

        <h2>RESEARCH QUESTIONS</h2>

        <p>The Lp(a) data challenge seeks participants to advance the understanding of how Lp(a) levels impact the risk
          of
          cardiovascular disease and stroke. </p>

        <p>Participants will be asked to submit a research project that leverages the Lp(a) dataset comprised of
          clinical
          EHR data that will be made available AND/OR by bringing their own data for analysis on the Precision Medicine
          Platform. The data challenge focuses on multiple research topics, including but not limited to:</p>

        <ul>
          <li><span class="lpa-subtitle">Epidemiology / Risk Score. Examples include:</span>
            <ul>
              <li>Identify whether Lp(a) lab measurements improve risk prediction for ASCVD across multiple cohorts and
                data
                sets.</li>
              <li>Identify the role of Lp(a) in CVD risk in understudied, high-risk populations. </li>
              <li>What is the role of Lp(a) in high-risk populations including FH (familial hypercholesterolemia),
                chronic
                renal failure, diabetes, and the elderly?</li>
              <li>Do Lp(a) risk thresholds differ across populations (including HIV or other special populations
                (elderly,
                underserved/understudied, chronic illnesses))? </li>
              <li>Is there a relationship between Lp(a) and cardiovascular or other diseases that have not been
                extensively described (e.g., atrial fibrillation, kidney disease, diabetes)? </li>
            </ul>
          </li>
          <li><span class="lpa-subtitle">Characterize Lp(a) in individuals. Examples include:</span>
            <ul>
              <li>Identify Lp(a) profiles across sex, race, and ethnicity in the presence and absence of medications and
                other comorbidities. </li>
              <li>Identify Temporal relationships between ASCVD events and Lp(a) testing patterns. </li>
              <li>How does Lp(a) affect the body? Pro-inflammatory, pro-thrombotic theories</li>
            </ul>
          </li>
          <li><span class="lpa-subtitle">Genetics and Lp(a) (for those who have their own genetic data they would bring
              to the data challenge).
              Examples include:</span>
            <ul>
              <li>What is the genetic contribution of elevated Lp(a) to ASCVD risk?</li>
              <li>What is the underlying genetic risk for elevated Lp(a) across racial and ethnic sub-groups?</li>
            </ul>
          </li>
          <li><span class="lpa-subtitle">Treatment Patterns</span>
            <ul>
              <li>Is there an association between Lp(a) testing and medication intensification?</li>
            </ul>
          </li>
        </ul>
        <hr>
        <h2>DATA</h2>

        <ul>
          <li>
            We have licensed a <u>de-identified electronic health record (EHR) dataset</u> for patients with a Lp(a)
            measurement
            from <a href="/assets/downloads/lpa/NashBio-DataSource(Apr2023).pdf" target="_blank">Nashville
              Biosciences</a>.

            <ul>
              <li><span class="lpa-subtitle"> Dataset characteristics</span>
                <ul>
                  <li>
                    Total number of records: 11,134

                    <table id="lpa-info-table">
                      <tr>
                        <th scope="col">Unique patients for whom an exact numeric value of Lp(a) was measured.</th>
                        <td> 9,126</td>

                      </tr>
                      <tr>
                        <th scope="col">Unique patients for whom only a range value of Lp(a) was measured.</th>
                        <td> 2,268</td>

                      </tr>
                      <tr>
                        <th scope="col"><span class="lpa-subtitle">Total number of unique patients with Lp(a) measurement</span>
                        </th>
                        <td><span class="lpa-subtitle">11,134</span></td>

                      </tr>
                    </table>
                  </li>
                </ul>
              </li>

              <li><span class="lpa-subtitle">The dataset includes the following information:</span>

                <ul>
                  <li>
                    Demographics: age, date of birth, gender, race, ethnicity, zip code [3 digits], and insurance
                    status.
                  </li>
                  <li>Visit: visit_ID, start date, end date, visit type (inpatient, outpatient, or Emergency), and
                    discharge
                    destination.</li>
                  <li>ICD codes: code, entry date, type of code (ICD 9 CM or ICD 10 CM), position (primary diagnosis or
                    secondary), and visit_ID.</li>
                  <li>CPT codes: code, entry date, and visit_ID. </li>
                  <li>Measurement for labs and vital signs: measurement date, measurement name, numeric value, unit, and
                    visit_ID.</li>
                  <li>Drug information: drug exposure date, drug name, drug generic name, drug generic Rxnorm ID, dose,
                    unit, and visit_ID.</li>
                  <li>Smoking status </li>
                  <li>Death</li>
                  To access a detailed description of dataset, please <a
                    href="assets/downloads/lpa/2023-05-30_LP(a)_Data-Dictionary.xlsx">click here</a> to access a
                  copy of the Data Dictionary.
                </ul>
              </li>

              <li>Participants are also encouraged to bring your own data (BYOD) such as genetic data or other
                de-identified
                clinical data to use in conjunction with the dataset provided or on its own. Each participant can upload
                data into their own private workspace; no one else will have access to your data.</li>

              <li>All data must be uploaded and analyzed in a workspace on the Precision Medicine Platform to meet the
                data challenge submission requirements.</li>
            </ul>
          </li>
        </ul>

        <hr>

        <h2>HOW TO PARTICIPATE</h2>

        <ol>
          <li><a href="https://app.smartsheet.com/b/form/d010af929b7e40d88223460d9a76fcf4" target="_blank">Register
              here</a>
            for the Lp(a) data challenge.</li>
          <li>Once we review your registration, we will email you with a link to execute our standard Non-Disclosure &
            Data Use Agreement which will allow you to gain access to the Lp(a) data on the Precision Medicine Platform.
            Upon electronic execution of the agreement, you will receive instructions for requesting the Lp(a) dataset
            and a
            workspace on the Precision Medicine Platform
            <ol>
              <li>Each challenge participant/team will be provided with a workspace on the AHA’s Precision Medicine
                Platform at no cost to conduct all analyses. Use of the workspace is mandatory for participation in the
                challenge</li>
            </ol>
          </li>
          <li>Submit your research analysis by the deadline of <u>September 15th by 5 pm CT.</u></li>
        </ol>

        <hr>

        <h2>WHO CAN APPLY</h2>

        <ul>
          <li>Researchers from academic institutions.</li>
          <li>Diversity of perspectives and experiences is crucial to our success, so we encourage all to apply.</li>
        </ul>

        <hr>

        <h2>CHALLENGE TIMELINE</h2>

        <ol>
          <li><span class="lpa-subtitle">Now until September 15th</span>: Registration is open, participants analyze
            data on the Precision Medicine
            Platform.
          </li>
          <li><span class="lpa-subtitle">September 15, 2023 by 5 pm CT</span>: Data Challenge Submissions due.</li>
          <li><span class="lpa-subtitle">October 10, 2023</span>: Finalists will be selected and notified by this date.
          </li>
          <li><span class="lpa-subtitle">November 11-13, 2023</span>: Finalists present their research at Scientific
            Sessions and a winner is chosen.</li>
        </ol>

        <hr>

        <h2>PRIZES AND EVALUATION</h2>

        <h3>Prizes</h3>

        <p>Three prizes will be awarded to the top submissions. Recipients of the grand, second or third place prize
          will be
          required to publish a manuscript detailing the winning solutions and will be subject to the standard <a
            target="_blank"
            href="https://www.heart.org/en/professional/quality-improvement/quality-research-and-publications/national-level-program-data-research-opportunities">review
            process</a>.</p>

        <ul>
          <li>Grand prize: $40,000
          </li>
          <li>Second place: $25,000
          </li>
          <li>Third place: $10,000</li>
        </ul>

        <h3>Evaluation</h3>

        <p>Submissions will be reviewed by a peer review committee that includes experts and volunteers from the
          American
          Heart Association. Reviewers will evaluate the novel information learned from the analyses that address the
          focus
          of the research question. Specifically, the committee will assess the:</p>

        <ul>
          <li>Findings or results of the data analyses
          </li>
          <li>Data and data analyses (methods that support the findings)</li>
          <li>Novel information learned</li>
          <li>Overall impact of the findings and analyses on the question’s focus</li>
        </ul>

        <hr>

        <h2>DATA CHALLENGE SUBMISSION</h2>

        <h3>Submission Requirements</h3>

        <p>The Precision Medicine Platform provides an easy-to-use, web-based user interface that allows participants to
          access a secure, cloud-based environment. It contains a variety of standard software and packages such as
          Python
          and R. The platform workspace leverages <a href="https://pmp.heart.org/jupyter_notebooks/about"
            target="_blank">Jupyter Notebooks</a> and <a href="https://bookdown.org/yihui/rmarkdown/notebook.html"
            target="_blank">RStudio</a> to allow users to create notebooks to
          document and display results.</p>

        <p style="text-decoration: underline;">Each participant must submit a notebook from their individual workspace
          in
          the Precision Medicine Platform that
          includes the following:</p>

        <ul>
          <li>Full name of corresponding applicant and team members </li>
          <li>Institution (if applicable)</li>
          <li>Email address of corresponding applicant</li>
          <li>Phone number of corresponding applicant</li>
          <li>A 250-word description of the findings and how they can be applied.</li>
          <li>Description of data analysis and datasets used for establishing, testing, and validating models.</li>
          <li>Figures or tables (if applicable)</li>
          <li>Summary</li>
        </ul>

        <p>Please <a href="/assets/downloads/lpa/PMP_Notebook_instructions_Data_Challenge.pdf" target="_blank">click
            here</a> to review the detailed instructions for how to submit your notebook.</p>

        <p>Precision Medicine Platform workspaces will remain available to participants if you are working towards
          publication of a manuscript. If your data challenge submission is not selected as a finalist and you do not
          want
          to publish your research, we will decommission your workspace shortly after the conclusion of the data
          challenge.
        </p>

        <hr>

        <h2>Contact Information</h2>

        <p>Please email <a href="mailto:pmp@heart.org">pmp&#64;heart.org</a> with any questions regarding the data
          challenge.
        </p>



      </div>
    </div>
  </div>
</div>