import { Component } from '@angular/core';

@Component({
  selector: 'app-grid-section',
  templateUrl: './grid-section.component.html',
  styleUrls: ['./grid-section.component.scss'],
})
export class GridSectionComponent {

  constructor() { }

}
