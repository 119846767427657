<div class="row alert-banner banner-position" *ngFor="let banner of bannerList">
  <div class="col-12" id="banner_{{banner.index}}" *ngIf="banner.status == 'show'" style="margin-bottom:10px;">
    <div role="alert" aria-atomic="true" class="c-alert c-alert__active">
      <div class="container c-alert__container">
        <div style="margin-left:20px;" class="c-alert__message">
          <span class="c-alert__kicker">Alert</span>
        </div>
        <div style="display:flex;align-items:center;width: 100%" [innerHTML]="banner.banner_content">
        </div>
        <div style="margin-right:20px;" class="c-alert__actions">
          <button (click)="banner.status = 'hide'" type="button" aria-label="Close" class="c-alert__close btn btn-dark">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-para-block></app-para-block>
<app-feature-grid></app-feature-grid>
<app-small-banner></app-small-banner>
<app-timing-banner></app-timing-banner>
<app-grid-section></app-grid-section>
<app-contact-section></app-contact-section>
<!-- <app-pmp-banner titleId="home-container-title" subtitleId="home-container-subtitle"
  title="Accelerate Precision Medicine" subTitle="The AHA Precision Medicine Platform is a cloud-based technology solution that enables the global medical 
research community to accelerate breakthroughs in cardiovascular and brain diseases." buttonText="Register Now"
  buttonRoute="workspace">
</app-pmp-banner>

<div class="embed-border row">
  <div class="col-lg-1"></div>
  <div class="embed-responsive col-lg-6 col-12">
    <video id="learn-video" class="pmp-video"
      poster="https://s3.amazonaws.com/aha-pmp-videos/home_page/IntroducingAHAsPrecisionMedicinePlatform.png"
      controls="controls">
      <source id="vid_frame"
        src="https://s3.amazonaws.com/aha-pmp-videos/home_page/IntroducingAHAsPrecisionMedicinePlatform.mp4"
        type="video/mp4">
      Your browser does not support HTML5 video. Please update your browser to view this media content.
    </video>
  </div>
  <div class="pmp-right-sidebar col-lg-5 col-12">
    <img src="assets/images/precision-medicine-platform-logo.png" width="200" height="90">
    <br>
    <div style="margin-top:20px;">Streamlined access to diverse datasets. Data harmonization. State of the art analytics
      tools.</div>
    <button (click)="registerButtonClick()" id="registerNowBtn" class="btn btn-primary register-now">Register
      Now</button>
  </div>
</div>

<div class="row pmp-short-desc-container">
  <div class="pmp-short-description col-lg-12 col-12">
    The Precision Medicine Platform is a strategic initiative of the American Heart Association's
    Institute for Precision Cardiovascular Medicine.
  </div>
</div>

<div class="row aws-logo-section">
  <div class=" col-lg-12 col-12">
    <img src="/assets/images/aws-logo.png" alt="aws logo" aria-label="AWS Logo">
  </div>
</div> -->