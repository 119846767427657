import { Component } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/auth.service';
@Component({
  selector: 'app-ptfi-data-resource',
  templateUrl: './ptfi-data-resource.component.html',
  styleUrls: ['./ptfi-data-resource.component.scss']
})
export class PtfiDataResourceComponent {

  bannerStatus: any = true;
  constructor(public authenticationService: AuthenticationService) { }
}
