<div class="pricing-container text-center">
  <div class="row">
    <!-- First Section -->
    <div class="col-lg-6 d-flex flex-column align-items-center justify-content-center">
      <img src="assets/images/pricing/early-career.svg" class="mb-3" width="221" height="152">
      <h2 class="pricing-box-title">Early Career</h2>
      <p class="pricing-box-price">$1,300</p>
      <p class="pricing-box-subtitle">Annual License Fee</p>
      <p class="pricing-box-feature">Computational Cloud Credits<br /> <span class="pricing-box-highlight">FREE</span>
      </p>
      <p class="pricing-box-feature">Get With The Guidelines<br /> Data Administration<br /> <span
          class="pricing-box-highlight">$250/Per Proposal</span></p>
    </div>

    <!-- Second Section -->
    <div class="col-lg-6 d-flex flex-column align-items-center justify-content-center">
      <img src="assets/images/pricing/established.svg" class="mb-3" width="221" height="152">
      <h2 class="pricing-box-title">Established</h2>
      <p class="pricing-box-price">$5,200</p>
      <p class="pricing-box-subtitle">Annual License Fee</p>
      <p class="pricing-box-feature">Computational Cloud Credits<br /> <span class="pricing-box-highlight">FREE</span>
      </p>
      <p class="pricing-box-feature">Get With The Guidelines<br /> Data Administration<br /> <span
          class="pricing-box-highlight">$1000/Per Proposal</span></p>
    </div>
  </div>

  <!-- Button -->
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <button *ngIf="!authService.isLoggedIn()" (click)="authService.signIn('signup')" class="btn btn-primary mt-3 pricing-subscribe btn-transparent-red">SIGN UP</button>
    </div>
  </div>
</div>