function formatPercentage (value) {
    return value + '%'
}
function tooltipFormatting(value, count) {
    return value + "%" + " (" + count + ")"
}

export const settings = {
    redToWhite: {domain: ['#800000', '#aa0000', '#aa0000', '#ff0000', '#ff2a2a', '#ff5555', '#ff8080', '#ffaaaa', '#ffcccc', '#ffd5d5']},
    showLegend: false,
    showLabels: true,
    showXAxis: true,
    showYAxis: true,
    showGridLines: true,
    showDataLabel: true,
    trimXAxisTicks: false,
    trimYAxisTicks: false,
    maxYAxisTickLength: 30,
    legendTitle: '',
    showXAxisLabel: true,
    showYAxisLabel: true,
    xAxisLabel: '',
    yAxisLabel: '',
    formatPercentage: formatPercentage,
    legendPosition: 'right',
    labels: true,
    trimLabels: false,
    barPadding: 8,
    yAxisTicks: '',
    tooltipFormatting: tooltipFormatting,
    view: [730, 547]
}
