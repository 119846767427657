<div class="container-fluid">
  <div class="row">
    <!-- First part -->
    <div class="col-12 col-md-6 col-lg-3 part part-1 d-flex align-items-center justify-content-center">
      <p class="first-part-text"><span style="color:#C10E21">How</span> does it work?</p>
    </div>

    <!-- Second part -->
    <div class="col-12 col-md-6 col-lg-3 part part-2 d-flex flex-column align-items-start  text-left">
      <h3>Workspaces are designed for ease of use & efficiency.</h3>
      <p>Analyze data faster and more efficiently with ready-to-use tools. This eliminates the need to purchase hardware
        or build complex code for common work processes.</p>
      <button routerLink="/workspace" class="btn btn-light btn-transparent-white">LEARN MORE</button>
    </div>

    <!-- Third part -->
    <div class="col-12 col-md-6 col-lg-3 part part-3 d-flex flex-column align-items-start  text-left">
      <h3>Customize as needed.</h3>
      <p>Built to handle any type of data and analysis, researchers can leverage many integrated tools including R,
        Python, SAS, Jupyter, and more.</p>
      <!-- <button class="btn btn-dark">COMPATIBLE INTEGRATIONS</button> -->
    </div>

    <!-- Fourth part -->
    <div class="col-12 col-md-6 col-lg-3 part part-4 d-flex flex-column align-items-start  text-left">
      <h3>Bring your own data or use ours.</h3>
      <p>Gain access to Get With The Guidelines and Research Goes Red datasets from the AHA or Request access to various
        datasets from other contributors.</p>
      <button routerLink="/request" class="btn btn-light btn-blue btn-transparent-white">DATASETS & INFORMATION</button>
    </div>
  </div>
</div>