<div class="row ">
  <div class="col-12 col-md-6 d-flex justify-content-center flex-column contact-section">
    <h3 class="contact-section-title">Not Just for Medical Research.</h3>
    <p class="contact-section-subtitle">The Precision Medicine Platform’s analytics tools and software can be used with
      any type of dataset, in any
      field. Contact us to learn how many industries are leveraging the Precision Medicine Platform’s capabilities.</p>
    <button *ngIf="!authService.isLoggedIn()" (click)="authService.signIn('signup')" class="btn btn-primary btn-sign-up btn-transparent-red" type="button">
      SIGN UP
    </button>
  </div>
  <div class="col-12 col-md-6 no-padding-right"><app-contact-box></app-contact-box>
  </div>
</div>