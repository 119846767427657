<div class="container container-wide gcrff-container">

  <div class="row">
    <div class="col-9">
      <div class="gcrff-header">
        <h1>Global Cardiovascular Research Funders Forum Data Challenge</h1>
      </div>

      <div class="gcrff-article-main">
        <p>
          The Global Cardiovascular Research Funders Forum (GCRFF) invites scientific researchers to compete in a data challenge to better understand 
          how to improve acceleration of science through funding. The winning project is eligible to receive a $20,000 USD cash prize.
        </p>

        <p>The GCRFF is a coalition of major international cardiovascular research funders whose aim is to improve cardiovascular health worldwide by catalyzing, 
          supporting, and promoting transformational international research efforts in heart, stroke, and circulatory diseases. 
          The GCRFF works to identify research gaps, coordinate multinational clinical trials, share data, and oversee the international 
          research funding portfolio. </p>

        <p>Participants will use grant funding data provided by GCRFF member organizations, and have the option to bring their own data, 
          to conduct analyses using the American Heart Association’s <a href="https://pmp.heart.org" target="_blank">Precision Medicine Platform</a>.  
          The Precision Medicine Platform is a cloud-based system that allows researchers to collaborate and analyze datasets from any 
          computer in the world using a secure environment and the power of machine learning.</p>

        <hr>

        <h2>RESEARCH QUESTIONS</h2>

        <p>Participants will be asked to test different AI/ML models to assess which funded applications are most successful, 
          which could be measured by acquiring additional funding, meeting stated objectives, opening new research directions, or 
          providing impact on human health.  </p>

        <p><b>How can the organizations that fund cardiovascular and stroke science most effectively fund research to drive discoveries 
          and improve lives?</b>  The following are examples of topics and questions that can be asked of the funding data.</p>

        <ul>
          <li>Identify the current gaps in research funding for cardiovascular and stroke science.</li>
          <li>
            Identify the top funded topics and the return on the investment.
            <ul>
              <li>Could use publication number, impact factor of journal, etc.</li>
              <li>Publications could be linked to grants through the acknowledgement section where the grant ID and title is usually listed.</li>
            </ul>
          </li>
          <li>Build a prediction model for which funded applications are most successful (i.e. publication record of grantee, area of research)</li>
          <li>Current models of “success” used in academia and non-profits include number of publications, impact of the journal, number of citations.
            <ul>
              <li>
                Are there additional variables that best predict success? (changes to guidelines, patents, procedures in healthcare, etc.)
              </li>
            </ul>
          </li>
          <li>Build an interactive visualization map of GCRFF funding at the institutional level – including # of grants, keywords, amount funded, etc.</li>
          <li>Analyze proportion of funding vs. burden of disease to determine if certain areas are underfunded.</li>
        </ul>
        <p>*Participants may use data provided from GCRFF member organizations, and /or bring their own data.  </p>  



        <h2>DATA</h2>

        <ul>
          <li>
            Grant funding data provided from GCRFF member organizations.

            <ul>
              <li><span class="gcrff-subtitle">Dataset characteristics</span>
                
                    <table id="gcrff-info-table">
                      <tr>
                        <th scope="col">Number of GCRFF member organizations contributing data</th>
                        <td> 11</td>

                      </tr>
                      <tr>
                        <th scope="col">Number of grants</th>
                        <td> 233,701</td>

                      </tr>
                      <tr>
                        <th scope="col"><span class="gcrff-subtitle">Date range of dataset</span>
                        </th>
                        <td><span class="gcrff-subtitle">2017-2024*</span></td>

                      </tr>
                    </table>
                    *Most data is within this range, but there is some data that goes back to 2008.
                  
              </li>

              <li><span class="gcrff-subtitle">GCRFF Member Organizations:</span>
                <ul>
                  <li>
                    American Heart Association
                  </li>
                  <li>British Heart Foundation</li>
                  <li>Canadian Institutes of Health Research</li>
                  <li>Danish Heart Foundation </li>
                  <li>Deutsches Zentrum für Herz-Kreislaufforschung (German Centre for Cardiovascular Research)</li>
                  <li>Heart and Stroke Foundation of Canada</li>
                  <li>Leducq Foundation </li>
                  <li>National Heart Foundation of Australia</li>
                  <li>National Heart Foundation of New Zealand</li>
                  <li>National Heart, Lung, and Blood Institute</li>
                  <li>Swiss Heart Foundation</li>
                </ul>
              </li>

              


              <li><span class="gcrff-subtitle">The dataset includes the following data points:</span>
                <ul>
                  <li>Organization Name</li>
                    <li>Institution of grantee</li>
                    <li>Title provided by grant applicant</li>
                    <li>Type of grant</li>
                    <li>Name(s) of principal investigator(s)</li>
                    <li>Start date of grant</li>
                    <li>End date of grant</li>
                    <li>Keywords provided by applicant</li>
                    <li>Abstract provided by applicant</li>
                    <li>Amount funded</li>
                    <li>Currency type for funded amount</li>
                    <li>Funding range</li>
                </ul>
                Please click here to access a copy of the <a href="/assets/downloads/gcrff/GCRFF_Data_Dictionary.xlsx" target="_blank">Data Dictionary</a>.
              </li>

             

                
            </ul>
          </li>
          <li >We also encourage participants to bring their own data (BYOD) to use in conjunction with the dataset provided 
            (any additional data should be de-identified).  Each participant can upload data into their own private workspace on 
            the Precision Medicine Platform; no one else will have access to your data.</li>
          <li>All data must be uploaded and analyzed in a workspace on the Precision Medicine Platform to meet the data challenge submission requirements.</li>
        </ul>

        <hr>

        <h2>HOW TO PARTICIPATE</h2>

        <ol>
          <li><a href="https://app.smartsheet.com/b/form/ab8971cc21a04187ac40b76c30cab44e" target="_blank">Submit your research proposal here</a> 
            by September 30, 2024.</li>
          <li>All proposals will be reviewed, and registrants will be informed of the review decision by October 21, 2024.
            <ol>
              <li>If approved, you will receive an email with a link to execute our standard Non-Disclosure & Data Use Agreement which will 
                allow you to gain access to the data on the Precision Medicine Platform.  Upon electronic execution of the agreement, 
                you will receive instructions for requesting the dataset and a workspace on the Precision Medicine Platform.</li>
              <li>Each challenge participant/team will be provided with a workspace on the AHA’s Precision Medicine Platform at no cost to 
                conduct all analyses. Use of the workspace is mandatory for participation in the challenge.  </li>
            </ol>
          </li>
          <li>Submit your research analysis by the deadline of February 21, 2025 by 5 pm CT.</li>
        </ol>

        <hr>

        <h2>WHO CAN APPLY</h2>

        <ul>
          <li>Researchers from academic institutions.
            <ul>
              <li>Must be able to code using Python, R, or SAS.  Please review the <a href="https://pmp.heart.org/workspace/about" target="_blank">workspace tools</a> available on the Precision Medicine 
                Platform for more information</li>
            </ul>
          </li>
          <li>Diversity of perspectives and experiences is crucial to our success, so we encourage all to apply.</li>
        </ul>

        <hr>

        <h2>CHALLENGE TIMELINE</h2>

        <ol>
          <li><span class="gcrff-subtitle">Deadline extended to September 30, 2024:</span> Research proposals <a href="https://app.smartsheet.com/b/form/ab8971cc21a04187ac40b76c30cab44e" target="_blank"> due</a>.
          </li>
          <li><span class="gcrff-subtitle">October 21, 2024:</span> Selected proposals notified.</li>
          <li><span class="gcrff-subtitle">February 21, 2025 by 5 pm CT:</span> Data Challenge Submissions due.
          </li>
          <li><span class="gcrff-subtitle">April 7, 2025:</span> Finalists will be selected and notified by this date.</li>
          <li><span class="gcrff-subtitle">April 7th and beyond:</span>
            <ol type="a">
              <li>Finalists work towards abstract and publication.
                 
                </li>
                <li>Potential for finalists to submit an abstract to AHA Scientific Sessions 2025 or ESC 2025, etc.</li>
            </ol>
          </li>
        </ol>

        <hr>

        <h2>PRIZES AND EVALUATION</h2>

        <h3>Prizes</h3>

        <p>Three prizes will be awarded to the top submissions. Recipients of the grand, second or third place prize will be required to publish a manuscript detailing the winning solutions. </p>

        <ul>
          <li>Grand prize: $20,000
          </li>
          <li>Second place: $5,000
          </li>
          <li>Third place: $5,000</li>
        </ul>

        <h3>Evaluation</h3>

        <p>Data Challenge submissions will be reviewed by a peer review committee that includes experts and volunteers from the GCRFF. 
          Reviewers will evaluate the novel information learned from the analyses that address the focus of the research question.  
          Specifically, the committee will assess the:</p>

        <ul>
          <li>Findings or results of the data analyses
          </li>
          <li>Data and data analyses (methods that support the findings)</li>
          <li>Novel information learned</li>
          <li>Overall impact of the findings and analyses on the question’s focus</li>
        </ul>

        <hr>

        <h2>DATA CHALLENGE SUBMISSION</h2>

        <h3>Submission Requirements</h3>

        <p>The Precision Medicine Platform provides an easy-to-use, web-based user interface that allows participants to
          access a secure, cloud-based environment. It contains a variety of standard software and packages such as
          Python
          and R. The platform workspace leverages <a href="https://pmp.heart.org/jupyter_notebooks/about"
            target="_blank">Jupyter Notebooks</a> and <a href="https://bookdown.org/yihui/rmarkdown/notebook.html"
            target="_blank">RStudio</a> to allow users to create notebooks to
          document and display results.</p>

        <p style="text-decoration: underline;">Each participant must submit a notebook from their individual workspace
          in
          the Precision Medicine Platform that
          includes the following:</p>

        <ul>
          <li>Full name of corresponding applicant and team members </li>
          <li>Institution (if applicable)</li>
          <li>Email address of corresponding applicant</li>
          <li>Phone number of corresponding applicant</li>
          <li>A 250-word description of the findings and how they can be applied.</li>
          <li>Description of data analysis and datasets used for establishing, testing, and validating models.</li>
          <li>Figures or tables (if applicable)</li>
          <li>Summary (overall impact).</li>
        </ul>

        <p>Please <a href="/assets/downloads/lpa/PMP_Notebook_instructions_Data_Challenge.pdf" target="_blank">click
            here</a> to review the detailed instructions for how to submit your notebook.</p>

        <p>Precision Medicine Platform workspaces will remain available to participants after the challenge is over 
          if you are working towards publication of a manuscript.  If your data challenge submission is not selected as a 
          finalist and you do not want to publish your research, we will decommission your workspace shortly after the conclusion 
          of the data challenge.
        </p>

        <hr>

        <h2>Contact Information</h2>

        <p>Please email <a href="mailto:pmp@heart.org">pmp&#64;heart.org</a> with any questions regarding the data
          challenge.
        </p>



      </div>
    </div>
  </div>
</div>