// For GWTG-CAD V4 - Harvested April 2024
export var statisticData = [
    {
        "name": "COLLECTION DATES",
        "value": "2015-2023"
    },
    {
        "name": "RECORDS",
        "value": "663K"
    },
    {
        "name": "SITES",
        "value": "880"
    }
]
export var raceEthData = [
    {
        "name": "Non-Hispanic White",
        "value": 72.19,
        "extra": {
            "count": 451610
        }
    },
    {
        "name": "Non-Hispanic Black",
        "value": 12.96,
        "extra": {
            "count": 81109
        }
    },
    {
        "name": "Hispanic",
        "value": 8.23,
        "extra": {
            "count": 51463
        }
    },
    {
        "name": "Asian",
        "value": 3.33,
        "extra": {
            "count": 20826
        }
    },
    {
        "name": "American Indian or Alaska Native",
        "value": 0.61,
        "extra": {
            "count": 3797
        }
    },
    {
        "name": "Native Hawaiian or Pacific Islander",
        "value": 0.24,
        "extra": {
            "count": 1532
        }
    },
    {
        "name": "UTD",
        "value": 2.44,
        "extra": {
            "count": 15291
        }
    }
]
export var ageData = [
    {
        "name": "18-29",
        "value": 0.45,
        "extra": {
            "count": 2831
        }
    },
    {
        "name": "30-39",
        "value": 2.59,
        "extra": {
            "count": 16337
        }
    },
    {
        "name": "40-49",
        "value": 9.83,
        "extra": {
            "count": 61945
        }
    },
    {
        "name": "50-59",
        "value": 22.06,
        "extra": {
            "count": 139068
        }
    },
    {
        "name": "60-69",
        "value": 28.41,
        "extra": {
            "count": 179112
        }
    },
    {
        "name": "70-79",
        "value": 22.33,
        "extra": {
            "count": 140758
        }
    },
    {
        "name": "80-89",
        "value": 11.65,
        "extra": {
            "count": 73426
        }
    },
    {
        "name": "90-99",
        "value": 2.63,
        "extra": {
            "count": 16562
        }
    },
    {
        "name": "\u2265 100",
        "value": 0.07,
        "extra": {
            "count": 438
        }
    }
]
export var genderData = [
    {
        "name": "Male",
        "value": 66.65,
        "extra": {
            "count": 416505
        }
    },
    {
        "name": "Female",
        "value": 33.35,
        "extra": {
            "count": 208425
        }
    }
]
export var diagnosisData = [
    {
        "name": "Confirmed AMI - non-STEMI",
        "value": 52.14,
        "extra": {
            "count": 245353
        }
    },
    {
        "name": "Confirmed AMI - STEMI",
        "value": 46.72,
        "extra": {
            "count": 219858
        }
    },
    {
        "name": "Confirmed AMI - STEMI/Non-STEMI unspecified",
        "value": 1.13,
        "extra": {
            "count": 5336
        }
    }
]
