<div class="ptfi-section">
  <div class="container">
      <div class="row">
          <div class="col-12 d-flex flex-column flex-md-row">

              <div class="ptfi-logo-section mb-3 mb-md-0">
                  <img class="ptfi-logo img-fluid" src='assets/images/ptfi/PTFI_logo.png' alt='ptfi logo'>
              </div>

              <div class="ptfi-intro flex-grow-1">
                  <h4 class="red-header">About the Periodic Table of Food Initiative</h4>
                  <p><b>Unlocking Food Composition Data for Human and Planetary Health</b></p>
                  <p>Utilize a workspace to further analyze food specimens and ask questions such as:</p>
                  <p>1) What is in our food?</p>
                  <p>2) How does what is in our food vary by genetic diversity within species, agriculture practices, climate conditions and environment?</p>
                  <p>3) What are the implications of this variation on human and planetary health?</p>
              </div>

              <div class="ptfi-info-card">
                  <div class="dataset-stat-card">
                      <span class="ptfi-info-card-title">Latest Version</span>
                      <h5 class="ptfi-info-card-subtitle">August 2024</h5>
                  </div>
                  <div class="dataset-stat-card">
                      <span class="ptfi-info-card-title">Number of Food Specimens</span>
                      <h5 class="ptfi-info-card-subtitle">500</h5>
                  </div>
              </div>

          </div>
      </div>
  </div>
</div>


<div class="ptfi-section container">
  <div class="row ptfi-data">
    <div class="col-12">
      <h5>Explore PTFI Data with PMP</h5>
    <p>
      The Periodic Table of Food Initiative is mapping food quality around the globe to improve human and planetary health. The PTFI Database provides biomolecular data and associated meta-data on a wide variety of foods, generated by our growing ecosystem of researchers utilizing standardized multi-omics analytical platforms. Along with food biomolecular composition data, metadata may include environmental, socio-cultural, and economic data. Researchers can use the PMP to search the PTFI database and explore food biomolecular data and associated metadata using a cloud-based workspace integrated with the power of A.I.
      </p>

      <ul>
        <li>How to request a workspace with PTFI data on the PMP

          <ul>
            <li>Review the <a href="https://pmp.heart.org/getting-started" target="_blank">Getting Started</a> guide for steps on how to register and submit a dataset and workspace request.  In short, the steps are:
              <ul>
                <li><a href="javascript:void(0);" (click)="authenticationService.signIn('signup')" >Register</a> for an account.</li>
                <li>Login and navigate to the <a href="https://pmp.heart.org/request" target="_blank">Data</a> page.</li>
                <li>Select and request the PTFI dataset, answer all questions including accepting the <a href="assets/documents/ptfi/PTFI_Data_Use_Agreement_AHAPMP_April1_2024.pdf" target="_blank">PTFI Terms and Conditions of Data Use</a>, and submit.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>How to view/analyze the data

          <ul>
            <li>Once you have been provisioned a workspace on the PMP with the PTFI dataset you can:

              <ul>
                <li>View the PTFI dataset from our database user interface (UI) that provides you with a great way to view, interact, and initiate Exploratory Data Analysis (EDA).</li>
                <li>Analyze the data within a workspace and utilize the PTFI tutorial to get you started.</li>
                <li>Reference the <a target="_blank" href="https://ptfi.community.versobio.com/c/knowledgebase/data-dictionary/100">data dictionary</a> for a listing of all the variables, formats, and coding.</li>
              </ul>
            </li>
          </ul>
        </li>

        <li>PTFI data pipeline

          
        </li>
        <img class="ptfi-data-img" src="assets/images/ptfi/ptfi-data-pipeline.png" />

        <li>Explore more! You may have already visited one of the PTFI websites, but if not, here are some links about the PTFI and other ways of exploring PTFI data for your reference.
          <ul>
            <li>
                <a href="https://foodperiodictable.org/" target="_blank">The Periodic Table of Food Initiative (PTFI) (foodperiodictable.org) </a>
            </li>
            <li> <a href="https://ptfi.markerlab.com/" target="_blank">PTFI Markerlab</a>: PTFI MarkerLab is a web-based visualization platform that makes multi-omics data more accessible, interpretable, and valuable for labs and researchers.</li>
            <li> <a href="https://ptfi.versobio.com/" target="_blank" >PTFI Research Hub</a></li>
            <li> <a href="https://ptfi.community.versobio.com/" target="_blank">PTFI Research Community</a>
              <ul>
                <li><a href="https://ptfi.community.versobio.com/t/ptfi-food-sampling-overview/183" target="_blank">Description of specimens and samples</a></li>
                  <li>PTFI Core Metadata: <a href="https://ptfi.community.versobio.com/t/food-biospecimen-specimen-collection/186" target="_blank"> How food specimens are collected</a>
                  </li>
              </ul>
            </li>
          </ul>
        </li>

        <li>The dataset includes two types of Food Composition data: Reference data and Discovery data.

          <ul>
            <li>Food Composition Reference data includes: 
              <ul>
                <li>Proximate analysis for overall macronutrient composition</li>
                <li>Fatty Acid Methyl Esters (FAME) analysis for fatty acid content</li>
                <li>PTFI Lipidomics for complex lipids</li>
                <li>PTFI Ionomics for minerals and metals </li>
                <li>PTFI Glycomics for fibers </li>
                <li>Proteomics for proteins</li>
              </ul>
            </li>
            <li>Food Composition Discovery Data
              <ul>
                <li>Explore the relative abundances of small molecules in a wide array of foods, generated from PTFI’s untargeted metabolomics method. </li>
                <li>Many specialized metabolites and other analytes in this database are unnamed. </li>
                <li>Untargeted metabolomics includes some level of ambiguity in annotation and quantitation.</li> 
              </ul>
            </li>
          </ul>
        </li>

        <li>The Food Composition Discovery Data is for those who want to explore the dark matter of food! Hierarchy of PTFI data:</li>
        <img class="img-fluid" src="assets/images/ptfi/ptfi-hierarchy.jpg" />
      </ul>

      <p>
        <i>Specimen</i> is the principal term for a controlled grouping or singular food/bio-specimen.</p>
        <p><i>Sample</i> represents the specimen following any necessary preparation steps for storage. In some cases, a single specimen will generate a single sample while other cases could result in multiple samples coming from a single specimen through different preparation methods (i.e. raw or cooked) or subsections of specimens (i.e. lemon zest, lemon juice of a meyer lemon). Sample is always parented to Specimen.</p>
          <p><i>Aliquot</i> is the final hierarchy level and reflects individual milligram (mg) amounts of a sample. Aliquot is always parented to Sample.</p>
<h5> How to Attribute PTFI Data Analysis via the PMP in Publications</h5>
<p>Data users are to attribute the use of PTFI data and all information derived from data exploration in the PMP in all publications, presentations, websites, databases or other data collections, and other material assets will be appropriately attributed using the following statement: “This [publication] has sourced food composition data and other data on biochemical annotations and abundances, along with associated metadata, from the Periodic Table of Food Initiative. This data and metadata has been collected, contributed, and generated by global Data Contributors using standardized multi-omics tools provided by the Periodic Table of Food Initiative and is licensed under CC-BY-NC-4.0 [https://creativecommons.org/licenses/by-nc/4.0/]. More information can be found at foodperiodictable.org [https://foodperiodictable.org/]”

      </p>
  </div>
</div>

</div>
