declare var DDP_ENV: any;

import { ActivatedRoute} from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../shared/services/base.service';
@Injectable({ providedIn: 'root' })
export class TechnicalSupportService extends BaseService {

    constructor(private http: HttpClient, private route: ActivatedRoute) {
        super(http, route);
    }

    public submitRequest(request: any){
      
      let url = this.getBaseUrl() + "public/contactUs";
      let headers = this.buildHeaders();
      return this.http.post(url, JSON.stringify(request))
    }
}