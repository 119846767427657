import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nested-accordion',
  templateUrl: './nested-accordion.component.html'
})
export class NestedAccordionComponent {
  @Input() data: any;
  @Input() isRoot: boolean = false;

}