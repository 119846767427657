<div class="row pmp-top-section" >
  <div class="col-lg-12 col-md-12">
    <div class="pmp-banner">
      <div class="banner-content">
        <h1 id="{{titleId}}" [ngClass]="{'center': location}" class="banner-header">{{title}}</h1>
        <div class="pad10"></div>
        <p *ngIf="location !== 'center'" id="{{subtitleId}}" class="banner-content-label">
            {{subTitle}}
        </p>
        <div class="pad3"></div>
        <button *ngIf="buttonText" (click)="bannerButtonClick()" id="registerNowBtn" class="btn btn-primary register-now">{{buttonText}}</button>
      </div>
    </div>
  </div>
</div>