import { Component } from '@angular/core';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { AuthenticationService } from '../app/shared/services/auth.service';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt';
import { filter } from 'rxjs/operators';
import { TitleService } from './shared/services/title.service';
import { InactivityTimerService } from './shared/services/inactivity-timer-service.service';
import { GtmService } from './shared/services/gtm.service';
import { environment } from 'environments/environment';
import { CommonModule } from '@angular/common';
import { PreLoginHeaderComponent } from './core/prelogin-header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import jwt_decode from 'jwt-decode';
import { LoaderService } from './shared/services/loaderService';
@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet, PreLoginHeaderComponent, FooterComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
  })
export class AppComponent {
    title = 'aha-pmp-web';
    isHomePage: boolean;
    isApprover: boolean;
    isSupport: boolean;
    isAdmin: boolean;
    userInfo : any;
    isLoading = false;
    private gtmId = environment.google_analytics;
    constructor(private loaderService: LoaderService, private scriptInjector: GtmService,private router: Router, private authService: AuthenticationService,private inactivityTimerService: InactivityTimerService,
        private titleService: TitleService
    ) {
        this.titleService.initializeTitleService();
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)  
          ).subscribe((event: NavigationEnd) => {
            // If the url is '/', then we're on the home page
            this.isHomePage = event.url === '/' || event.url === '' || event.url.includes('contact');
            this.isAdmin = event.url.includes('admin')
            this.isSupport = event.url.includes('support')
            this.isApprover = event.url.includes('approver')
          });
         
     }

    ngOnInit() {
        this.loaderService.loaderState.subscribe(state => {
            this.isLoading = state;
          });
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
        this.userInfo = localStorage.getItem('currentUser')
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo)
            const decodedToken: any = jwt_decode(this.userInfo['IdToken']);
            const currentTime = new Date().getTime();
           
            if (decodedToken.exp * 1000 < currentTime) {
              // Token is expired, log out the user
              this.authService.logout(true)
              this.inactivityTimerService.stopInactivityTimer()
            }
            }else{
                this.inactivityTimerService.startInactivityTimer();
            }
        // if(this.userInfo){
        //     this.userInfo = JSON.parse(this.userInfo)
        //     let expiry = this.userInfo.ExpiresIn
        //     // add 2 mins to avoid unnecessary removal
        //     let currentTime = Math.floor(Date.now() / 1000) + 120;
        //     if(expiry < currentTime){
        //         localStorage.removeItem('currentUser');
        //     }else{
        //         this.inactivityTimerService.startInactivityTimer();
        //     }
        // }
        // if(this.gtmId != ""){
        // this.scriptInjector.injectGTM(this.gtmId);
        // this.scriptInjector.injectNoScript(this.gtmId);
        // }
    }
}
