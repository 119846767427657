import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../../app/shared/services/auth.service';
import { AuthssoService } from '../../../../app/shared/services/authsso.service';
import { SharedModule } from '../../../../app/shared/shared.module';

@Component({
  selector: 'app-adgroup-login',
  standalone:true,
  imports: [SharedModule],
  templateUrl: './adgroup-login.component.html',
  styleUrls: ['./adgroup-login.component.scss']
})
export class AdgroupLoginComponent implements OnInit {

  constructor(private auth: AuthenticationService, public router: Router, private route: ActivatedRoute,private authsso:AuthssoService) { }

  ngOnInit() {
    this.authsso.parseCognitoCallback();


  }

}
