import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../shared/services/auth.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';  // Adjust the path

@Component({
  selector: 'app-refresh-token-alert',
  templateUrl: './refresh-token-alert.component.html',
  styleUrls: ['./refresh-token-alert.component.scss'],
  imports:[CommonModule, SharedModule],
  standalone: true,
})
export class RefreshTokenAlertComponent implements OnInit {
  open;
  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
    this.open = this.authService.getrefreshtokenBlock();
  }


  onAlertChange(event) {
    if (event) {
      this.authService.setInactivityTimer();
      this.authService.resetrefreshtokenBlock();
    } else {
      this.authService.resetrefreshtokenBlock();
    }
  }
}
