<!-- Identify where the chart should be drawn. -->
<br><br>

<!-- Title Card -->
<div class="snapshot-title container">
  <h1>Research Goes Red Data Snapshot</h1>

  <p> These public-facing data snapshots provide an overview of Research Goes Red (RGR) participant characteristics.
  </p>

  <!-- *** Change number of participants each update *** -->
  <p> More than {{ participantCounts['overall'].toLocaleString() }} people have consented into RGR from the <a href="http://projectbaseline.com"> Project Baseline </a> and completed all initial steps of the program. The snapshots below highlight participants in the RGR Program. </p>

  <!-- *** Change collection date each update *** -->
  <p> Numbers reflect data collected by {{ participantCounts['harvest_date'] }}. </p>
</div>

<!-- Diversity Statistics -->
<div class="diversity-section container">
  <app-section-header section_title="Diversity"></app-section-header>

  <div class="row pb-5 no-gutters">
    <div class="diversity-raceEth col-lg-6">
      <h3> Race & Ethnicity </h3>
      <p>
        <!-- *** Change text depending on stats each update *** -->
        A majority of participants are Non-Hispanic White, followed by Non-Hispanic Black and Hispanic.
      </p>
      <br>

      <div class="chart-view">
        <ngx-charts-bar-horizontal [scheme]="redToWhite" [results]="raceEthData" [xAxis]="showXAxis" [yAxis]="showYAxis"
          [showGridLines]="showGridLines" [legend]="showLegend" [showXAxisLabel]="raceEthShowXAxisLabel"
          [showYAxisLabel]="raceEthShowYAxisLabel" [trimYAxisTicks]="trimYAxisTicks"
          [xAxisTickFormatting]="formatPercentage" [xAxisLabel]="raceEthXAxisLabel" [yAxisLabel]="raceEthYAxisLabel"
          [showDataLabel]="showDataLabel" [dataLabelFormatting]="formatPercentage">
          <ng-template #tooltipTemplate let-model="model">
            <div class="container pt-3 pl-3 pr-3 pb-2 text-center">
              <div *ngFor="let data of raceEthData">
                <div *ngIf="data.name == model.name">
                  <p>{{data.name}}</p>
                  <p>{{data.value}}% ({{data.extra.count}})</p>
                </div>
              </div>
            </div>
          </ng-template>
        </ngx-charts-bar-horizontal>
      </div>
    </div>

    <div class="diversity-age col-lg-6">
      <h3> Age </h3>
      <!-- *** Change text depending on stats each update *** -->
      <p> The age of our Research Goes Red participants shows to follow a normal distirbution, with the median number
        being around the 40-49 age group. </p>

      <div class="chart-view">
        <ngx-charts-bar-vertical [scheme]="redToWhite" [results]="ageData" [xAxis]="showXAxis" [yAxis]="showYAxis"
          [legend]="showLegend" [showXAxisLabel]="ageShowXAxisLabel" [showYAxisLabel]="ageShowYAxisLabel"
          [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [yAxisTickFormatting]="formatPercentage"
          [showGridLines]="showGridLines" [showDataLabel]="showDataLabel" [dataLabelFormatting]="formatPercentage">
          <ng-template #tooltipTemplate let-model="model">
            <div class="container pt-3 pl-3 pr-3 pb-2 text-center">
              <div *ngFor="let data of ageData">
                <div *ngIf="data.name == model.name">
                  <p> Ages {{data.name}} </p>
                  <p>{{data.value}}% ({{data.extra.count}})</p>
                </div>
              </div>
            </div>
          </ng-template>
        </ngx-charts-bar-vertical>
      </div>
    </div>
  </div>

  <div class="row no-gutters">
    <div class="col">
      <h3> Geographical Distribution </h3>
    </div>
  </div>

  <div class="row no-gutters">
    <div class="geo-dist col">

      <div class="geo-chart">
        <!-- *** Change text depending on stats each update *** -->
        <p> The states with the highest RGR participation are Ohio, Texas, and California. </p>
        <!-- <google-chart [type]="geoChart" [data]="geoDistData" [columns]="geoColumns" [options]="geoOptions"
          [dynamicResize]="true">
        </google-chart> -->
      </div>
    </div>
  </div>

</div>

<!-- Medical Conditions Statistics -->
<div class="medical-conditions container">
  <app-section-header section_title="Medical Conditions"></app-section-header>

  <div class="row no-gutters">
    <div class="top-10 col-lg-7">
      <h3> Top 10 Medical Conditions </h3>
      <!-- *** Change text depending on stats each update *** -->
      <p> The most common conditions among RGR participants are high blood pressure, anxiety disorder, and depression.
      </p>
      <div class="chart-view">
        <ngx-charts-bar-horizontal [scheme]="redToWhite" [results]="top10MedData" [xAxis]="showXAxis"
          [yAxis]="showYAxis" [showGridLines]="showGridLines" [legend]="showLegend"
          [showXAxisLabel]="raceEthShowXAxisLabel" [showYAxisLabel]="raceEthShowYAxisLabel"
          [xAxisTickFormatting]="formatPercentage" [trimYAxisTicks]="trimYAxisTicks" [xAxisLabel]="raceEthXAxisLabel"
          [yAxisLabel]="raceEthYAxisLabel" [showDataLabel]="showDataLabel" [dataLabelFormatting]="formatPercentage">
          <ng-template #tooltipTemplate let-model="model">
            <div class="container pt-3 pl-3 pr-3 pb-2 text-center">
              <div *ngFor="let data of top10MedData">
                <div *ngIf="data.name == model.name">
                  <p> {{data.name}} </p>
                  <p>{{data.value}}% ({{data.extra.count}})</p>
                </div>
              </div>
            </div>
          </ng-template>
        </ngx-charts-bar-horizontal>
      </div>
    </div>

    <div class="heart-attack-stroke col-lg-5">
      <div class="box">
        <h3> Within the past 10 years... </h3>

        <div class="box-row">
          <img src="/assets/images/rgr/heart_attack_icon.png" alt="stroke icon"
            style="width: 90px; padding-left: 10px;">
          <div class="box-row-text">
            <p> <span style="color: #D40000; font-weight: 600;"> {{ haStrokeData['heart_attack'] }}% of participants
              </span> reported having experienced a heart attack </p>
          </div>
        </div>

        <div class="line">
          <hr style="border: 1px solid #6D6371">
        </div>

        <div class="box-row">
          <img src="/assets/images/rgr/stroke_icon.jpg" alt="stroke icon" style="width: 90px; padding-left: 10px;">
          <div class="box-row-text">
            <p> <span style="color: #D40000; font-weight: 600;"> {{ haStrokeData['stroke'] }}% of participants </span>
              reported having experienced a stroke </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- Active Surveys -->
<div class="active-surveys-section container">
  <app-section-header section_title="Active Surveys"></app-section-header>

  <div class="row">
    <div class="col-md-4">
      <div class="survey-card">
        <img src="/assets/images/rgr/reproductive_health.jpg" alt="pregnant woman with heart on stomach">
        <div class="survey-content">
          <h4> Reproductive Health </h4>
          <!-- *** Change number of participants each update *** -->
          <p> <span class="p-count"> {{ participantCounts['reproductive_health'].toLocaleString() }} Participants </span> </p>
          <p> Helps us better understand the complicated intersection between cardiovascular disease and pregnancy. </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="survey-card">
        <img src="/assets/images/rgr/top_health_concerns.jpg" alt="woman looking into the distance">
        <div class="survey-content">
          <h4> Top Health Concerns </h4>
          <!-- *** Change number of participants each update *** -->
          <p> <span class="p-count"> {{ participantCounts['rgr_survey'].toLocaleString() }} Participants </span> </p>
          <p> Share what health concerns the participant has to prioritize the research question. </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="survey-card">
        <img src="/assets/images/rgr/health_care_exp.jpg" alt="healthcare worker talking to patient">
        <div class="survey-content">
          <h4> Health Care Experiences and Perceptions </h4>
          <!-- *** Change number of participants each update *** -->
          <p> <span class="p-count"> {{ participantCounts['hce_perceptions'].toLocaleString() }} Participants </span> </p>
          <p> Share the experience with medical professionals and the health care system to help better understand patient experiences and perceptions. </p>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="row explore">
      <a class="explore-button" target="_blank" href="https://drive.google.com/drive/folders/1p0BGykSMcTjxVUY0b0OKj-8dMwOLXyE7"> Explore RGR Surveys </a>
  </div> -->
</div>