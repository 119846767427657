// For GWTG-Stroke - Harvested March 2024
export var statisticData = [
    {
        "name": "COLLECTION DATES",
        "value": "2003-2023"
    },
    {
        "name": "RECORDS",
        "value": "8.9M" // 8,902,693
    },
    {
        "name": "SITES",
        "value": "3.0K" // 3,045
    }
]

export var raceEthData = [
    {
        "name": "Non-Hispanic White",
        "value": 68.24,
        "extra": {
            "count": 6063957
        }
    },
    {
        "name": "Non-Hispanic Black",
        "value": 16.41,
        "extra": {
            "count": 1457898
        }
    },
    {
        "name": "Hispanic",
        "value": 7.78,
        "extra": {
            "count": 691696
        }
    },
    {
        "name": "Asian",
        "value": 3.06,
        "extra": {
            "count": 272293
        }
    },
    {
        "name": "Native American",
        "value": 0.39,
        "extra": {
            "count": 34427
        }
    },
    {
        "name": "Pacific Islander",
        "value": 0.29,
        "extra": {
            "count": 25960
        }
    },
    {
        "name": "Other",
        "value": 3.83,
        "extra": {
            "count": 340377
        }
    }
]
export var ageData = [
    {
        "name": "18-29",
        "value": 0.77,
        "extra": {
            "count": 68310
        }
    },
    {
        "name": "30-39",
        "value": 2.26,
        "extra": {
            "count": 200917
        }
    },
    {
        "name": "40-49",
        "value": 6.75,
        "extra": {
            "count": 601065
        }
    },
    {
        "name": "50-59",
        "value": 15.16,
        "extra": {
            "count": 1350016
        }
    },
    {
        "name": "60-69",
        "value": 21.99,
        "extra": {
            "count": 1957802
        }
    },
    {
        "name": "70-79",
        "value": 24.36,
        "extra": {
            "count": 2168404
        }
    },
    {
        "name": "80-89",
        "value": 21.71,
        "extra": {
            "count": 1932966
        }
    },
    {
        "name": "90-99",
        "value": 6.75,
        "extra": {
            "count": 601012
        }
    },
    // {
    //     "name": "nan",
    //     "value": 0.06,
    //     "extra": {
    //         "count": 5709
    //     }
    // },
    {
        "name": "\u2265 100",
        "value": 0.19,
        "extra": {
            "count": 16492
        }
    }
]
export var genderData = [
    {
        "name": "Female",
        "value": 50.92,
        "extra": {
            "count": 4530266
        }
    },
    {
        "name": "Male",
        "value": 49.08,
        "extra": {
            "count": 4365754
        }
    }
]
export var diagnosisData = [
    {
        "name": "Ischemic Stroke",
        "value": 68.82,
        "extra": {
            "count": 6126944
        }
    },
    {
        "name": "Transient Ischemic Attack (< 24 hours)",
        "value": 14.81,
        "extra": {
            "count": 1318098
        }
    },
    {
        "name": "Intracerebral Hemorrhage",
        "value": 11.43,
        "extra": {
            "count": 1017497
        }
    },
    {
        "name": "Subarachnoid Hemorrhage",
        "value": 3.87,
        "extra": {
            "count": 344956
        }
    },
    {
        "name": "Stroke not otherwise specified",
        "value": 1.07,
        "extra": {
            "count": 95198
        }
    }
]
