import { Component } from '@angular/core';
@Component({
  selector: 'app-ptfi-data-challenge',
  templateUrl: './ptfi-data-challenge.component.html',
  styleUrls: ['./ptfi-data-challenge.component.scss']
})
export class PtfiDataChallengeComponent {

  constructor() { }

}
