<!--The content below is only a placeholder and can be replaced.-->
<ng-container *ngIf="!isAdmin && !isApprover && !isSupport">
    <ng-container *ngIf="isLoading">
        <aui-loader type="page"></aui-loader>
    </ng-container>
    <app-pre-login-header *ngIf="!isHomePage" linkColor="#000"></app-pre-login-header>
    <app-pre-login-header *ngIf="isHomePage" isHomePage="isHomePage" showVideo="true"
        linkColor="#fff"></app-pre-login-header>
</ng-container>
<router-outlet></router-outlet>
<ng-container *ngIf="!isAdmin && !isApprover && !isSupport">
    <app-footer></app-footer>
</ng-container>