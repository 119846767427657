import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent {
  @Input() open: boolean = false;
  @Input() message: string = '';
  @Input() closeText = 'CLOSE';
  @Input() acceptText: string;
  @Output() openChange: EventEmitter<void | boolean> = new EventEmitter<void | boolean>();


  constructor() { }

  onModalClose(accpect = false) {
    this.open = false;
    this.openChange.emit(accpect);
  }
}
