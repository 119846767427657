import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss'],
  imports: [CommonModule, SharedModule],
  standalone: true
})
export class UserGuideComponent {

  constructor(private router: Router) { }
  scrollToElement(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }else{
      this.router.navigateByUrl('/').then(() => {
        // Wait for navigation to complete, then scroll to the element
        this.scrollToElement('aha-footer')
      });
    }
  }

}
