import { Component, HostBinding } from '@angular/core';
import { DataResourceStateService } from '../data-resource-state.service';

@Component({
  selector: '[app-dr-tile]',
  templateUrl: './dr-tile.component.html',
  styleUrls: ['./dr-tile.component.scss'],
  standalone: true
})
export class DrTileComponent {

  constructor(public dataResourceState: DataResourceStateService) { }
  @HostBinding('class.col-md-12') get collapseIsOpen() { return this.dataResourceState.collapseOpen; }
  @HostBinding('class.col-lg-6') get collapseIsClosed() { return !this.dataResourceState.collapseOpen; }


}
