import { Component, Input } from '@angular/core';
import { AuthenticationService } from "../../services/auth.service";
@Component({
  selector: 'app-mini-box',
  templateUrl: './mini-box.component.html',
  styleUrls: ['./mini-box.component.scss']
})
export class MiniBoxComponent {

  @Input() title: string;
  @Input() subtitle: string;

  constructor(public authService: AuthenticationService) { }


}
