import { Component, OnInit } from '@angular/core';
import { SystemFailureService } from './system-failure.service';
import { CommonModule } from '@angular/common'; // Import CommonModule

@Component({
  selector: 'app-system-failure',
  templateUrl: './system-failure.component.html',
  styleUrls: ['./system-failure.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class SystemFailureComponent implements OnInit {

  systemFailure = false;

  constructor(private systemFailureService: SystemFailureService) { }

  ngOnInit() {
    // To get Query Params when App is Initialized.
    const qParams = window.location.search;

    // To check if is redirected from SSO.
    if (!qParams || !qParams.includes('code=')) {
      // Get system health if not redirected from SSO.
      this.systemFailureService.serviceHealth().subscribe(
        systemFailed => this.setServieFailure(systemFailed),

        // To show failure banner if API fails.
        err => this.setServieFailure(true)
      );
    }
  }

  setServieFailure(systemFailed: boolean) {
    this.systemFailure = systemFailed;
  }

}
