<app-title-subtitle [title]="'Frequently Asked Questions (FAQ)'"
  ></app-title-subtitle>


  <div class="root">
    <div class="wrapper">
      <app-content-navigation [sections]="[
      {'ref': functionality, 'name': 'Functionality'},
      {'ref': data, 'name': 'Data'},
      {'ref': general, 'name': 'General'},
      {'ref': credits, 'name': 'Credits'}
    ]"></app-content-navigation>
      <div class="user-guide-container documentation">
        <div class="section">
          <h2 #functionality>Functionality</h2>
          
          <div class="faq">
            <h5>Can I access my workspace outside of the US?</h5>
            <p>Yes, if you have internet access, you are able to access the PMP.</p>
          </div>

          <div class="faq">
            <h5>Why am I not able to use the AWS Marketplace as I do with my university AWS infrastructure?</h5>
            <p>The PMP is built using Hadoop and Jupyter framework as an easier transition to move to the cloud. Because of the design and security decisions, not all the offerings on AWS Marketplace are available within the workspace.</p>
          </div>

          <div class="faq">
            <h5>Can I use licensed products in conjunction with the PMP?</h5>
            <p>Yes, however, you must have an active license for the tool prior to loading it on the platform. File a ticket with Technical Support for assistance loading tools; when completing the form, include details of the type of software you want to upload onto your workspace, including your active license.
            </p>
          </div>

          <div class="faq">
            <h5>Is there anything I need to do to prepare myself and my work to go on to the cloud?</h5>
            <p>We recommend familiarizing yourself with R and Python.</p>
          </div>

          <div class="faq">
            <h5>I know nothing about Jupyter Notebooks.   Do you provide training on how to use the notebooks?</h5>
            <p>No, however, a tutorial for getting started with Jupyter in the workspace is available <a href="https://pmp.heart.org/jupyter_notebooks/about" target="_blank">here</a>, and all Jupyter Notebook Documentation can be found <a href="https://jupyter.org/documentation" target="_blank">here.</a></p>
          </div>

          <div class="faq">
            <h5>I normally use the Terminal, does the PMP have a Terminal mode?</h5>
            <p>Yes! You can access the Terminal through Jupyterlab or by clicking the New button on the top right corner of Jupyter and selecting Terminal on the drop down.            </p>
          </div>
        </div>

        
  
        <div class="section">
          <h2 #data>Data</h2>
          
          <div class="faq">
            <h5>What types of data are allowed on the PMP?</h5>
            <p>Only de-identified data is allowed on the PMP. Clinical data must be properly de-identified and no PII is allowed.</p>
          </div>

          <div class="faq">
            <h5>Are there limits to the amounts of data I can upload onto the workspace?</h5>
            <p>No, the only limitations will be with your internet connection and the security timeout of one hour.</p>
          </div>

          <div class="faq">
            <h5>How does the system know if the people I share my workspace with meet the appropriate access requirements for each dataset?</h5>
            <p>Currently the system does not provide this function.  It is up to the workspace owner to ensure that the people you are sharing your workspace with adhere to any Data Use Agreement terms that you have agreed to in order to gain access to a protected dataset. If your workspace has access to a Private/Protected dataset, when you click on the Submit button to share access to your workspace, a formal request will be sent to the data contributor to review and approve. Access to the workspace will only be provided upon approval.</p>
          </div>

          <div class="faq">
            <h5>I still don't understand what data harmonization is and why it is necessary, can you provide me with an example of this?</h5>
            <p>Yes! Data harmonization looks at how data is represented in a dataset to find commonly used variables and then reassigns those variables to a 'more common' label.  The goal of harmonization is to make more datasets interoperable and available for researchers.  An example of this is how a person's sex could be listed as Male or Female or M and F, however the 'more common' label for a person's sex is Male and Female.  If the dataset uses M and F as the labels, the data harmonization process would change these to Male and Female and then make note of the change.  By harmonizing the data within datasets, researchers can pull data from multiple datasets and be confident that the terms used to label variables and the variables themselves are consistent across datasets.</p>
          </div>
        </div>
  
        <div class="section">
          <h2 #general>General Questions</h2>

          <div class="faq">
            <h5>What exactly is the cloud?</h5>
            <p>The cloud is a network of remote servers hosted on the internet and used to store, manage, and process data in place of local servers and personal computers. This technology allows us to work on large, complex datasets in a much faster time span than was previously possible with conventional computing.</p>
          </div>

          <div class="faq">
            <h5>How is the AHA Precision Medicine Platform different from what my university provides me for my computational requirements?</h5>
            <p>The PMP does not rely on local servers managed by a university. The PMP uses Amazon Web Services (AWS) to provide its users an on-demand secure cloud services platform – a virtual cluster of computers – offering compute power, database storage, content delivery and other functionality to help institutions scale and grow their research exponentially faster than they might on their own. This provides users access to large-scale computing capacity quickly and inexpensively without the need to schedule or coordinate access as one might at an institution.</p>
          </div>

          <div class="faq">
            <h5>Why isn't the PMP on the list of approved repositories for the AHA Open Science Policy?</h5>
            <p>Not only do you need to register as a user of the PMP to gain access to data, some of the PMP datasets require approval for use/access, hence, the PMP does not meet the guidelines of an ‘Open Data’ repository where everyone has access to the data.</p>
          </div>
        </div>

        <div class="section">
          <h2 #credits>Credits</h2>

          <div class="faq">
            <h5>Can I keep my unspent credits?</h5>
            <p>If you were allotted computational cloud credits for a certain period for your grant or research project, you may be allowed to continue to use the PMP and credits after your project ends by request.  Please reach out to the AHA team at <a href="mailto:pmp@heart.org">pmp&#64;heart.org</a></p>
          </div>
        </div>

      </div>
    </div>
  </div>