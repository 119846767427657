<div class="container pricing-content-container">



  <div class="row pricing-main-block">
    <div class="col-12 col-lg-6 pricing-image">
      <img class="pricing-icon-1" src="assets/images/pricing/pricing1.svg" />
    </div>
    <div class="col-12 col-lg-6">
      <div class="pricing-title">What Can I Use the Precision Medicine Platform for?</div>
      <div class="pricing-content-block">

        <div class="pricing-line"></div>
        <div class="pricing-content">
          <div class="pricing-subtitle">Independent Research:</div>
          <p class="pricing-text">Anyone can utilize the Precision Medicine Platform for their own research needs.
            Pre-built
            tools, significant computing power and customizability make the tool a one-stop-shop for research across
            industries.</p>
        </div>



      </div>

      <div class="pricing-content-block">

        <div class="pricing-line"></div>
        <div class="pricing-content">
          <div class="pricing-subtitle">Grant-Funded Research:</div>
          <p class="pricing-text">The American Heart Association is the largest not-for-profit funding source for
            cardiovascular and cerebrovascular disease research next to the federal government. <a href="https://professional.heart.org/en/research-programs/aha-funding-opportunities" target="_blank"> New award offerings</a> and
            <a href="https://professional.heart.org/en/research-programs/awardee-policies" target="_blank" >resources</a> are available for preparing & submitting applications for funding.</p>
        </div>



      </div>

      <div class="pricing-content-block">

        <div class="pricing-line"></div>
        <div class="pricing-content">
          <div class="pricing-subtitle">Data Challenge Participation:</div>
          <p class="pricing-text">The American Heart Association hosts Data Challenges, where participants compete in
            research processes to further scientific research and improve patient outcomes.</p>
        </div>



      </div>

    </div>
  </div>


  <div class="row pricing-main-block">
    <div class="col-12 col-lg-6 pricing-image">
      <div class="pricing-title">Pricing Information</div>
      <div class="pricing-content-block">

        <div class="pricing-line"></div>
        <div class="pricing-content">
          <div class="pricing-subtitle">Apply for Subsidized and Free Cloud Credits:</div>
          <p class="pricing-text">Thanks to every single donor that contributes each year to the American Heart Association, we are able to fund important research and accelerate scientific discovery. Many researchers and trainees qualify for free and subsidized cloud computing credits.</p>
        </div>



      </div>

      <div class="pricing-content-block">

        <div class="pricing-line"></div>
        <div class="pricing-content">
          <div class="pricing-subtitle">Situational Payment Structures:</div>
          <p class="pricing-text">Research submissions relating to causes that overlap with the American Heart Association’s mission statement and goals may be eligible for situational payment structures, including reduced rates and subsidization.</p>
        </div>



      </div>


    </div>
    <div class="col-12 col-lg-6">
      <img class="pricing-icon-1" src="assets/images/pricing/pricing3.png" />
      
    </div>
  </div>




  <div class="row pricing-main-block">
    <div class="col-12 col-lg-6">

      <img class="pricing-icon-1" src="assets/images/pricing/pricing2.svg" />
      
    </div>
    <div class="col-12 col-lg-6">
      <div class="pricing-title">Only Pay for <span class="text-color"> What You Use</span></div>
      <div>With the Precision Medicine Platform, you only pay for the computing power that your research requires. Our pricing structure allows users and organizations to avoid major investments in hardware or software required for processing large datasets or complex algorithms.</div>
      <button type="submit" (click)="scrollToElement('contactForm')" class="btn btn-primary1 btn-solid-red">Contact Us For More Information</button>
      <a href="https://professional.heart.org/en/research-programs/aha-funding-opportunities" target="_blank" class="btn btn-primary1 btn-solid-red-2" >Submit Research Proposal</a>
      <div class="pricing-link">
        <a href="https://www.heart.org/en/professional/quality-improvement/quality-research-and-publications/national-level-program-data-research-opportunities" target="_blank"> National Level Program Data Research Opportunities </a>
      </div>
    </div>
  </div>

  <!-- <div class="row pricing-main-block">
    <div class="col-12 col-lg-6">
      <div class="pricing-title">Our Data Science Teams Are Available To You</div>
      <div>The American Heart Association Data Science Team is available to support your research goals. From quick coding needs to in-depth problem-solving. Think of it as “renting an expert.”</div>
    </div>
    <div class="col-12 col-lg-6">
      <img class="pricing-icon-1" src="assets/images/pricing/pricing3.svg" />
      
    </div>
  </div> -->
</div>