import { Component, Input } from '@angular/core';
import { DRCardComponent } from '../dr-card/dr-card.component';
@Component({
  selector: 'app-dr-sidebar',
  templateUrl: './dr-sidebar.component.html',
  styleUrls: ['./dr-sidebar.component.scss', '../data-resource.component.scss'],
  imports: [DRCardComponent],
  standalone: true
})
export class DrSidebarComponent {
  @Input() collectionForm = "";
  @Input() factSheet = "";
  @Input() python = "";
  @Input() r = "";
  @Input() sas = "";
  constructor() { }
}
