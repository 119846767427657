<div id="login-register-container" class="container" style="padding-top: 25px">
  <div class="row" id="loginRegisterDiv">
    <div
      class="col-sm-12 col-12 col-lg-8 offset-lg-2"
    >
      <div class="sign-in-header">
        <span>Multi-factor authentication</span>
      </div>
      <div class="step-alert" *ngIf="displayQR">
        <span class="title"
          >Install an authenticator app on your mobile device and
          <b> scan the QR code. </b> Alternatively, you can manually enter a
          secret key in your authenticator app.</span
        >
        <div [hidden]="!showSecret" class="pt-2"><b class="d-block" style="word-wrap: break-word;">
          {{SecretCode}}
        </b>  </div>

        <div class="action-keys">
          <button
            class="show-secret pr-3"
            [hidden]="showSecret"
            (click)="toggleSecret()"
          >
            Show secret key
          </button>
          <button
            class="show-secret pr-3"
            [hidden]="!showSecret"
            (click)="toggleSecret()"
          >
            Hide secret key
          </button>
          <button class="pl-3" (click)="navigateHelp()">Help</button>
        </div>

        <!-- <div class="d-flex my-4 justify-content-center align-items-center">
          <div class="border-1 mr-3"></div>
          <span class="">OR</span>
          <div class="border-1 ml-3"></div>
        </div> -->
        <!-- <button (click)="toggleAuthenticate(codeInputs,'SOFTWARE_TOKEN_MFA')" [hidden]="showAuthenticator">Code from Authenticator App</button>
        <button (click)="toggleAuthenticate(emailInputs,'EMAIL_OTP')" [hidden]="!showAuthenticator">Get verification code on Email.</button> -->
      </div>
      <div class="step-alert" [hidden]="showAuthenticator">
        <span class="title"
          >A authentication code code has been sent to {{maskEmail(email)}}</span
        >
        <div class="d-flex my-4 justify-content-center align-items-center">
          <div class="border-1 mr-3"></div>
          <span class="">OR</span>
          <div class="border-1 ml-3"></div>
        </div>
        <button (click)="toggleAuthenticate(codeInputs,'SOFTWARE_TOKEN_MFA')" [hidden]="showAuthenticator">Code from Authenticator App</button>
        <button (click)="toggleAuthenticate(emailInputs,'EMAIL_OTP')" [hidden]="!showAuthenticator">Get verification code on Email.</button>
      </div>
      <div *ngIf="displayQR && !enableQR">
      <div class="d-flex justify-content-center align-items-center m-3 image-container">
        <img style="height:270px;width:420px"  src="../../../../assets/images/qr_dummy_code.png" alt="Dummy QR Code"/>
          <button  (click)="revealQRCode()" class="btn overlay-button">Reveal QR Code</button>
      </div>
      </div>
      <div *ngIf="displayQR && enableQR" class="d-flex justify-content-center align-items-center m-3" >
        <img style="height:270px;width:270px"  [src]="qrCode" alt="QR Code" />
      </div> 

      <div class="authenticator-container" [ngStyle]="{ 'opacity': (displayQR && !enableQR && !showSecret) ? 0.5 : 1,'pointer-events': (displayQR && !enableQR && !showSecret) ? 'none' : 'auto' }" [hidden]="!showAuthenticator">
        <p>Enter 6-digit code from your authenticator app</p>
        <div class="code-inputs" #codeInputs>
          <input
            type="number"
            maxlength="1"
            class="code-input"
            (input)="moveToNext($event, 0)"
            (keydown)="moveToNext($event, 0)"
          />
          <input
            type="number"
            maxlength="1"
            class="code-input"
            (input)="moveToNext($event, 1)"
            (keydown)="moveToNext($event, 1)"
          />
          <input
            type="number"
            maxlength="1"
            class="code-input"
            (input)="moveToNext($event, 2)"
            (keydown)="moveToNext($event, 2)"
          />
          <input
            type="number"
            maxlength="1"
            class="code-input"
            (input)="moveToNext($event, 3)"
            (keydown)="moveToNext($event, 3)"
          />
          <input
            type="number"
            maxlength="1"
            class="code-input"
            (input)="moveToNext($event, 4)"
            (keydown)="moveToNext($event, 4)"
          />
          <input
            type="number"
            maxlength="1"
            class="code-input"
            (input)="moveToNext($event, 5)"
            (keydown)="moveToNext($event, 5)"
          />
        </div>
       
        <div class="required">
          {{errorMessage}}
        </div>
        <div class="">
          {{authCode}}
        </div>
      </div>
      <div class="authenticator-container" [hidden]="showAuthenticator">
        <p>Enter 6-digit code from your email</p>
        <div class="code-inputs" #emailInputs>
          <input
            type="number"
            maxlength="1"
            class="code-input"
            (input)="moveToNext($event, 0)"
            (keydown)="moveToNext($event, 0)"
          />
          <input
            type="number"
            maxlength="1"
            class="code-input"
            (input)="moveToNext($event, 1)"
            (keydown)="moveToNext($event, 1)"
          />
          <input
            type="number"
            maxlength="1"
            class="code-input"
            (input)="moveToNext($event, 2)"
            (keydown)="moveToNext($event, 2)"
          />
          <input
            type="number"
            maxlength="1"
            class="code-input"
            (input)="moveToNext($event, 3)"
            (keydown)="moveToNext($event, 3)"
          />
          <input
            type="number"
            maxlength="1"
            class="code-input"
            (input)="moveToNext($event, 4)"
            (keydown)="moveToNext($event, 4)"
          />
          <input
            type="number"
            maxlength="1"
            class="code-input"
            (input)="moveToNext($event, 5)"
            (keydown)="moveToNext($event, 5)"
          />
        </div>
        <div class="required">
          {{errorMessage}}
        </div>
        <div class="">
          {{authCode}}
        </div>
      </div>
      <div [ngStyle]="{ 'opacity': ( displayQR && !enableQR && !showSecret ) ? 0.5 : 1,'pointer-events': (displayQR && !enableQR && !showSecret) ? 'none' : 'auto' }" class="d-flex justify-content-center align-items-center my-3">
        <button (click)="cancelMFA()" class="btn cancel" >Cancel</button>
        <button (click)="combineValues(codeInputs)" [hidden]="!showAuthenticator" class="btn complete">Complete</button>
        <button (click)="combineValues(emailInputs)" [hidden]="showAuthenticator" class="btn complete">Complete</button>
      </div>
    </div>
  </div>

</div>
