<div *ngIf="open" class="alert-modal-banner">
    <div class="alert alert-modal-message fade show">
        <div>
            <p style="text-align: center" [innerHTML]="message"></p>
            <div style="text-align: center">
                <button *ngIf="acceptText" class="btn btn-primary primary-btn" style="margin-left:15px;" (click)="onModalClose(true)"
                    type="button">{{acceptText}}</button>
                <button class="btn btn-primary primary-btn" style="margin-left:15px;" (click)="onModalClose(false);$event.stopPropagation()"
                    type="button">{{closeText}}</button>

            </div>
        </div>
    </div>
</div>