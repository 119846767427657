import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataResourceStateService } from './data-resource-state.service';
import { DRCardComponent } from './dr-card/dr-card.component';
import { HelpDialogComponent } from './help-dialog/help-dialog.component';
import { SharedModule } from '../../../shared/shared.module';
interface Section {
  ref: any;
  name: string;
}

@Component({
  selector: 'app-data-resource',
  templateUrl: './data-resource.component.html',
  styleUrls: ['./data-resource.component.scss'],
  providers: [DRCardComponent],
  imports: [SharedModule],
  standalone: true
})
export class DataResourceComponent {
  @Input() statisticData = [];
  @Input() pageTitle = "";
  @Input() url = "";
  @Input() logoUrl = "";
  @Input() sections: Section[] = [];
  @Input() showLink = false;
  

  
  constructor(public dialog: MatDialog, public dataResourceState: DataResourceStateService) {}

  openDialog(): void {
    this.dialog.open(HelpDialogComponent);
  }
  get collapseOpen() {
    return this.dataResourceState.collapseOpen;
  }
  toggleCollapse() {
    this.dataResourceState.collapseOpen = !this.dataResourceState.collapseOpen;
    window.dispatchEvent(new Event('resize'));
  }
  getCollapseLabel() {
    return this.collapseOpen ? "Collapse right pane" : "Open right pane"
  }
  getCollapseIcon() {
    return this.collapseOpen ? "last_page" : "menu"
  }
  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
  linkto(event: MouseEvent){
    event.preventDefault;
    window.open('https://gwtg-cad-sdoh-dashboard.heart.org', '_blank');
  }
}

