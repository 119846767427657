import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../../app/shared/services/auth.service';
import { AuthssoService } from '../../../../app/shared/services/authsso.service';
@Component({
  selector: 'app-pmp-banner-home',
  templateUrl: './pmp-banner.component.html',
  styleUrls: ['./pmp-banner.component.scss']
})
export class PmpBannerHomeComponent {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() buttonText: string;
  @Input() titleId: string;
  @Input() subtitleId: string;
  @Input() location: string;
  @Input() buttonRoute: string;
  constructor(private authsso:AuthssoService,public route: Router, private authenticationService: AuthenticationService) { }


  public isLoggedIn(){
    return this.authsso.currentUserValue ? true : false;
  }

  scrollToElement(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  bannerButtonClick(){
    this.signIn();
  }

  public signIn(){
    //this.auth.login().subscribe();
    if(!this.buttonRoute){
      this.buttonRoute = "workspace";
    }
    if(!this.isLoggedIn()){
      window.location.href = this.authenticationService.getSSOUrl() + "/oauth/authorize?client_id=acme&redirect_uri=" + window.location.origin + "/loggedin&response_type=code&state=g6hju7";
    }else{
      this.route.navigate(['/' + this.buttonRoute + '/loggedin'], { queryParams: { loggedIn: true } } );
    }
  }

}
