export var participantCounts = {
	'harvest_date': 'February 2024',
	'overall': 20087,
	'reproductive_health': 5378,
	'rgr_survey': 6778,
	'hce_perceptions': 4270,
}

export var haStrokeData = {
	'heart_attack': 5.8,
	'stroke': 2.8
}

export var top10MedData = [ 
	{
		"name":"High Blood Pressure",
		"value":23.3,
		"extra": {
			"count":3596,
		}
	},
	{
		"name":"Anxiety Disorder",
		"value":10.8,
		"extra": {
			"count":1667,
		}
	},
	{
		"name":"Depression",
		"value":10.7,
		"extra": {
			"count":1654,
		}
	},
	{
		"name":"High Cholesterol",
		"value":10.5,
		"extra": {
			"count":1624,
		}
	},
	{
		"name":"Asthma",
		"value":10.2,
		"extra": {
			"count":1573,
		}
	},
	{
		"name":"Migraine",
		"value":8.8,
		"extra": {
			"count":1357,
		}
	},
	{
		"name":"Hypothyroidism",
		"value":8.3,
		"extra": {
			"count":1280,
		}
	},
	{
		"name":"Obesity",
		"value":7.2,
		"extra": {
			"count":1110,
		}
	},
	{
		"name":"Diabetes",
		"value":5.3,
		"extra": {
			"count":819,
		}
	},
	{
		"name":"Arthritis",
		"value":5.0,
		"extra": {
			"count":767,
		}
	},
]

export var ageData = [ 
	{
		"name":"18-29",
		"value":11.6,
		"extra": {
			"count":2323,
		}
	},
	{
		"name":"30-39",
		"value":21.3,
		"extra": {
			"count":4271,
		}
	},
	{
		"name":"40-49",
		"value":26.8,
		"extra": {
			"count":5388,
		}
	},
	{
		"name":"50-59",
		"value":25.0,
		"extra": {
			"count":5031,
		}
	},
	{
		"name":"60-69",
		"value":12.2,
		"extra": {
			"count":2451,
		}
	},
	{
		"name":"70-79",
		"value":2.9,
		"extra": {
			"count":583,
		}
	},
	{
		"name":"80+",
		"value":0.2,
		"extra": {
			"count":40,
		}
	},
]

export var raceEthData = [ 
	{
		"name":"Non-Hispanic White",
		"value":68.9,
		"extra": {
			"count":13830,
		}
	},
	{
		"name":"Non-Hispanic Black",
		"value":14.0,
		"extra": {
			"count":2814,
		}
	},
	{
		"name":"Hispanic",
		"value":9.4,
		"extra": {
			"count":1898,
		}
	},
	{
		"name":"Mixed",
		"value":3.2,
		"extra": {
			"count":638,
		}
	},
	{
		"name":"Asian",
		"value":3.2,
		"extra": {
			"count":634,
		}
	},
	{
		"name":"Other",
		"value":0.7,
		"extra": {
			"count":149,
		}
	},
	{
		"name":"American Indian or Alaskan Native",
		"value":0.5,
		"extra": {
			"count":95,
		}
	},
	{
		"name":"Native Hawaiian or Pacific Islander",
		"value":0.1,
		"extra": {
			"count":29,
		}
	},
]

export var geoDistData = [ 
	[{v: "US-TX", f: ""}, 1388, "<b>Texas</b> <br>1388 Participants"],
	[{v: "US-OH", f: ""}, 1363, "<b>Ohio</b> <br>1363 Participants"],
	[{v: "US-CA", f: ""}, 1300, "<b>California</b> <br>1300 Participants"],
	[{v: "US-MA", f: ""}, 1299, "<b>Massachusetts</b> <br>1299 Participants"],
	[{v: "US-FL", f: ""}, 942, "<b>Florida </b> <br>942 Participants"],
	[{v: "US-NY", f: ""}, 924, "<b>New York</b> <br>924 Participants"],
	[{v: "US-NC", f: ""}, 779, "<b>North Carolina</b> <br>779 Participants"],
	[{v: "US-IL", f: ""}, 724, "<b>Illinois </b> <br>724 Participants"],
	[{v: "US-PA", f: ""}, 713, "<b>Pennsylvania </b> <br>713 Participants"],
	[{v: "US-MD", f: ""}, 696, "<b>Maryland</b> <br>696 Participants"],
	[{v: "US-MI", f: ""}, 654, "<b>Michigan </b> <br>654 Participants"],
	[{v: "US-MO", f: ""}, 577, "<b>Missouri </b> <br>577 Participants"],
	[{v: "US-VA", f: ""}, 560, "<b>Virginia</b> <br>560 Participants"],
	[{v: "US-WA", f: ""}, 524, "<b>Washington</b> <br>524 Participants"],
	[{v: "US-GA", f: ""}, 509, "<b>Georgia</b> <br>509 Participants"],
	[{v: "US-IN", f: ""}, 484, "<b>Indiana</b> <br>484 Participants"],
	[{v: "US-NJ", f: ""}, 470, "<b>New Jersey</b> <br>470 Participants"],
	[{v: "US-TN", f: ""}, 469, "<b>Tennessee</b> <br>469 Participants"],
	[{v: "US-MN", f: ""}, 417, "<b>Minnesota</b> <br>417 Participants"],
	[{v: "US-WI", f: ""}, 417, "<b>Wisconsin</b> <br>417 Participants"],
	[{v: "US-KY", f: ""}, 370, "<b>Kentucky</b> <br>370 Participants"],
	[{v: "US-SC", f: ""}, 357, "<b>South Carolina</b> <br>357 Participants"],
	[{v: "US-AL", f: ""}, 322, "<b>Alabama</b> <br>322 Participants"],
	[{v: "US-AZ", f: ""}, 307, "<b>Arizona</b> <br>307 Participants"],
	[{v: "US-CO", f: ""}, 273, "<b>Colorado</b> <br>273 Participants"],
	[{v: "US-LA", f: ""}, 268, "<b>Louisiana</b> <br>268 Participants"],
	[{v: "US-AR", f: ""}, 250, "<b>Arkansas</b> <br>250 Participants"],
	[{v: "US-OR", f: ""}, 242, "<b>Oregon</b> <br>242 Participants"],
	[{v: "US-IA", f: ""}, 232, "<b>Iowa</b> <br>232 Participants"],
	[{v: "US-OK", f: ""}, 224, "<b>Oklahoma</b> <br>224 Participants"],
	[{v: "US-KS", f: ""}, 216, "<b>Kansas</b> <br>216 Participants"],
	[{v: "US-CT", f: ""}, 183, "<b>Connecticut</b> <br>183 Participants"],
	[{v: "US-NE", f: ""}, 178, "<b>Nebraska</b> <br>178 Participants"],
	[{v: "US-UT", f: ""}, 170, "<b>Utah</b> <br>170 Participants"],
	[{v: "US-MS", f: ""}, 157, "<b>Mississippi</b> <br>157 Participants"],
	[{v: "US-NV", f: ""}, 116, "<b>Nevada</b> <br>116 Participants"],
	[{v: "US-ME", f: ""}, 104, "<b>Maine</b> <br>104 Participants"],
	[{v: "US-NM", f: ""}, 102, "<b>New Mexico</b> <br>102 Participants"],
	[{v: "US-NH", f: ""}, 101, "<b>New Hampshire</b> <br>101 Participants"],
	[{v: "US-WV", f: ""}, 96, "<b>West Virginia</b> <br>96 Participants"],
	[{v: "US-ID", f: ""}, 89, "<b>Idaho</b> <br>89 Participants"],
	[{v: "US-RI", f: ""}, 64, "<b>Rhode Island</b> <br>64 Participants"],
	[{v: "US-SD", f: ""}, 63, "<b>South Dakota</b> <br>63 Participants"],
	[{v: "US-VT", f: ""}, 58, "<b>Vermont </b> <br>58 Participants"],
	[{v: "US-DE", f: ""}, 54, "<b>Delaware </b> <br>54 Participants"],
	[{v: "US-HI", f: ""}, 53, "<b>Hawaii</b> <br>53 Participants"],
	[{v: "US-MT", f: ""}, 50, "<b>Montana </b> <br>50 Participants"],
	[{v: "US-DC", f: ""}, 48, "<b>District of Columba</b> <br>48 Participants"],
	[{v: "US-WY", f: ""}, 44, "<b>Wyoming</b> <br>44 Participants"],
	[{v: "US-AK", f: ""}, 42, "<b>Alaska</b> <br>42 Participants"],
	[{v: "US-ND", f: ""}, 42, "<b>North Dakota</b> <br>42 Participants"],
]

