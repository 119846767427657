<div class="container-fluid contact-form-container"  id="contactForm" [ngClass]="{
  'theme-light': theme === 'light',
  'theme-dark': theme === 'dark'
}">
  <h2 [style.font-size.px]="fontSize" [style.color]="color">{{title}}</h2>
  <p *ngIf="subtitle" [innerHtml]="sanitizedHtmlContent"></p>
  <div *ngIf="message" id="message" class="alert contact-alert-info fade show alert-dismissable">
    <a href="#" class="close" data-dismiss="alert" aria-label="close" title="close">x</a>
    {{message}}
</div>
<div *ngIf="errorMessage" id="errorMessage" class="alert alert-danger fade show">
    <a href="#" class="close" data-dismiss="alert" aria-label="close" title="close">x</a>
    {{errorMessage}}
</div>
  <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-12 col-sm-6">
        <label for="firstName">First Name *</label>
        <input type="text" id="firstName" formControlName="firstName" class="form-control" />
        <div 
        *ngIf="contactForm.get('firstName').invalid && (contactForm.get('firstName').dirty || contactForm.get('firstName').touched)">
          <small *ngIf="contactForm.get('firstName').errors.required" class="text-danger">Field is required</small>
          <small *ngIf="contactForm.get('firstName')?.hasError('pattern')" class="text-danger">Special characters are not allowed</small>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="lastName">Last Name *</label>
        <input type="text" id="lastName" formControlName="lastName" class="form-control" />

        <div
          *ngIf="contactForm.get('lastName').invalid && (contactForm.get('lastName').dirty || contactForm.get('lastName').touched)">
          <small *ngIf="contactForm.get('lastName').errors.required" class="text-danger">Field is required</small>
          <small *ngIf="contactForm.get('lastName')?.hasError('pattern')" class="text-danger">Special characters are not allowed</small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label for="email">Email *</label>
        <input type="email" id="email" formControlName="email" class="form-control" />
        <div
          *ngIf="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched)">
          <small class="text-danger" *ngIf="contactForm.get('email').errors.required">Field is required</small>
          <small class="text-danger" *ngIf="contactForm.get('email').errors.email">Please Enter a Valid Email Address</small>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="confirmEmail">Confirm Email *</label>
        <input type="email" id="confirmEmail" formControlName="confirmEmail" class="form-control" />
        <div
          *ngIf="contactForm.get('confirmEmail').invalid && (contactForm.get('confirmEmail').dirty || contactForm.get('confirmEmail').touched)">
          <small class="text-danger" *ngIf="contactForm.get('confirmEmail').errors.required">Field is required</small>
          <small class="text-danger" *ngIf="contactForm.get('confirmEmail').errors.email">Please Enter a Valid Email Address</small>
          <small class="text-danger" *ngIf="contactForm.get('confirmEmail').errors.mustMatch">Emails must match</small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label for="phone">Phone (Optional)</label>
        <input type="text" id="phone" formControlName="phone" class="form-control" />
        <div
        *ngIf="contactForm.get('phone').invalid && (contactForm.get('phone').dirty || contactForm.get('phone').touched)">
        <small *ngIf="contactForm.get('phone')?.hasError('pattern')" class="text-danger">Special characters `<` and `>` are not allowed</small>
      </div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="country">Country (Optional)</label>
        <input type="text" id="country" formControlName="country" class="form-control" />
        <div
        *ngIf="contactForm.get('country').invalid && (contactForm.get('country').dirty || contactForm.get('country').touched)">
        <small *ngIf="contactForm.get('country')?.hasError('pattern')" class="text-danger">Special characters are not allowed</small>
      </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label for="organization">Organization *</label>
        <input type="text" id="organization" formControlName="organization" class="form-control" />
        <div
          *ngIf="contactForm.get('organization').invalid && (contactForm.get('organization').dirty || contactForm.get('organization').touched)">
          <small class="text-danger" *ngIf="contactForm.get('organization').errors.required">Field is required</small>
          <small class="text-danger" *ngIf="contactForm.get('organization')?.hasError('pattern')">Special characters  are not allowed</small>  
         </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label for="comment">Question? *</label>
        <textarea id="comment" formControlName="comment" class="form-control"></textarea>
        <div
          *ngIf="contactForm.get('comment').invalid && (contactForm.get('comment').dirty || contactForm.get('comment').touched)">
          <small class="text-danger" *ngIf="contactForm.get('comment').errors.required">Field is required</small>
          <small class="text-danger" *ngIf="contactForm.get('comment')?.hasError('pattern')">Special characters are not allowed</small> 
         </div>
      </div>
    </div>

    <div class="row" *ngIf="type === 'technicalSupport'">
      <div class="col-12">
        <label for="comment">Attachment</label>
        <!-- <input formControlName="fileName" [(ngModel)]="fileName" class="form-control"  name="fileName" id="fileName" type="text" placeholder="Attachment (Optional), Max size 5 MB" readonly="true"/> -->
        <div class="input-group">
          <input 
            formControlName="fileName" 
            class="form-control" 
            name="fileName" 
            id="fileName" 
            type="text" 
            value="{{fileName}}"
            placeholder="Attachment (Optional), Max size 5 MB" 
            readonly="true"
          />
          
          <!-- Show the "X" icon when a file is uploaded -->
          <div *ngIf="fileName" class="input-group-append">
            <button type="button" (click)="clearFile()" class="close-btn">
              <span>&times;</span>
            </button>
          </div>
        </div>
   
        <input type="file" id="fileToUpload"  #fileInput placeholder="Upload file..."  (change)="changeListener($event)"  style="display:none"/>
        <button id="chooseFileBtn" type="button" onclick="document.getElementById('fileToUpload').click();">Choose file</button>
        <div *ngIf="fileValiadtion" class="mt-3"
        >
        <small class="text-danger">{{fileValiadtion}}</small>
      </div>
      </div>

    </div>

    <div class="row">
      <div class="col-12">
        <button type="submit" class="btn btn-primary1 btn-solid-red" [disabled]="contactForm.invalid || fileValiadtion">SUBMIT</button>
      </div>
    </div>
  </form>
</div>