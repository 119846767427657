import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthssoService } from '../../../../app/shared/services/authsso.service';
import { InactivityTimerService } from '../../../../app/shared/services/inactivity-timer-service.service';
import { SharedModule } from '../../../../app/shared/shared.module';
@Component({
  selector: 'app-logged-in',
  templateUrl: './loggedin.component.html',
  styleUrls: ['./loggedin.component.scss'],
  imports: [SharedModule],
  standalone: true
})
export class LoggedInComponent implements OnInit {

  showError = false;

  constructor(private inactiveService:InactivityTimerService,private auth: AuthenticationService, public router: Router, private route: ActivatedRoute,private authsso:AuthssoService) { }

  ngOnInit() {

    const authData = this.authsso.getData()
    this.auth.getToken(authData).subscribe({
      next: (user) => {
       // console.log('User token stored:', user);
        const data = this.authsso.userDetailsData; 
        const userInfo = localStorage.getItem('userInfo')
        if(data || (userInfo != null)) {
          this.inactiveService.startInactivityTimer()
          if (data && (!data.family_name || !data.given_name || !data['custom:title']
            || !data['custom:researchArea'] || !data['custom:role'] || !data['custom:degree'
            ])) {
            this.router.navigate(['/profile/user'], { queryParams: { update: true } });
          } else {
            let redirectRoute = '/workspace/launch-workspace';
            if(JSON.parse(localStorage.getItem("path"))){
              redirectRoute = JSON.parse(localStorage.getItem("path"));
            }
            this.router.navigate([redirectRoute]);
          }
        }
      },
      error: (err) => {
        this.showError = false;
        console.error('Error storing token:', err);
      }
    });

  }
}
