import { Component } from '@angular/core';

@Component({
  selector: 'app-pricing-content',
  templateUrl: './pricing-content.component.html',
  styleUrls: ['./pricing-content.component.scss']
})
export class PricingContentComponent {

  constructor() { }

  scrollToElement(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

}
