import { Component } from '@angular/core';

@Component({
  selector: 'app-gwtg-biovu',
  templateUrl: './gwtg-biovu.component.html',
  styleUrls: ['./gwtg-biovu.component.scss']
})
export class GwtgBiovuComponent {

  constructor() { }

}
