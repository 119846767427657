<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<link rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
<br><br>
<div class="container-lg">
  <div class="row noGutters">
    <div class="col-sm-5 overflow-hidden">
      <h1>
        <img class="data-resource-img" [src]="logoUrl"
          alt="American Heart Association Get With the Guidelines {{pageTitle}}">
      </h1>
    </div>
    <div class="col-sm-4">
    </div>
    <div class="col-sm-3 options-bar">
      <a mat-icon-button class="icon-button-large" [href]="url" aria-label="Info"
        matTooltip="Info about this registry (link)" matTooltipPosition="above">
        <mat-icon>info_outline</mat-icon>
      </a>
      <button mat-icon-button class="icon-button-large" (click)="openDialog()" aria-label="Help" matTooltip="Help"
        matTooltipPosition="above">
        <mat-icon>help_outline</mat-icon>
      </button>
      <button mat-icon-button class="icon-button-large" (click)="toggleCollapse()" [attr.aria-expanded]="collapseOpen" aria-label="Toggle Collapse"
        matTooltip="Toggle Collapse" matTooltipPosition="above" aria-controls="id_dr_sidebar">
        <mat-icon>{{getCollapseIcon()}}</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="showLink" class="social">linked with Social Determinants of Health</div>
  <div class="row">
    <!-- Title Card -->

    <div class="snapshot-title"
      [ngClass]="{'col-lg-8': collapseOpen, 'col-md-8': collapseOpen, 'col-sm-12': !collapseOpen }">

      <div class="statistic-container">
        <div *ngFor="let item of statisticData">
          <h2 class="statistic-label">{{item.name}}</h2>
          <p class="statistic">{{item.value}}</p>
        </div>
      </div>
      <button *ngIf="showLink" class="link-button btn btn-link-style d-flex justify-content-start align-items-center"
      (click)="linkto($event)">View GWTG-CAD + SDOH Dashboard
        <img src="../../../../../assets/icon/New-Window.svg" alt="no icon" class="ml-2"/>
       </button>
     
      <div class="nav-bar">
        <button *ngFor="let item of sections" class="nav-btn" (click)="scrollToElement(item.ref)">{{item.name}}</button>
      </div>
      <ng-content>
      </ng-content>
    </div>
    <div class="snapshot-title"
      id="id_dr_sidebar"
      [ngClass]="{'col-lg-4': collapseOpen, 'col-md-4': collapseOpen, 'd-none': !collapseOpen }">
      <ng-content select="[sidebar]"></ng-content>


    </div>
  </div>
  <footer>
    Please note: The information on this page is not approved for use in public presentations or unapproved research
    publications.
    Any unauthorized use will require immediate corrective action.
    Please visit <a href="https://www.heart.org/qualityresearch">www.heart.org/qualityresearch</a> for more information.
  </footer>

</div>