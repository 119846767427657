import { Component } from '@angular/core';
import { AuthenticationService } from '../../services/auth.service';
@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.scss']
})
export class ContactSectionComponent {

  constructor(public authService: AuthenticationService) { }


}
