import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { BehaviorSubject, Subject, Observable, Subscriber } from 'rxjs';
import { humanFileSize } from '../utils/utility';

export interface UploadMap {
  mapKey: string | number;
  uniqueId: string | number;
}

@Injectable()
export class AwsUploadService {

  uploaderMap = new Map();
  private $subject: Subject<any> = new Subject();

  constructor() { }

  private addProgressItem(uploadMap: UploadMap, progressItem) {
    const currentState = this.uploaderMap.get(uploadMap.mapKey) || [];

    this.uploaderMap.set(uploadMap.mapKey, [progressItem, ...currentState]);
  }

  public removeProgressItem(uploadMap: UploadMap) {
    const currentState = this.uploaderMap.get(uploadMap.mapKey) || [];
    const filterItems: any[] = currentState.filter(item => item.uniqueId !== uploadMap.uniqueId);

    if (filterItems.length > 0) {
      this.uploaderMap.set(uploadMap.mapKey, filterItems);
    } else {
      this.uploaderMap.delete(uploadMap.mapKey);
    }
  }

  public getProgressItems(mapKey: string | number) {
    const currentState = this.uploaderMap.get(mapKey) || [];
    return currentState;
  }

  public hasProgressItems() {
    let hasItems = false;
    this.uploaderMap.forEach((value) => (hasItems = (value && value.length > 0)));
    return hasItems;
  }

  public getProgressStream() {
    return this.$subject.asObservable();
  }

  private setProgressStream(newItem?: any) {
    this.$subject.next(newItem);
  }


  uploadToS3(s3Config, params, options, uploadMap: UploadMap, fileDetails: any) : Observable<any> {
    console.log("uploadToS3 method started");

    return new Observable((observer: Subscriber<any>) => {
      console.log("Observable created");

        const s3 = new AWS.S3(s3Config);

        const $progress = new BehaviorSubject({
          progress: 0,
          loading: true,
          uploadSize: '0 / 0',
          timestamp: ''
        });

        const uploader = s3.upload(params, options);

        const progressItem = {
          ...fileDetails,
          uniqueId: uploadMap.uniqueId,
          upload: $progress.asObservable(),
          abort: uploader.abort.bind(uploader)
        };

        this.addProgressItem(uploadMap, progressItem);
        this.setProgressStream(progressItem);

        return uploader.on('httpUploadProgress', (evt) => {
          const progressPercent = (evt.loaded / evt.total);
          $progress.next({
            progress: Math.round(progressPercent * 100),
            uploadSize: `${humanFileSize(evt.loaded)} / ${humanFileSize(evt.total)}`,
            loading: true,
            timestamp: ''
          });
        }).send((error, data) => {
          
          let now = new Date();
          let now_utc = new Date(now.toUTCString().slice(0, -4));
          $progress.next({
            progress: 100,
            loading: false,
            timestamp: '' + now_utc,
            uploadSize: '0 / 0'
          });
          this.removeProgressItem(uploadMap);
          this.setProgressStream();

          if (error) {
            observer.error(error);
        } else {
            observer.next(data);
            observer.complete();
        }
        });
      })
    };
}
