<app-title-subtitle [title]="'User Guide'"
  ></app-title-subtitle>


  <div class="root">
    <div class="wrapper">
      <app-content-navigation [sections]="[
      {'ref': PlatformDesign, 'name': 'Platform Design'},
      {'ref': TransferFilesInAndOutOfThePmp, 'name': 'Transfer Files In and Out of the PMP'},
      {'ref': AccessDataInThePmp, 'name': 'Access Data in the PMP'},
      {'ref': TheWorkspacePortalPage, 'name': 'The Workspace Portal Page'},
      {'ref': TheWorkspaceCloudEnvironment, 'name': 'The Workspace Cloud Environment'}
    ]"></app-content-navigation>
      <div class="user-guide-container documentation">
  
        <div class="section">
          <h2 #PlatformDesign>Platform Design</h2>
          <p>
            The Precision Medicine Platform consists of two components, the <a
              routerLink="/workspace">portal</a> and the
            cloud-based virtual environment that is used for computing.
          </p>
          <p>
            The portal acts as the interface between the user and the workspace virtual environment and allows users to control workspace settings. The workspace environment is a cloud-based compute environment for analytics powered by Amazon Web Services.
          </p>
          <div class="col">
            
            
            <div class=""><img class="guide-img" src="/assets/images/juputer_home_page.png" /></div>
            <div class=""><img class="guide-img" src="/assets/images/workspace_page.png" /></div>
          </div>
          
          
        </div>
  
        <div class="section">
          <h2 #TransferFilesInAndOutOfThePmp>Transfer Files In and Out of the PMP</h2>
          <em>Please file a ticket with <a routerLink="/technical-support">Technical
              Support</a> if at any
            point you are having trouble uploading or exporting files.</em>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Upload Files to the PMP
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                <h5>
                  File Upload
                </h5>
                <p>Files can be uploaded into the workspace via the <a routerLink="/workspace">workspace </a> page by clicking the Files tab and then the red Upload link.
                </p>
                <p>Do not upload any data that contains PII or PHI information per the <a
                    routerLink="/tos" >Terms of Service</a> that you agreed to as a
                  user
                  of the Precision Medicine Platform.</p>
                <p>Users have the option to upload a single file or multiple files at once.</p>
                <p>Once the files are uploaded and you have launched your workspace, the files will be found in the folder labeled <code>files/uploaded_files/</code> on the Jupyter
                  home page (path: <code>/home/sagemaker-user/files/uploaded_files</code>).</p>

                <p><b>Note:</b> The time it takes to upload data is dependent on the internet upload speed</p>

                  <p>It is our recommendation that you <b>Cut</b> the files that you upload to the uploaded_files folder and <b>Paste</b> them into a separate folder within <code>/home/sagemaker-user/files/workspace_files/</code> prior to unzipping or working on them. The uploaded_files folder is meant to be a temporary space to hold the files you need to get into your Workspace. From there, it is best practice to create separate folders and cut/paste your files into these new folders and to work out of those folders.</p>
  
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Get Files out of the PMP
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                <p>
                  Data and Code within a workspace can be synced with the <a routerLink="/workspace"
                    >workspace portal</a> by moving
                  it to the <code>/home/sagemaker-user/files/export_files</code> directory within the workspace. Files located in this directory will display under the Export Files section under the Files tab in the workspace portal. File types that can be exported are: .pdf, .html, .rmd, .r, .ipynb, .jpg, .jpeg, .gif, and .png.
                  <br>
                  <em><span class="note"><b>Note:</b></span> Due to the secure nature of the workspace, certain files will not sync with the workspace portal. To extract any data files that you uploaded to the platform, please file a ticket with
                    <a routerLink="/technical-support" >Technical Support</a>.</em> You are not allowed to extract any protected datasets you have permissions to. 
                </p>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="section">
          <h2 #AccessDataInThePmp>Access Data in the PMP</h2>
          <p><em>Please file a ticket with <a routerLink="/technical-support">Technical Support</a>
              if at any point you are having trouble accessing data.</em></p>
          <ul>
            <li>Datasets requested through the Data page are accessible to the users directly in their workspace application. Upon
              approval of a dataset, the user will be able to use the runbook located in their workspace under the path (/files/runbooks) which contains example code on how to query and load the datasets into a data frame/table. The user will then be able to use this data frame for other analyses/tasks.
              <ul>
                <li>If you are a SAS user, you will need to access the data using the runbook in either Jupyter or R.  You may need to request Jupyter or R as an additional application for your workspace if you don't have one of them already. Please use the runbook to save the dataset as a CSV to your workspace. You can then import the CSV into SAS for your analysis.  Please refer to the instructions linked <a href="/assets/downloads/user-guide/Runbook CSV Documentation.pdf" download target="_blank">here</a>.</li>
              </ul>
            </li>
            <li>Publicly available data can be accessed in a variety of ways in a PMP workspace including
              downloading data directly and pulling/cloning data from repositories. Steps for accessing data
              from download links and repositories are described below.
            </li>
          </ul>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Downloading Data Directly
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                <p>Data can be downloaded in the workspace in the same fashion as your personal computer. Add a
                  new tab in Chrome, navigate to the download link, and click download.</p>
                <p><img src="assets/images/documentation/download_data_PMP.png" alt="screenshot of data tab"
                    style="width:90%;"></p>
                <ul>
                  <li>This will download the data in a temporary downloads folder. Simply click <em>Upload</em>
                    in Jupyter or Jupyter Lab to upload the downloaded files and save them to the workspace.
                  </li>
                </ul>
                <p><em><span class="note"><b>Note:</b> </span> If you are having trouble accessing a certain link in
                    the platform, please file a ticket with
                    <a routerLink="/technical-support" >Technical Support</a>.</em>
                </p>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Pulling or Cloning Data from a Repository
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                <p>Using git repositories in the workspace functions the same way as it does on a personal computer.
                  To pull data or clone a repository, simply go to the repository in the workspace (first tab on
                  right shown below).</p>
                <p><img src="assets/images/clone_data_git_workspace.png"
                    alt="screenshot of cloning in a terminal in a jupyter tab" style="width:90%;">
                </p>
                <ul>
                  <li>A terminal can be opened in the workspace through JupyterLab or by clicking New >
                    Terminal in the Jupyter Home Tab
                  </li>
                  <li>Using login credentials or and ssh key works the same as with your personal
                    computer.
                  </li>
                  <li>The example above is cloning the COVID-19 Csse_daily_reports repository from Johns
                    Hopkins.
                  </li>
                </ul>
                <p><em><span class="note"><b>Tip:</b></span> Make sure to access the repository in your workspace when
                    setting up SSH keys. You cannot copy an SSH key out of the workspace</em></p>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="section">
          <h2 #TheWorkspacePortalPage>
            The Workspace Portal Page
          </h2>
          <img class="guide-img" src="assets/images/workspace_page.png" alt="screenshot of workspace portal page">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Naming a Workspace
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                <p>Update Workspace Name: When the workspace is provisioned, the name of the workspace is the workspace
                  owner's User ID (which is normally their email address). To update the workspace name, click on the
                  edit button next to the workspace name and enter the new name.
                </p>
                <img class="guide-img" src="assets/images/workspace_name.png"
                  alt="screenshot of update workspace name dialog">
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Sharing a Workspace
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                <ul>
                  <li>
                    The red Share Workspace  link in the Users tab enables the owner to share the workspace with others, which is further
                    detailed
                    in
                    the section below: The design of PMP workspaces is such that when a user is provisioned a workspace,
                    no
                    one
                    except
                    the user has access (including the AHA). All code and data is housed behind a secure walled garden
                    that
                    cannot
                    be
                    accessed by anyone other than the user. To provide workspace access for teams working on projects, the
                    owner
                    of
                    a
                    workspace can share their workspace with other people who have a PMP account.
                    <ul>
                      <li>Shared users can access all code and files in the workspace and it is a workspace owner's
                        responsibility
                        to
                        ensure the new shared user has the appropriate data approvals prior to sharing the workspace.
                      </li>
                      <li>
                        To share a workspace with a user, click share and enter the email for the shared user on the PMP
                        and set any additional desired permissions (e.g. allowing to manage workspace users or allowing to manage workspace settings)
                      </li>
                    </ul>
                  </li>
                </ul>
                <img class="guide-img" src="assets/images/share_workspace.png" alt="screenshot of share workspace interface">
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>User Permissions</mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                <p><b>Manage Workspace Users:</b> This permission grants a user the ability to share the workspace with, edit the permissions of, and stop sharing the workspace with other PMP users.</p>

                 <p><b>Manage Workspace Settings:</b> This permission grants a user the ability to manage workspace applications, configurations, and the workspace name.  </p>
                 <p><em><span class="note"><b>Note:</b></span> Requests for a dataset must be submitted by the workspace owner. </em></p>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Starting and Stopping an Application
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                <ul>
                  <li>
                    To preserve the overall resources needed to run and maintain a workspace application, there is a built-in timer that automatically stops an application instance if it has been inactive for more than an hour. A workspace application can also be stopped manually by clicking the stop button next to the application name in the Applications tab on the Workspace portal page. Additionally, when stopped, users with the appropriate permissions can change architectures and compute options for the applications. When restarting, the user will be provided the opportunity to set configurations based on AWS instances.
                  For custom sizing options, please reach out to <a routerLink="/technical-support" >Technical Support</a>.
                    <ul>
                      <li>You can adjust your email notification settings specifically for the workspace application start/stop notifications. On the workspace portal page to the right of the header “My Workspace”, click on the alert icon to disable notification emails when your workspace is started or stopped. By default, these notifications are enabled. If you have access to more than one workspace, this setting applies to all workspaces.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="section">
          <h2 #TheWorkspaceCloudEnvironment>
            The Workspace Cloud Environment
          </h2>
          <img class="guide-img" src="assets/images/juputer_home_page.png" alt="screenshot of workspace cloud environment">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Software and Functionality
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                <h5>Helpful Tips: </h5>
                <ul>
                  <li>The directory where files are located is <code> /home/sagemaker-user/</code></li>
                  <li>The terminal can be accessed in JupyterLab or by clicking the <em>New</em> button in Jupyter</li>
                  <li>The internet is available in the workspace, just click a new tab in Chrome</li>
                  <li>To paste something inside the workspace, click the paste icon on the top menu bar. You can not
                    copy
                    out of the workspace due to security reasons.
                  </li>
                  <li>If the screen is frozen or solid blue/black, click the 4 squares on the top menu bar</li>
                </ul>
                <h5>Root Directory File Location</h5>
                <ul>
                  <li>The root path in the workspace is <code> /home/sagemaker-user/</code>. This is the default directory for JupyterLab, R Studio and SAS Studio.
                  </li>
                </ul>
                <h5>Software</h5>
                <ul>
                  <li>The workspace is <b>fully customizable</b> and other software tools not already available within the
                    workspace
                    are
                    available
                    <a href="JavaScript:void(0);" (click)="scrollToElement('contactForm')">upon request</a>.
                  </li>
                  <li><b>R Studio, JupyterLab, and SAS Studio</b> are available as applications and can be launched from the workspace portal page. The terminal can be accessed through JupyterLab.
                  </li>
                  <li>Python and R based packages can easily be installed by users via pip or conda and
                    install.packages respectively.
                  </li>
                  <li>The following software requires a log in.
                    <table class="doc-table">
                      <tr style="padding: 5px 10px;">
                        <th>Software</th>
                        <th>Username</th>
                        <th>Password</th>
                      </tr>
                      <tr>
                        <td>
                          <span><img src="https://www.r-project.org/logo/Rlogo.png" alt="Drawing;"
                              style='width:25px; vertical-align:middle; border:none; padding-right:5px; padding-bottom: 5px;'>
                            <b>R</b>
                          </span>
                        </td>
                        <td><code>Your individual RStudio account username</code></td>
                        <td><code>rstudio</code></td>
                      </tr>
                      <tr>
                        <td>
                          <span><img
                              src="https://www.automateexcel.com/excel/wp-content/uploads/2018/04/icon-shortcuts-sas.png"
                              alt="Drawing;" style='width:25px; border:none; vertical-align:middle; padding-bottom: 5px;'>
                            <b>SAS</b>
                          </span>
                        </td>
                        <td><code>sasstudio</code></td>
                        <td><code>workspace</code></td>
                      </tr>
                    </table>
                  </li>
                  
                  <li>A comprehensive list of pre-installed packages and software can be found on the
                    <a  routerLink="/workspace/about">About Workspaces</a> page.
                  </li>
                  <li>The ReadMe notebook (data-access-runbook.ipynb) located in the ‘runbooks’ directory on the JupyterLab Home page <code>/home/sagemaker-user/runbooks/</code> provides information regarding workspace usage, including trouble shooting instructions, software references, and tutorials.
                  </li>
                </ul>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Working Directory and Saving Your Work 
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                
                <p>Please save ALL your working files and folders in the <code>/files/workspace_files/ </code> folder. This folder is synced across all your applications (JupyterLab, RStudio, SAS Studio), and any changes made here will be persistent across sessions. It is highly recommended that you use this folder as your working directory, and to save all your files and folders in this directory (<code>/files/workspace_files/</code>).</p>
                  <p><em><span class="note"><b>Note:</b></span> If you do not save your work in the <code>/files/workspace_files/</code> folder, your files may be at risk of deletion in between sessions.</em></p>
                
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Tutorials and Examples
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="expandable-content">
                
                <p>The runbooks located in the <code>/files/runbooks/</code> folder contain information about connecting to the data lake and accessing datasets. Users can also request data science and support services for help with analyses and workspace usage. For more information, <a  href="JavaScript:void(0);" (click)="scrollToElement('contactForm')">
                    contact us.</a>
                </p>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>