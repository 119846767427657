<app-pmp-banner
	titleId="about-container-title"
	subtitleId="about-container-subtitle"
	title="About Us" 
	subTitle="" 
	location="center"></app-pmp-banner>


<div id="about-div" class="about-container col-lg-10 offset-lg-1 col-md-10 offset-sm-1 col-sm-12 offset-xs-0 col-12" style="margin-top: 25px">
		<div id="aboutBanner">
			<p class="about-header sub-heading">
				ABOUT THE AHA INSTITUTE FOR PRECISION CARDIOVASCULAR MEDICINE
			</p>
			Precision medicine considers an individual's genetics, environment and lifestyle in tailoring treatments and looks for patterns across diverse datasets to identify disease triggers or to explain lack of disease. The AHA Institute for Precision Cardiovascular Medicine (Institute) was established in 2013 to advance the science of precision cardiovascular and brain health through its investments in technology and research grants. As of mid-2018 the Institute has awarded 72 grants totaling $18.6M.<br /><br />
			In 2016 and in partnership with Amazon Web Services, The Institute launched the Precision Medicine Platform, a cloud-based data science environment for researchers engaged in scientific discovery.  The platform offers secure workspaces equipped with artificial intelligence and machine learning tools for data analysis.  In collaboration with the scientific community, the Institute has invested in data access solutions and a harmonization framework to drive additional value to researchers. The Institute carefully cultivates the datasets that we bring to the PMP to ensure high quality and relevance to scientists.<br /><br />
			<div style="margin-top:30px;" class="pad15"></div>
			<p class="about-header sub-heading">
				ABOUT THE AMERICAN HEART ASSOCIATION
			</p>
			The American Heart Association (AHA) is devoted to saving people from heart diseases and stroke - two of the leading causes of death in the world. We team with millions of volunteers to fund innovative research, fight for stronger public health policies, and provide lifesaving tools and information to prevent and treat these diseases.<br /><br />
			The Dallas-based association is one of the world's oldest and largest voluntary organizations dedicated to fighting heart diseases and stroke.<br /><br />
			To learn more or to get involved, visit <a href="https://www.heart.org/">https://www.heart.org/</a> or follow us on <a target="_blank" href="https://www.facebook.com/AmericanHeart">Facebook</a> and <a target="_blank" href="https://www.twitter.com/American_Heart">Twitter.</a>
			<div style="margin-top:30px;" class="pad15"></div>
			<p class="about-header sub-heading">
				ABOUT AWS
			</p>
			For more information about Amazon Web Services (AWS) please visit the AWS Nonprofits & NGOs website here: <a href="https://aws.amazon.com/government-education/nonprofits/">https://aws.amazon.com/government-education/nonprofits/</a>
		</div>
	</div>