import { Router, ActivatedRoute} from '@angular/router';
import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { AwsUploadService } from './aws-upload.service';
import { AuthssoService } from './authsso.service';
declare var $ : any;

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends BaseService {
    private logoutBlock$: Subject<boolean> = new Subject();
    private refreshtokenBlock$: Subject<boolean> = new Subject();
    public currentUser: Observable<any>;
    public getTokenAlert: Subject<any> = new Subject();
    clientId: String = "acme";
    redirect_uri: String = window.location.origin + "/loggedin";
    responseType: String = "code";
    state: String = "g6hju7";
    authCode: any;
    refreshTokenTimer: any;
    inactivityTimer: any;
    checkTimer: any;
    cookieConsentName = 'cookieconsent_status';
    tokenData: string;



    constructor(private http: HttpClient, 
        private route: ActivatedRoute,
        private awsUploadService: AwsUploadService,private authsso:AuthssoService,
        private router: Router) {
            super(http, route);
            this.route.queryParams.subscribe(params => {
                this.authCode = params['code'];
            }); 
    }
    
     refreshToken():Promise<any>{
      return new Promise((resolve,reject)=>{
      })
     }

    getToken(user): Observable<any> {
        return new Observable(observer => {
                if (user && user['IdToken']) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    user["ExpiresIn"] = Date.now() + (user["ExpiresIn"] * 1000 - 60000 );
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.authsso.currentUserSubject.next(user);
                    this.setRefreshTokenTimer();
                    this.setInactivityTimer();
                }
                observer.next(user);
                observer.complete();  
            })
        }
    getAzureToken(user): Observable<any> {
        return new Observable(observer => {
                if (user && user.IdToken) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    // user["ExpiresIn"] = Date.now() + (user["expiresInSeconds"] * 1000 - 60000 );
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.authsso.currentUserSubject.next(user);
                }
                observer.next(user);
                observer.complete();  
            })
        }

    getUser(){
        let url = this.getBaseUrl() + "user/getUser";
        return this.http.get(url, {
            headers: this.buildHeaders()
        }).pipe(
            map(user => {
                localStorage.setItem("userInfo", JSON.stringify(user));
                return user as any;
            })
        )
    }

    public getlogoutBlock() {
        return this.logoutBlock$.asObservable();
    }

    public resetlogoutBlock() {
        this.logoutBlock$.next(false);
    }

    private setlogoutBlock(block = false) {
        this.logoutBlock$.next(block);
    }


    public getrefreshtokenBlock() {
        return this.refreshtokenBlock$.asObservable();
    }

    public resetrefreshtokenBlock() {
        this.refreshtokenBlock$.next(false);
    }

    public setrefreshtokenBlock(block = false) {
        this.refreshtokenBlock$.next(block);
    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    public isLoggedIn(){
        return this.authsso.currentUserValue;
      }

      signIn(type?: any) {
        //this.auth.login().subscribe();
        this.authsso.mfaEnabled.next(false)
        let cookiesConsent = this.getCookie(this.cookieConsentName);
        if( cookiesConsent === 'allow'){
            if(type === 'signup'){
                localStorage.setItem("signup", JSON.stringify({signupPath: true}));
            }
          if(!this.isLoggedIn()){
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {  
                this.router.navigate(['/sso']);
            });
          }
        } else {
            $('#cookies-consent').show();
            return false;
        }
        return true;
      }

    logout(checkBlock = false, state = '') {
        if (checkBlock && this.awsUploadService.hasProgressItems()) {
            this.setlogoutBlock(true);
        } else {
            this.authsso.signOut()
            .subscribe(
                error => {
                    //console.log(error);
                });
                // remove user from local storage to log user out
                this.setlogoutBlock(false);
            localStorage.removeItem('currentUser');
            localStorage.removeItem('userInfo');
            sessionStorage.clear();
            this.authsso.currentUserSubject.next(null);
            this.router.navigate(['/']);
            localStorage.setItem("path", JSON.stringify(state));
            if(state){
                this.signIn()
            }
            $(".modal-backdrop").remove();
            $("body").removeClass("modal-open");
        }
    }

    public setRefreshTokenTimer(){
        let refreshTime =  (JSON.parse(localStorage.getItem("currentUser")).ExpiresIn) - Date.now();
        setTimeout(() => {
            this.refreshToken().then(token=>{
                this.setRefreshTokenTimer();   
            })
                        
        }, refreshTime);
    }

    public setInactivityTimer(){
        clearTimeout(this.inactivityTimer);
        clearTimeout(this.checkTimer);
        let inactivityTime = 1000 * 60 * 20;
        let that = this;
        this.checkTimer = setTimeout(() => {
            that.setrefreshtokenBlock(true);
        }, 1000*60*18);
        this.inactivityTimer = setTimeout(() => {
            that.resetrefreshtokenBlock();
            that.logout();
            that.router.navigateByUrl("/");
        }, inactivityTime);
    }
}