

<div class="container container-wide">
  <app-title-subtitle title="Research"
subtitle="The Precision Medicine Platform is changing the way the world does research. Here you’ll find thought leadership, success stories, media, and more. You’ll also find new and historical research opportunities on the Precision Medicine Platform and a comprehensive list of research and publications."
></app-title-subtitle>
  <div class="row">
      <div class="col-lg-8">
        <div class="container-fluid col" style="padding-top: 35px; padding-left: 25px;"  id="accordion" role="tablist" aria-multiselectable="true">
          <app-nested-accordion [data]="data" [isRoot]="true"></app-nested-accordion>
        </div>
    </div>

    <div  class="col-lg-4 " style="margin-top: 30px;">
      <h3>Press</h3>
      <div class="press-releases">
        <div *ngFor="let pressRelease of pressReleases"  style="margin-top: 20px;">
          <h4>{{ pressRelease.year }}</h4>
          <div *ngFor="let child of pressRelease.childs" style="margin-top: 15px;">
            <a [href]="child.link" target="_blank">{{ child.name }}</a>
          </div>
        </div>
    </div>
    </div>
  </div>
</div>
