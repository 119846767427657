

<!-- banner.component.html -->
<div class="timing-banner">
    
    <!-- <div class="content-container"> -->
      <!-- <div class="container-fluid"> -->
        <div class="main-container">
          <div class="description">
            <div class="heading">Please join our weekly office hours if you have questions about navigating the updated portal and/or workspace applications              
            </div>
            <div class="time-details">
              <div class="list-item">
                <div class="time-info">Tuesdays at 12-12:30 ET / 11-11:30 CT / 10-10:30 MT / 9-9:30 PT</div>
                <button  class="btn btn-sign-up btn-transparent-red" (click)="tuesdayTiming()"type="button">
                  Join here
                </button>
              </div>
              <div class="list-item">
                <div class="time-info">Fridays at 3-3:30 ET / 2-2:30 CT / 1-1:30 MT / 12 – 12:30 PT</div>
                <button  class="btn btn-sign-up btn-transparent-red"  (click)="fridayTiming()" type="button">
                  Join here
                </button>
              </div>
            </div>
          </div>
          <div class="pmp-team">
            <img src="../../../../assets/images/pmp-laptop.svg" alt="previous" />
          </div>

        </div>
      <!-- </div> -->
    <!-- </div> -->
  </div>