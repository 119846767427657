import { environment } from '../environments/environment';

interface Scripts {
    name: string;
    src: string;
}
const gaProperty = environment.google_analytics;

export const ScriptStore: Scripts[] = [
    {name: 'footer', src: 'https://static.heart.org/ahaanywhere/sitecore/dist/ahaFooterSitecore.js'},
    {name: 'googleAnalytics', src: 'https://www.googletagmanager.com/gtag/js?id=' + gaProperty},
    {name: 'analytics', src: 'https://s3.amazonaws.com/cdn.heart.org/googleanalytics/ga-custom.js'}
];