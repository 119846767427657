<ng-container *ngIf="data">
  <mat-accordion [multi]="isRoot">
    <mat-expansion-panel *ngFor="let item of data" [expanded]="isRoot">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ item.name }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <!-- Check if item has children -->
      <ng-container *ngIf="item.childs && item.childs.length > 0">
        
        <!-- Directly display links if in second layer -->
        <div *ngIf="item.childs[0].link; else recursiveTemplate">
          <div *ngFor="let child of item.childs" style="margin-top: 20px;">
            <a [href]="child.link" target="_blank">{{ child.name }}</a>
          </div>
        </div>

        <!-- Recursive template -->
        <ng-template #recursiveTemplate>
          <div style="margin-top: 20px;">
          <app-nested-accordion [data]="item.childs" ></app-nested-accordion>
        </div>
        </ng-template>

      </ng-container>

    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
