import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import {PmpBannerComponent} from '../../../shared/components/pmp-banner/pmp-banner.component';
import { HomeService } from './home.service';
import { CommonModule } from '@angular/common';  // Import CommonModule
import { SharedModule } from '../../../shared/shared.module';
@Component({
  selector: 'app-home',
  providers: [PmpBannerComponent],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, SharedModule],
  standalone: true
})
export class HomeComponent implements OnInit {

  bannerList: any = [];

  constructor(private homeService: HomeService, private pmpBannerComponent: PmpBannerComponent) { }
  

  ngOnInit() {
    this.bannerList = []
    this.homeService.getBanner().subscribe(data => {
      for(let _banner of data['banners']){
        if( 'location' in _banner && _banner['location'] === 'home'){
          this.bannerList.push(_banner);
        }
      }
    }, error => {
      console.log(error);
    });
  }

  public registerButtonClick(){
    this.pmpBannerComponent.bannerButtonClick();
  }

}
