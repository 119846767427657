<div class="container-fluid container-fluid-home">
  <div class="row">
    <div class="col-12 my-5">
      <h2 class="feature-main-title">Faster Computing and Easy Access Lead to More Discoveries.</h2>
    </div>
  </div>

  <div class="row justify-content-center feature-row">
    <!-- Repeat this block for each feature -->
    <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-4">
      <div class="feature">
        <img src="assets/images/feature/cloud-computation.svg" alt="Cloud Computation" class="img-fluid">
        <h3 class="feature-title">Cloud Computation</h3>
        <p class="feature-subtitle">Access powerful cloud processing from any computer</p>
      </div>
    </div>
    <!-- End of feature block -->
    <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-4">
      <div class="feature">
        <img src="assets/images/feature/data-access.svg" alt="Data Access" class="img-fluid">
        <h3 class="feature-title">Data Access</h3>
        <p class="feature-subtitle">Get integrated access to diverse datasets, including the Get With The Guidelines
          registry</p>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-4">
      <div class="feature">
        <img src="assets/images/feature/secure-collaboration.svg" alt="Secure Collaboration" class="img-fluid">
        <h3 class="feature-title">Secure Collaboration</h3>
        <p class="feature-subtitle">Safely share workspaces with collaborators across the globe</p>
      </div>
    </div>

  </div>

  <div class="row justify-content-center">

    <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-4">
      <div class="feature">
        <img src="assets/images/feature/machine-learning.svg" alt="Machine Learning" class="img-fluid">
        <h3 class="feature-title">Machine Learning</h3>
        <p class="feature-subtitle">Improve your research with systems that learn and improve automatically</p>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-4">
      <div class="feature">
        <img src="assets/images/feature/integrated-applications.svg" alt="Integrated Applications" class="img-fluid">
        <h3 class="feature-title">Integrated Applications</h3>
        <p class="feature-subtitle">Customize and code based on the needs of your project</p>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-4">
      <div class="feature">
        <img src="assets/images/feature/data-storage.svg" alt="Data Storage & Sharing" class="img-fluid">
        <h3 class="feature-title">Data Storage & Sharing</h3>
        <p class="feature-subtitle">Securely store and analyze data </p>
      </div>
    </div>
  </div>
</div>