import { Component, ViewChild } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import {TechnicalSupportService} from './technical-support.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';
import { SharedModule } from '../../../shared/shared.module';
@Component({
  selector: 'app-technical-support',
  templateUrl: './technical-support.component.html',
  styleUrls: ['./technical-support.component.scss'],
  standalone: true,
  imports: [SharedModule]
})
export class TechnicalSupportComponent {

  @ViewChild('fileInput') fileInput;
  
  technicalSupportForm: FormGroup;
  message: string;
  errorMessage: string;
  firstName: string;
  lastName: string;
  country: string;
  email: string;
  phone: string;
  organization: string;
  queryDescription: string;
  technicalSupportRequest: TechnicalSupportRequest;
  base64file: any;
  fileName: string;
  fileInputName: any;

  createForm() {
    this.technicalSupportRequest = new TechnicalSupportRequest();
    this.technicalSupportForm = this.fb.group(new TechnicalSupportRequest());
  }

  constructor(private technicalSupportService: TechnicalSupportService,
    private ngxService: NgxUiLoaderService, private fb: FormBuilder) {
      this.createForm();
  }

  changeListener($event) : void {
    this.readThis($event.target);
  }
  
  readThis(inputValue: any): void {
    this.fileName = '';
    this.base64file = '';
    var file:File = inputValue.files[0];
    if( !file )
      return;

    this.fileName = file.name;
    var myReader:FileReader = new FileReader();
  
    myReader.onloadend = (e) => {
      let fileBase64: any = myReader.result;
      this.base64file = fileBase64.split(';base64,')[1];
    }
    myReader.readAsDataURL(file);
  }

  validation() {

    if ( this.technicalSupportForm.invalid ) {
      return false;
    }
    let fileBrowser = this.fileInput.nativeElement;
    if (fileBrowser.files && fileBrowser.files[0]) {
      var file:File = fileBrowser.files[0];
      var limit = 5 * 1024 * 1024;    // 5 MB
      if(file.size > limit){
        this.errorMessage = 'Maximum upload size of 5 MB exceeded';
        return false;
      }
    }
    return true;
  }

  public submitTechnicalSupportRequest() {
    if( !this.validation() ) {
      return;
    }
    
    let request: TechnicalSupportRequest = Object.assign({}, this.technicalSupportForm.value);
    if( this.fileName  ) {
      request.base64file = this.base64file;
    }
    this.technicalSupportService.submitRequest(request).subscribe(
      data => {
        this.message = 'Request has been submitted successfully. Someone will soon get in touch with you';
        this.technicalSupportForm.reset();
      },
      error => {
        this.errorMessage = 'There was an error trying to submit technical support request. Please contact Support';
      }
    )
  }
}

export class TechnicalSupportRequest{
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  phone: string = '';
  organization: string = '';
  country: string = '';
  queryDescription: string = '';
  base64file: any;
  fileName: string = '';
}
