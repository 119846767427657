import { Component, HostBinding } from '@angular/core';

@Component({
  selector: '[app-dr-row]',
  templateUrl: './dr-row.component.html',
  styleUrls: ['./dr-row.component.scss'],
  standalone: true
})
export class DrRowComponent {
  @HostBinding('class.row') row = true;
  @HostBinding('class.pb-5') padding = true;
  @HostBinding('class.no-gutters') noGutters = true;

}
