import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PmpBannerComponent } from './components/pmp-banner/pmp-banner.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpErrorHandler } from './services/http-error-handler.service';
import { MessageService } from './services/message.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NouisliderModule } from 'ng2-nouislider';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import {TouComponent} from './components/tos/tos.component';
import { InfoCardComponent } from '../modules/dashboard/components/info-card/info-card.component';
import { ActiveStatusComponent } from '../modules/dashboard/components/active-status/active-status.component';
import { MaterialModule } from './material/material.module';
import { MultiSelectComponent } from '../modules/dashboard/components/multi-select/multi-select.component';
import { LoaderComponent } from '../modules/dashboard/components/loader/loader.component';
import { NameCellComponent } from '../modules/dashboard/components/name-cell/name-cell.component';
import { DownloadDropdownComponent } from '../modules/dashboard/components/download-dropdown/download-dropdown.component';
import { BackgroundLayerComponent } from '../modules/dashboard/components/background-layer/background-layer.component';
import { ErrorMessageComponent } from '../modules/dashboard/components/error-message/error-message.component';
import { TitleSubtitleComponent } from './components/title-subtitle/title-subtitle.component';
import { ContactBoxComponent } from './components/contact-box/contact-box.component';
import { ContactSectionComponent } from './components/contact-section/contact-section.component';
import { MiniBoxComponent } from './components/mini-box/mini-box.component';
import { NestedAccordionComponent } from './components/nested-accordion/nested-accordion.component';
import { ContentNavigationComponent } from './components/content-navigation/content-navigation.component';
import { PtfiTosComponent } from './components/ptfi-tos/ptfi-tos.component';
import { AlertBoxComponent } from './components/alert-box/alert-box.component';
import { DndDirective } from './directives/dnd.directive';
import { PricingBoxComponent } from './components/pricing-box/pricing-box.component';
import { RouterModule } from '@angular/router';
import { PricingContentComponent } from './components/pricing-content/pricing-content.component';
import { GridSectionComponent } from './components/grid-section/grid-section.component';
import { SmallBannerComponent } from './components/small-banner/small-banner.component';
import { FeatureGridComponent } from './components/feature-grid/feature-grid.component';
import { ParaBlockComponent } from './components/para-block/para-block.component';
import { PmpBannerHomeComponent } from './components/pmp-banner-home-page/pmp-banner.component';
import { TimingBannerComponent } from './components/timing-banner/timing-banner.component';
import { AddiLoginComponent } from '../../app/modules/home/addi-login/addi-login.component';
import { WorkspaceConfigurationComponent } from '../../app/modules/request-access/workspace-configuration/workspace-configuration.component';
import { WorkspaceAdvancedConfigurationComponent } from '../../app/modules/request-access/workspace-advanced-configuration/workspace-advanced-configuration.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
@NgModule({
  declarations: [
    PmpBannerComponent,
    InfoCardComponent,
    MultiSelectComponent,
    ActiveStatusComponent,
    AlertModalComponent,
    ConfirmDialogComponent,
    TouComponent,
    LoaderComponent,
    NameCellComponent,
    DownloadDropdownComponent,
    BackgroundLayerComponent,
    ErrorMessageComponent,
    TitleSubtitleComponent,
    ContactBoxComponent,
    ContactSectionComponent,
    MiniBoxComponent,
    NestedAccordionComponent,
    ContentNavigationComponent,
    PtfiTosComponent,
    AlertBoxComponent,
    DndDirective,
    PricingBoxComponent,
    PricingContentComponent,
    GridSectionComponent,
    FeatureGridComponent,
    SmallBannerComponent,
    ParaBlockComponent,
    PmpBannerHomeComponent,
    TimingBannerComponent,
    AddiLoginComponent,
    WorkspaceConfigurationComponent,
    WorkspaceAdvancedConfigurationComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    RouterModule,
    NgxExtendedPdfViewerModule

    
  ],
  exports: [
    PmpBannerComponent,
    InfoCardComponent,
    MultiSelectComponent,
    ActiveStatusComponent,
    AlertModalComponent,
    ConfirmDialogComponent,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    NgxChartsModule,
    MaterialModule,
    NgxExtendedPdfViewerModule,
    NouisliderModule,
    TouComponent,
    LoaderComponent,
    NameCellComponent,
    DownloadDropdownComponent,
    BackgroundLayerComponent,
    ErrorMessageComponent,
    TitleSubtitleComponent,
    ContactBoxComponent,
    ContactSectionComponent,
    MiniBoxComponent,
    NestedAccordionComponent,
    ContentNavigationComponent,
    PtfiTosComponent,
    AlertBoxComponent,
    DndDirective ,
    PricingBoxComponent,
    PricingContentComponent,
    GridSectionComponent,
    SmallBannerComponent,
    FeatureGridComponent,
    ParaBlockComponent,
    PmpBannerHomeComponent,
    TimingBannerComponent,
    AddiLoginComponent,
    WorkspaceConfigurationComponent,
    WorkspaceAdvancedConfigurationComponent

  ],
  providers: [
    HttpErrorHandler,
    MessageService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
