<div class="container">
  <div class="w-840">
    <button class="pl-6 back" (click)="goBack()">← Go Back</button>
    <h1 class="title">Multi-factor authentication help</h1>
    <div class="alert-mfa">
      Multi-factor authentication (MFA) is a security process that requires users
      to provide two or more independent factors to verify their identity when
      accessing an account or system.
    </div>
    <h6 class="sub-title">Set up an Authenticator App</h6>
    <div class="row mb-74">
      <div class="col-6 d-flex align-items-center steps">
        1. Download an authenticator app like Google Authenticator, Microsoft
        Authenticator or Authy from your app store.
      </div>
      <div class="col-6 d-flex align-items-end justify-content-end auth-img">
        <img src="../../../../assets/images/image 23.svg" alt="no image" />
      </div>
      <div class="col-6 d-flex align-items-end justify-content-start auth-img">
        <img src="../../../../assets/images/image.svg" alt="no image" />
      </div>
      <div class="col-6 d-flex align-items-center steps">
        2. On the website, you’ll see a QR code or a manual key.
      </div>
      <div class="col-6 d-flex align-items-center steps">
        3. Open the authenticator app and, Scan the QR code displayed on the
        screen.
      </div>
      <div class="col-6 d-flex align-items-end justify-content-end auth-img">
        <img src="../../../../assets/images/image (1).svg" alt="no image" />
      </div>
      <div class="col-6 d-flex align-items-end justify-content-start auth-img">
        <img src="../../../../assets/images/image (2).svg" alt="no image" />
      </div>
      <div class="col-6 d-flex align-items-center steps">
        4. If scanning isn't possible, manually enter the key provided.
      </div>
    </div>
    <h6 class="sub-title mt-90">Generate and Verify the Code</h6>
    <div class="row">
      <div class="col-6 d-flex align-items-center steps">
        1. The authenticator app will display a 6-digit code that refreshes every
        30 seconds.
      </div>
      <div class="col-6 d-flex align-items-end justify-content-end auth-img">
        <img src="../../../../assets/images/image (3).svg" alt="no image" />
      </div>
      <div class="col-6 d-flex align-items-end justify-content-start code-img">
        <img src="../../../../assets/images/image (4).svg" alt="no image" />
      </div>
      <div class="col-6 d-flex align-items-center steps">
        2. Enter this code on the website or app where prompted to verify the setup.
      </div>
    </div>
  </div>
  
</div>
