import { Component } from '@angular/core';

@Component({
  selector: 'app-tou',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss']
})
export class TouComponent {

  constructor() { }
}
