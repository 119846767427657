<style type="text/css">
    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        background-color: #DF0000;
    }
</style>


<div style="margin-top: 100px;"></div>

<div class="container-wide">
    <div class="row no-gutters">
        <div class="col-12 col-lg-6">
            <div id="login-register-container" class="container" style="padding-top: 25px;" *ngIf="!mfaenabled">
                <div class="row" id="badCredentialErrDiv" *ngIf="login.showErrorMessage">
                    <div class="col-lg-6 offset-lg-3 alert alert-danger fade show alert-dismissable  sso-message">
                        <a href="#" class="close" (click)="login.showErrorMessage = false;" title="close">x</a>
                        <p [textContent]="login.errorMsg">Username and password did not match our records.</p>
                        <p [textContent]="login.errorMessage">If you have not set up MFA, please click on the Forgot Password link and then set up MFA.</p>
                        <p [textContent]="login.errorMessage2">If you have already set up MFA, please check your credentials and reset your password if needed.</p>
                    </div>
                </div>
                <div class="row" *ngIf="login.showMessage">
                    <div class="col-lg-6 offset-lg-3 alert alert-info fade show alert-dismissable sso-message">
                        <a href="#" class="close" data-dismiss="alert" aria-label="close" title="close">x</a>
                        <p [textContent]="login.message"></p>
                    </div>
                </div>

                <div class="row" id="loginRegisterDiv" [hidden]="login.forgotPassword || login.confirmRegisterUser">
                    <div class="col-sm-12 col-12 col-lg-8 offset-lg-2"
                        style="border-radius: 5px;padding: 10px 30px 0 30px; margin-bottom: 20px;">
                        <!-- <ul class="nav nav-pills nav-justified">
                            <li id="loginTabLi" class="nav-item"><a id="enableLoginTab" class="nav-link active"
                                    data-toggle="tab" href="#logintab">Login</a></li>
                            <li id="registerTabli" class="nav-item"><a id="enableRegisterTab" class="nav-link"
                                    data-toggle="tab" href="#registertab">Register</a></li>
                        </ul> -->
                        <div *ngIf="!isSignupFieldsValid" style="color: #da1527;">
                            Special characters not allowed.
                          </div>
                          <div *ngIf="!specialCharacter" style="color: #da1527;">
                            Special characters not allowed
                          </div>
                          <div *ngIf="!isValid && specialCharacter" style="color: #da1527;">
                            For FirstName and LastName Only letters, numbers, space, dot (.), hyphen (-) and &#64; are allowed.
                          </div>
                        <div class="sign-in-header">
                            <span *ngIf="currentTab === 'register'">SIGN UP</span>
                            <span *ngIf="currentTab === 'signin' ">SIGN IN</span>
                        </div>
                        <div *ngIf="currentTab === 'signin'" class="sign-in-text">Need an account?
                            <a #signUpTab data-toggle="tab" href="#registertab" id="enableRegisterTab"
                                (click)="onTabChange('register')">Sign Up</a>
                        </div>

                        <div *ngIf="currentTab === 'register'" class="sign-in-text">Already have an account?
                            <a #signInTab data-toggle="tab" href="#logintab" id="enableLoginTab"
                                (click)="onTabChange('signin')">Sign In</a>
                        </div>
                        <div class="tab-content">
                            <div class="pad7-5"></div>
                            <div id="logintab" class="tab-pane fade show active">
                               <!-- // <form ngNoForm name="loginForm"> -->
                                    
                                    <div class="row">
                                        <div class="col-lg-12" style="padding-top: 10px; padding-bottom: 10px;">
                                            <label>Email:</label>
                                        </div>
                                        <div class="col-lg-12 pmp-input">
                                            <input [(ngModel)]="username" type="text"  aria-label="Email" placeholder="Email">
                                        </div>
                                    </div>
                                    <div class="row" style="margin-top: 15px !important;">
                                        <div class="col-lg-12" style="padding-top: 10px; padding-bottom: 10px;">
                                            <label>Password:</label>
                                        </div>
                                        <div class="col-lg-12 pmp-input">
                                            <input [(ngModel)]="password" type="password"  aria-label="Password"
                                                placeholder="Password is case sensitive">
                                            <sub style="align-content: center; font-size: 16px; margin-top: 10px;color:#334CD5; cursor: pointer;"><a
                                                    id="forgot-password"
                                                    (click)="forgotPasswordClicked(); ">Forgot
                                                    Password</a></sub>
                                        </div>
                                    </div>
                                    <div class="pad7-5 text-center" style="margin-top: 30px;">
                                        <button class="btn pmp-primary-button form-control  sso-primary"
                                             (click)="onLogin()">Login</button>                                         
                                    </div>
                                <!-- </form> -->

                                <div class="or-seperator">
                                    <div class="line"></div>
                                    <span>or</span>
                                    <div class="line"></div>
                                </div>

                                <div class="pad7-5 text-center">
                                    <span
                                        *ngIf="login.profile == 'develop' || login.profile == 'dev' || login.profile== 'default'">
                                        <form class="sso-buttons" ngNoForm id="loginFormUsingPiv"
                                            action="https://dev.sso.precision.heart.org/sso/login/piv" method="post">
                                            <input type="hidden" name="_csrf">
                                            <button class="btn pmp-primary-button form-control sso-secondary"
                                                id="pivloginbutton" type="submit">Login with PIV</button>
                                        </form>
                                    </span>
                                    <span *ngIf="login.profile == 'qa'">
                                        <form class="sso-buttons" ngNoForm id="loginFormUsingPiv"
                                            action="https://qa.sso.precision.heart.org/sso/login/piv" method="post">
                                            <input type="hidden" name="_csrf">
                                            <button class="btn pmp-primary-button form-control sso-secondary"
                                                id="pivloginbutton" type="submit">Login with PIV</button>
                                        </form>
                                    </span>
                                    <span *ngIf="login.profile == 'prod'">
                                        <form class="sso-buttons" ngNoForm id="loginFormUsingPiv"
                                            action="https://sso.precision.heart.org/sso/login/piv" method="post">
                                            <input type="hidden" name="_csrf">
                                            <button class="btn pmp-primary-button form-control sso-secondary"
                                                id="pivloginbutton" type="submit">Login with PIV</button>
                                        </form>
                                    </span>

                                    <!-- <form class="sso-buttons" ngNoForm id="loginFormUsingGoogle"
                                        action="/sso/login/google" method="post">
                                        <input type="hidden" name="_csrf">
                                        <button
                                            class="btn pmp-primary-button form-control sso-secondary"
                                            id="googleloginbutton" type="submit">Login with Google</button>
                                    </form> -->
                                    <button class="btn pmp-primary-button form-control sso-secondary" style="margin-right: 0px !important;"
                                    id="loginWithAD" (click)="loginWithAD('AHA_LOGIN')">AHA Staff Login</button>
                                    <button class="btn pmp-primary-button form-control sso-secondary" style="margin-right: 0px !important;"
                                    id="loginWithADDI" (click)="loginWithAD('ADDI_LOGIN')">AD Workbench Login</button>
                                </div>
                            </div>
           
                            <div id="registertab" class="tab-pane fade">
                                <form id="registerForm" class="register-form" method="post">
                                    <div class="row">
                                        <div class="col-lg-6" style="padding-bottom: 10px; padding-top: 10px;">
                                            <label for="firstName">First Name *</label>
                                        </div>
                                        <div class="col-lg-6" style="padding-bottom: 10px; padding-top: 10px;"><label
                                                for="lastName">Last Name *</label></div>

                                        <div class="col-lg-6 pmp-input">
                                            <input id="firstName" type="text" name="firstName"
                                                [(ngModel)]="login.user.firstName" />

                                        </div>


                                        <div class="col-lg-6 pmp-input">
                                            <input id="lastName" type="text" name="lastName"
                                                [(ngModel)]="login.user.lastName" />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-12" style="padding-bottom: 10px; padding-top: 10px;"><label
                                                for="emailId">Email *</label></div>
                                        <div class="col-lg-12 pmp-input">
                                            <input id="emailId" type="email" name="email"
                                                [(ngModel)]="login.user.emailId" />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-12" style="padding-bottom: 10px; padding-top: 10px;"><label
                                                for="registerPassword">Password *</label></div>
                                        <div class="col-lg-12 pmp-input">
                                            <input id="registerPassword" autocomplete="off" class="password"
                                                name="password" type="password" [(ngModel)]="login.user.password" />
                                        </div>
                                        <div class="col-lg-12 d-none passwordRuleDiv">
                                            <p><strong>Password must meet the following requirements:</strong></p>
                                            <ul class="passwordRuleList" style="list-style-type: none;">
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12" style="padding-bottom: 10px; padding-top: 10px;"><label
                                                for="registerConfirmPassword">Confirm Password *</label></div>
                                        <div class="col-lg-12 pmp-input">
                                            <input id="registerConfirmPassword" name="confirmPassword"
                                                autocomplete="off" class="confirmPassword" type="password"
                                                [(ngModel)]="login.user.confirmPassword" />
                                        </div>
                                        <div class="col-md-10 offset-md-2 d-none confirmPasswordCheckDiv">

                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-12" style="padding-bottom: 10px; padding-top: 10px;"><label
                                                for="organization">Organization Name *</label></div>
                                        <div class="col-lg-12 pmp-input">
                                            <input id="organization" type="text" name="organization"
                                                [(ngModel)]="login.user.organization" />
                                        </div>
                                    </div>

                                    <div class="pad7-5 text-center">
                                        <button (click)="submitRegisterUser(login.user)"
                                            class="btn pmp-primary-button form-control passwordSubmitBtn register-new btn-transparent-red"
                                            id="register" type="submit">Register</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" id="confirm-register-user-container" *ngIf="login.confirmRegisterUser">
                    <div class="col-sm-12 col-12 col-lg-8 offset-lg-2"
                        style="border-radius: 5px;padding-top: 10px;margin-bottom: 20px;">
                        <!-- <ul class="nav nav-pills nav-justified">

                            <li class="active nav-item"><a data-toggle="tab" class="d-sm-block nav-link"
                                    href="#confirmTab">Enter
                                    Verification Code</a></li>
                        </ul> -->

                        <div class="sign-in-header">
                            <span>ENTER VERIFICATION CODE</span>
                        </div>
                        <div class="form-group pad7-5">
                            <form>
                                <div class="row pad7-5">
                                    <div class="pmp-input col-sm-12 col-12">
                                        <input name="confirmationCode" class="confirmationCode"
                                            [(ngModel)]="login.user.code" placeholder="000000" type="text">
                                    </div>
                                    <div class="col-md-10 offset-md-2 d-none confirmationCodeCheckDiv">

                                    </div>
                                </div>
                                <div class="pad7-5" style="margin-top: 20px;">
                                    <button (click)="confirmRegisterUserSubmit(registeredUser)"
                                        class="btn pmp-primary-button form-control sso-primary"
                                        type="submit">Submit</button>
                                    <button class="btn btn-default form-control showLogin sso-secondary sso-secondary-3"
                                        (click)="backToLogin()" type="button">Cancel</button>
                                </div>
                                <div class="pad7-5">

                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div class="row" id="forgot-password-step1-container"
                    *ngIf="login.forgotPassword && login.forgotPasswordStep1">
                    <div class="col-sm-12 col-12 col-lg-8 offset-lg-2"
                        style="border-radius: 5px;padding-top: 10px;margin-bottom: 20px;">
                        <!-- <ul class="nav nav-pills nav-justified">
                            <li class="active nav-item"><a data-toggle="tab" class="d-sm-block nav-link"
                                    href="#confirmTab">Reset
                                    Password</a></li>
                        </ul> -->
                        <div *ngIf="!isValid && specialCharacter" style="color: #da1527;">
                            Special characters not allowed.
                        </div>
                        <div *ngIf="!specialCharacter" style="color: #da1527;">
                            Special characters `<` and `>` are not allowed
                          </div>
                        <div class="sign-in-header">
                            <span>RESET PASSWORD</span>
                        </div>

                        <div class="form-group pad7-5">
                            <form>
                                <div class="row pad7-5">
                                    <label class="col-sm-12 col-12 pad7-5" for="email">Please enter your email address
                                        (username): </label>
                                    <div class="col-sm-12 col-12 pmp-input">
                                        <input type="email" name="email" id="email"
                                            [(ngModel)]="login.resetPasswordForm.email" placeholder="Email">
                                    </div>
                                </div>
                                <div class="pad7-5" style="margin-top: 20px;">
                                    <button (click)="requestConfirmationCode(false,$event);"
                                        class="btn pmp-primary-button form-control sso-primary"
                                        id="forgotPasswordStep1Submit">Submit</button>

                                    <button class="btn btn-default form-control showLogin sso-secondary sso-secondary-3"
                                        (click)="backToLogin()" type="button">Cancel</button>
                                </div>
                                <div class="pad7-5">

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="row" id="forgot-password-step2-container"
                    *ngIf="login.forgotPassword && !login.forgotPasswordStep1">
                    <div class="col-sm-12 col-12 col-lg-8 offset-lg-2"
                        style="border-radius: 5px;padding-top: 10px;margin-bottom: 20px;">
                        <!-- <ul class="nav nav-pills nav-justified">
                            <li class="active nav-item"><a data-toggle="tab" class="d-sm-block nav-link"
                                    href="#confirmTab">Reset
                                    Password</a></li>
                        </ul> -->
                        <div class="sign-in-header">
                            <span>RESET PASSWORD</span>
                        </div>
                        <div class="form-group pad7-5">
                            <form>
                                <div class="row pad7-5">
                                    <label class="col-sm-12 col-12 pad7-5" for="email">Please enter password reset
                                        verification
                                        code</label>
                                    <div class="pmp-input col-sm-12 col-12">
                                        <input name="confirmationCode" class="resetPasswordconfirmationCode"
                                            [(ngModel)]="login.resetPasswordForm.confirmationCode" placeholder="000000"
                                            type="text">
                                    </div>
                                    <div class="col-md-10 offset-md-2 d-none resetPasswordConfirmationCodeDiv">

                                    </div>
                                    <div class="col-md-10" style="margin-top: -5px;">
                                        <sub style="align-content: center"><a href="#"
                                            (click)="requestConfirmationCode(true,$event)" id="resend-code">Resend
                                            Code</a></sub>
                                    </div>
                                </div>

                                <div class="row pad7-5">
                                    <div class="pmp-input col-sm-12 col-12">
                                        <input class="password resetPassword" id="password" name="resetPassword"
                                            (blur)="onResetPasswrodInputBlur()" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="login.resetPasswordForm.password" placeholder="New Password"
                                            type="password" autocomplete="off" />
                                    </div>
                                    <div class="col-md-10 d-none resetPasswordCheckDiv"></div>
                                    <div class="passwordRuleDiv col-md-10 d-none">
                                        <p><strong>Password must meet the following requirements:</strong></p>
                                        <ul class="passwordRuleList" style="list-style-type: none;">
                                        </ul>
                                    </div>
                                </div>
                                <div class="row pad7-5">
                                    <div class="pmp-input col-sm-12 col-12">
                                        <input class="confirmPassword resetConfirmPassword" name="confirmPassword" id="confirmPassword"
                                            (blur)="onConfirmPasswrodInputBlur()"
                                            [(ngModel)]="login.resetPasswordForm.confirmPassword"
                                            placeholder="Confirm Password" type="password" autocomplete="off" />
                                    </div>
                                    <div class="col-md-10 d-none resetConfirmPasswordCheckDiv"></div>
                                </div>
                                <div class="pad7-5" style="margin-top: 20px;">
                                    <button (click)="submitPasswordReset()"
                                        class="btn pmp-primary-button form-control passwordSubmitBtn sso-primary"
                                        id="forgotPasswordStep2" type="submit">Submit</button>

                                    <button class="btn btn-default form-control showLogin sso-secondary sso-secondary-3"
                                        (click)="backToLogin()" type="button">Cancel</button>
                                </div>
                                <div class="pad7-5">

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="mfaenabled">
                <app-two-step-authentication [mfaenabled]="mfaenabled" [SecretCode]="SecretCode" [errorMsg]="errorMsg" (selectedVerificationType)="selectedVerificationType($event)" (updateauthCode)="updateauthCode($event)" [email]="username" [qrCode]="qrCodeImage"></app-two-step-authentication>
              </div>

        </div>
        <div class="col-12 col-lg-6">
            <img class="sso-cover" src="assets/images/sso-cover.svg" />
        </div>
    </div>
</div>

<router-outlet></router-outlet>

