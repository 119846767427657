<div *ngIf="systemFailure" class="service-failure-banner">
    <div class="alert service-failure-message fade show" >
        <div>
            <p style="text-align: center">
                The Precision Medicine Platform is currently experiencing degraded service of underlying dependencies, some portions
                of the platform might not work. Please contact <a href="mailto:pmp_admin@precision.heart.org">pmp_admin&#64;precision.heart.org</a> with any questions.</p>
            <div style="text-align: center">
                    <button class="btn btn-primary primary-btn" style="margin-left:15px;" (click)="setServieFailure(false)" type="button">CLOSE</button>
            </div>
        </div>
    </div>
</div>