<div class="card-box">
  <!--
  <img src="/assets/logo/aha_heart.svg"> 
  <div class="vl"></div>
  -->
  <p class="dr-card-icon" aria-hidden="true">{{ iconName }}</p>
  <h2 class="section-title"> {{ section_title }} </h2>
  <ng-content select="[content]"></ng-content>
  <div class="extra-wrapper">
    <ng-content select="[extra]"></ng-content>
  </div>
</div>