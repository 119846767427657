import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../shared/services/auth.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';  // Adjust the path

@Component({
  selector: 'app-upload-progress-alert',
  templateUrl: './upload-alert.component.html',
  styleUrls: ['./upload-alert.component.scss'],
  imports: [CommonModule, SharedModule],
  standalone: true
})
export class UploadAlertComponent implements OnInit {
  open;
  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
    this.open = this.authService.getlogoutBlock();
  }


  onAlertChange(event) {
    if (event) {
      this.authService.logout();
    } else {
      this.authService.resetlogoutBlock();
    }
  }
}
