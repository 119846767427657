
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dr-card',
  templateUrl: './dr-card.component.html',
  styleUrls: ['./dr-card.component.scss'],
  standalone: true
})
export class DRCardComponent {

  @Input() section_title: string;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() buttonText: string;
  @Input() titleId: string;
  @Input() subtitleId: string;
  @Input() location: string;
  @Input() buttonRoute: string;
  @Input() iconName: string;

  constructor() { }

}