import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { AuthenticationService } from '../../../app/shared/services/auth.service';
import { AuthssoService } from '../../../app/shared/services/authsso.service';
import { InactivityTimerService } from '../../../app/shared/services/inactivity-timer-service.service';
import { first, map, tap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FormsModule } from '@angular/forms';
import { BaseService } from '../../../app/shared/services/base.service';
import { TwoStepAutentication } from '../mfa/two-step-autentication/two-step-autentication.component';
declare var $: any;
@Component({
selector: 'app-authlogin',
standalone:true,
imports: [CommonModule, RouterOutlet,NgxUiLoaderModule,FormsModule,TwoStepAutentication],
templateUrl: './auth-sso.component.html',
styleUrls: ['./auth-sso.component.scss'],



})
export class AuthSsoComponent {
username: string = '';
password: string = '';
registeredUser="";
specialCharacter: boolean = true;
enableTwoFactor:boolean = false;
signup: any;
@ViewChild('signUpTab') signUpTab!: ElementRef;
@ViewChild('signInTab') signInTab!: ElementRef;
message = '';
currentTab = 'signin';
login = {
  user: {
    firstName: '',
    lastName: '',
    emailId: '',
    organization: '',
    password: '',
    confirmPassword: '',
    code: ''
  },
  resetPasswordForm: {
    password: '',
    confirmPassword: '',
    confirmationCode: '',
    email: ''
  },
  forgotPassword: false,
  showErrorMessage: false,
  errorMessage: '',
  errorMessage2:'',
  errorMsg:'',
  showMessage: false,
  message: '',
  confirmRegisterUser: false,
  code: '',
  forgotPasswordStep1: false,
  profile: '',
  resetEmail: ''
};

csrf_token: any;
  isValid: boolean = true;
  isSignupFieldsValid: boolean = true;
  mfaenabled=false;
  qrCodeImage: any;
  errorMsg: any;
  SecretCode: any;
constructor(private inactivityTimerService:InactivityTimerService,private authenticationService: AuthenticationService,private http: HttpClient, private route: ActivatedRoute,
  private elRef: ElementRef, private renderer: Renderer2,private authService: AuthssoService,private base:BaseService) {}
  ngOnInit(): void {

    
    // this.http.get('/sso/profile').subscribe((result: any) => {
    //   this.login.profile = result.profile;
    // });

  if(localStorage.getItem("signup")){
    const signup = localStorage.getItem("signup");
    if(!!signup){
      this.signup = JSON.parse(signup);
    }
  }


  let that = this
  $('.password').focus(function (this: any) {
    $('.passwordRuleDiv').toggleClass('d-none');
    that.validatePassword($(this).val());
  }).blur(function (this: any) {
    $('.passwordRuleDiv').toggleClass('d-none');
  }).keyup(function (this: any) {
    that.validatePassword($(this).val());
  });

  // $('#loginForm').submit(function (this: any) {
  //   that.setCSRFToken(this);
  //   return true;
  // });

  $('#loginFormUsingGoogle').submit(function (this: any) {
    that.setCSRFToken(this);
    return true;
  });

  $('#loginFormUsingPiv').submit(function (this:any) {
    that.setCSRFToken(this);
    return true;
  });

  var queryParamList = $(location)[0]['search'].substring(1).split('&');
  var queryParams: any = [], hash;
  for (var i = 0; i < queryParamList.length; i++) {
    hash = queryParamList[i].split('=');
    queryParams.push(hash[0]);
    queryParams[hash[0]] = hash[1];
  }

  this.csrf_token = '';

  if ('errorMessage' in queryParams) {
    var errorMessage = queryParams['errorMessage'];
    if (errorMessage === 'BadCredential' || errorMessage === 'InvalidUser') {
      this.login.errorMessage = "If you have not set up MFA, please click on the Forgot Password link and then set up MFA.";
      this.login.showErrorMessage = true;
    }
    if (errorMessage === 'RESET_PASSWORD') {
      this.login.forgotPassword = true;
      var email = queryParams['email'];
      this.login.resetPasswordForm.email = email;
    }
  }
  this.authService.signInerrorAlert.subscribe(x=>{
    this.login.errorMsg = "Username and password did not match our records."
    this.login.errorMessage = "If you have not set up MFA, please click on the Forgot Password link and then set up MFA.";
    this.login.errorMessage2 = "If you have already set up MFA, please check your credentials and reset your password if needed.";
    this.login.showErrorMessage = true;
  })
  this.authService.signInMfaerrorAlert.subscribe(x=>{
    if(x && x.name === 'EnableSoftwareTokenMFAException'){
      this.errorMsg="The verification code you entered is incorrect. Please check and try again.";
    }
    else if(x && x.name === 'NotAuthorizedException'){
      this.errorMsg="Invalid session for the user, session is expired.";
    }
    else if(x && x.name === 'CodeMismatchException'){
      this.errorMsg="The verification code you entered is incorrect. Please check and try again.";
    }
  })
  this.authService.mfaEnabled.subscribe(x=>{
    if(x){
      this.mfaenabled = x;
    }
  })
  this.authService.sendPathUrl.subscribe(x=>{
    if(x){
        this.qrCodeImage = x
    }
  })
  this.authService.getSecretCode.subscribe(x=>{
    if(x){
        this.SecretCode = x
    }
  })
}

ngAfterViewInit() {
  // Logic to decide which tab to switch to
  this.route.queryParams.subscribe(params => {
    if(params['action'] === 'signup')
    this.switchToTab('register')
  });

  if(this.signup && this.signup.signupPath){
    this.switchToTab('register');
    localStorage.removeItem('signup');
  }
}
switchToTab(tab: string) {
  if (tab === 'register') {
    this.signUpTab.nativeElement.click();
  } else if (tab === 'signin') {
    this.signInTab.nativeElement.click();
  }
}

onTabChange(tab: string) {
  this.isValid = true;
  this.isSignupFieldsValid = true;
  this.specialCharacter = true;
  this.currentTab = tab;
}

submitPasswordReset () {

  var _scope: any = this;

if( !this.validateResetPasswordConfirmationCode()){
  return;
}

if( !this.validateResetConfirmPassword() ){
  return;
}

  if(!this.validatePassword(this.login.resetPasswordForm.password)){
      _scope.login.showErrorMessage = true;
      _scope.login.errorMessage = "Password does not meet the requirement policy";
      return;
  }
  
  this.resetError();

   if (!this.login.resetPasswordForm.email) {
      this.login.resetPasswordForm.email = this.login.resetEmail;
   }
   this.authService.confirmPassword(this.login.resetPasswordForm.email,this.login.resetPasswordForm.confirmationCode,this.login.resetPasswordForm.password)
    .subscribe(function (response:any) {
      delete _scope.login.resetPasswordForm;
      if (response === 'success'){
          _scope.login.forgotPassword = false;
          _scope.login.forgotPasswordStep1 = false;
          _scope.login.message = "Password reset successful"
          _scope.message = "Password reset successful";
          _scope.login.showMessage = true;
      } else {
          _scope.login.errorMessage = response;
          _scope.login.showErrorMessage = true;
      }
  });
};

validateResetPasswordConfirmationCode() {
var isValid = true;
var confirmationCodeValue = $('.resetPasswordconfirmationCode').val();

$('.resetPasswordConfirmationCodeDiv').empty();
  if ( confirmationCodeValue.trim() == '' ){
      $('.resetPasswordConfirmationCodeDiv').removeClass('d-none');
      $('.resetPasswordConfirmationCodeDiv').append(
          $('<p>')
              .addClass('invalid')
              .append($('<span>').addClass('glyphicon glyphicon-remove'))
              .append(' Enter Verification Code')
      );
      isValid = false;
  } else {
      $('.resetPasswordConfirmationCodeDiv').empty();
      $('.resetPasswordConfirmationCodeDiv').addClass('d-none');
  }
  return isValid;
}

validateResetConfirmPassword() {
var pwdValue = $('.resetPassword').val();
var confirmPwdValue = $('.resetConfirmPassword').val();

// To check if New password value is empty and display error message accordingly.
var resetPasswordCheckDiv = $('.resetPasswordCheckDiv');
var isNewPasswordValid = this.addErrorMessage(!pwdValue, resetPasswordCheckDiv, 'New Password is required');

// To check if Confirm password value is empty or does not match with New password
// And display error message accordingly.
var resetConfirmPasswordCheckDiv = $('.resetConfirmPasswordCheckDiv');
var isConfirmPasswordValid = this.addErrorMessage((pwdValue !== confirmPwdValue || !confirmPwdValue),
                    resetConfirmPasswordCheckDiv,
                    confirmPwdValue ? 'Confirm Password Value does not match Password' : 'Confirm Password is required');

// To check if both New and Confirm Password are valid and return.
return isNewPasswordValid && isConfirmPasswordValid;
}
confirmRegisterUserSubmit(registeredUser,) {
  if (!this.validateConfirmationCode()) {
    return;
  }

  var _scope: any = this;
  this.resetError();
  console.log(_scope);
  this.authService.confirmUser(this.login.user.emailId,this.login.user.code)
  this.authService.signupVerificationConfirmEvent
  .subscribe(function (response: any) {
    if (response === 'SUCCESS') {
      _scope.login.confirmRegisterUser = false;
      _scope.login.message = "User registered successfully";
      _scope.login.showMessage = true;
      _scope.goToLoginTab();
     // delete _scope.login.user;

    } else {
      _scope.login.errorMessage = response['message'];
      _scope.login.showErrorMessage = true;
    }
  });
};

goToLoginTab() {
  $("#enableLoginTab").click();
};

validateEmail(email: string): string {
  const emailRegex = this.base.emailPattern;

  if (!email.includes('@')) {
    return "Please enter a valid email address.";
  }

  const [localPart, domainPart] = email.split('@');

  if (!localPart || !domainPart) {
    return "Please enter a valid email address.";
  }

  const invalidLocalChars = /[<>()[\]{}\/:;"'`,~]/;
  if (invalidLocalChars.test(localPart)) {
    return "Special characters are not allowed";
  }

  if (!emailRegex.test(email)) {
    return "Please enter a valid email address.";
  }

  return ""; 
}


validateConfirmationCode() {
  var isValid = true;
  var confirmationCodeValue = $('.confirmationCode').val();

  $('.confirmationCodeCheckDiv').empty();
  if (confirmationCodeValue.trim() == '') {
    $('.confirmationCodeCheckDiv').removeClass('d-none');
    $('.confirmationCodeCheckDiv').append(
      $('<p>')
        .addClass('invalid')
        .append($('<span>').addClass('glyphicon glyphicon-remove'))
        .append(' Enter Verification Code')
    );
    isValid = false;
  } else {
    $('.confirmationCodeCheckDiv').empty();
    $('.confirmationCodeCheckDiv').addClass('d-none');
  }
  return isValid;
}

onConfirmPasswrodInputBlur() {
  var resetConfirmPasswordCheckDiv = $('.resetConfirmPasswordCheckDiv');
  if (!this.login.resetPasswordForm.confirmPassword) {
    this.addErrorMessage(!this.login.resetPasswordForm.confirmPassword, resetConfirmPasswordCheckDiv, 'Confirm Password is required');
  } else {
    this.addErrorMessage(this.login.resetPasswordForm.password !== this.login.resetPasswordForm.confirmPassword,
      resetConfirmPasswordCheckDiv, 'Confirm Password Value does not match Password');
  }
}

backToLogin() {
  this.resetError();
  this.isValid=true;
  this.isSignupFieldsValid = true;
  this.specialCharacter = true;
  this.login.forgotPassword = false;
  this.login.forgotPasswordStep1 = false;
  this.login.confirmRegisterUser = false;
};

onResetPasswrodInputBlur() {
  this.addErrorMessage(!this.login.resetPasswordForm.password, $('.resetPasswordCheckDiv'), 'New Password is required');
}

addErrorMessage(isInvalid: any, divElement: any, message: any) {
  divElement.empty();
  if (isInvalid) {
    divElement.removeClass('d-none');
    divElement.append(
      $('<p>')
        .addClass('invalid')
        .append($('<span>').addClass('glyphicon glyphicon-remove'))
        .append(message)
    );
  } else {
    divElement.empty();
    divElement.addClass('d-none');
  }

  return !isInvalid;
}

requestConfirmationCode(resendCode: any,event: Event) {
  event.preventDefault();
  var _scope = this;
  this.resetError();
  let emailErrorMessage = this.validateEmail(this.login.resetPasswordForm.email);
  if(emailErrorMessage){
    _scope.login.showErrorMessage = true;
    _scope.login.errorMessage = emailErrorMessage;
    return;
  }
  this.login.resetPasswordForm.confirmationCode = '';
  this.login.resetPasswordForm.password = '';
  this.login.resetEmail = this.login.resetPasswordForm.email;
  
  
  this.authService.forgotPassword(this.login.resetPasswordForm.email)
  .subscribe(function (response: any) {
    console.log(response);
    if (response['CodeDeliveryDetails']) {
      _scope.login.forgotPasswordStep1 = false;
      if (resendCode) {
        _scope.login.message = "A new verification code has been sent to your email address.";
      } else {
        _scope.login.message = "A one-time registration verification code has been sent to your registered email address.";
      }
      _scope.login.showMessage = true;
      setTimeout(() => {
        $('.password').focus(function (this: any) {
          $('.passwordRuleDiv').toggleClass('d-none');
          _scope.validatePassword($(this).val());
        }).blur(function (this: any) {
          $('.passwordRuleDiv').toggleClass('d-none');
        }).keyup(function (this: any) {
          _scope.validatePassword($(this).val());
        });
      },1000)
      
    }else if(response === 'LimitExceededException'){
      _scope.login.errorMessage = ' Attempt limit exceeded, please try after some time.'
      _scope.login.showErrorMessage = true;
    } else {
      _scope.login.errorMessage = response['errorMessage'];
      _scope.login.showErrorMessage = true;
    }
  });
};

forgotPasswordClicked() {
 
  this.resetError();
  this.login.forgotPassword = true;
  this.login.forgotPasswordStep1 = true;
};
resetPassword(email){
  this.authService.forgotPassword(email)
}
resetError() {
  this.login.showErrorMessage = false;
  this.login.showMessage = false;
}

getCookie(name: string): string {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) {
    return match[2];
  }
  return '';
}

public submitRegisterUser(registerUser: any) {
  this.specialCharacter = true;
  this.isValid = true;
  this.isSignupFieldsValid =true;
  
  //this.isValid = this.base.usernamepattern.test(this.login.user.lastName);
  if((this.login.user.firstName.length != 0) && !this.base.usernamepattern.test(this.login.user.firstName)){
    this.specialCharacter = !/[<>]/.test(this.login.user.firstName);
    this.isValid = !this.specialCharacter && this.base.usernamepattern.test(this.login.user.firstName);
    return;
  }
  else if((this.login.user.lastName.length != 0) && !this.base.usernamepattern.test(this.login.user.lastName)){
    this.isValid = this.base.usernamepattern.test(this.login.user.lastName);
    this.specialCharacter = !/[<>]/.test(this.login.user.lastName);
    this.isValid = !this.specialCharacter && this.base.usernamepattern.test(this.login.user.firstName);
    return;
  }
  else if(this.base.nonVulnerablePattern.test(this.login.user.organization)=== false || 
  this.base.nonVulnerablePattern.test(this.login.user.password)=== false ||   this.base.nonVulnerablePattern.test(this.login.user.confirmPassword)=== false ){
    this.isSignupFieldsValid = false;
    return;
  }
  var _scope = this;
  let emailErrorMessage = this.validateEmail(this.login.user.emailId);
  if(emailErrorMessage){
    _scope.login.showErrorMessage = true;
    _scope.login.errorMessage = emailErrorMessage;
    return;
  }
  if (!this.validateConfirmPassword()) {
    //Confirm password validation should happen only after submitting Registration form 
    this.addValidationEventToConfirmPassword();
    return;
  }
  if(registerUser.emailId.includes('heart.org')){
       this.authService.loginwithad('AHA_LOGIN')
  }
 
  if (!this.validatePassword(registerUser.password)) {
    _scope.login.showErrorMessage = true;
    _scope.login.errorMessage = "Password does not meet the requirement policy";
    return;
  }
  if (registerUser.organization === "") {
    _scope.login.showErrorMessage = true;
    _scope.login.errorMessage = "Please enter valid organization";
    return;
  }
  if (registerUser.firstName === "") {
    _scope.login.showErrorMessage = true;
    _scope.login.errorMessage = "Please enter valid firstname";
    return;
  }
  if (registerUser.lastName === "") {
    _scope.login.showErrorMessage = true;
    _scope.login.errorMessage = "Please enter valid lastname";
    return;
  }
  let body = { ...registerUser };
  delete body.code;


  // this.http.post('/sso/register/submit', $.param(body), {
  //   headers: {
  //     'Accept': 'application/json, text/plain, */*',
  //     'X-XSRF-TOKEN': this.getCookie('XSRF-TOKEN'),
  //     'content-type': 'application/x-www-form-urlencoded'
  //   }
  // })
  this.authService.signUp(body)
  this.authService.signupSuccessEvent.
  subscribe(function (response: any) {
    if (response != '') {
      //show confirm code page
      this.registeredUser=response;
      _scope.login.confirmRegisterUser = true;
      _scope.login.message = "A one-time registration verification code has been sent to your registered email address.";
      _scope.login.showMessage = true;
    } 
  });
  this.authService.signupFailEvent.subscribe(x=>{
    _scope.login.errorMessage = x;
    _scope.login.showErrorMessage = true;
  })
};

addValidationEventToConfirmPassword() {
  let that = this;
  $('.confirmPassword').focus(function () {
    that.validateConfirmPassword();
  }).keyup(function () {
    that.validateConfirmPassword();
  });

}

validateConfirmPassword() {
  var isValid = true;
  var pwdValue = $('.password').val();
  var confirmPwdValue = $('.confirmPassword').val();

  $('.confirmPasswordCheckDiv').empty();
  if (pwdValue !== confirmPwdValue) {
    $('.confirmPasswordCheckDiv').removeClass('d-none');
    $('.confirmPasswordCheckDiv').append(
      $('<p>')
        .addClass('invalid')
        .append($('<span>').addClass('glyphicon glyphicon-remove'))
        .append(' Confirm Password Value does not match Password')
    );
    $('.passwordSubmitBtn').prop("disabled", true);
    isValid = false;
  } else {
    $('.confirmPasswordCheckDiv').empty();
    $('.confirmPasswordCheckDiv').addClass('d-none');
    $('.passwordSubmitBtn').prop("disabled", false);
  }
  return isValid;
}


validatePassword(pwdValue: any) {

  var specialCharRule = ' At least <strong>one special</strong> character';
  var minLengthRule = ' Must be at least <strong>12 characters</strong>';
  var upperCaseRule = ' At least <strong>one upper-case</strong> character';
  var lowerCaseRule = ' At least <strong>one lower-case</strong> character';
  var numberRule = ' At least <strong>one number</strong>';
  var isValid = true;

  $('.passwordRuleList').empty();
  if (pwdValue.length < 12) {
    isValid = false;
    this.appendRuleSet('invalid', minLengthRule);
  } else {
    this.appendRuleSet('valid', minLengthRule);
  }

  if (pwdValue.match(/[A-Z]/)) {
    this.appendRuleSet('valid', upperCaseRule);
  } else {
    isValid = false;
    this.appendRuleSet('invalid', upperCaseRule);
  }

  if (pwdValue.match(/[a-z]/)) {
    this.appendRuleSet('valid', lowerCaseRule);
  } else {
    isValid = false;
    this.appendRuleSet('invalid', lowerCaseRule);
  }

  if (pwdValue.match(/\d/)) {
    this.appendRuleSet('valid', numberRule);
  } else {
    isValid = false;
    this.appendRuleSet('invalid', numberRule);
  }

  if (pwdValue.match(/[@#$%&!]/)) {
    this.appendRuleSet('valid', specialCharRule);
  } else {
    isValid = false;
    this.appendRuleSet('invalid', specialCharRule);
  }

  return isValid;
}

appendRuleSet(status: any, rule: any) {
  if (status === 'valid') {
    $('.passwordRuleList').append($('<li>').addClass(status)
      .append($('<span>').addClass('glyphicon glyphicon-ok'))
      .append(rule)
    );
  } else {
    $('.passwordRuleList').append($('<li>').addClass(status)
      .append($('<span>').addClass('glyphicon glyphicon-remove'))
      .append(rule)
    );
  }

}

public setCSRFToken(scope: any) {
  scope.csrf_token = '';
  var name = 'XSRF-TOKEN';
  var cookieValue = null;
  if (document.cookie && document.cookie != '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = $.trim(cookies[i]);
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) == (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        scope.csrf_token = cookieValue;
        break;
      }
    }
  }
  $("input[name=_csrf]").val(scope.csrf_token);
}


onLogin() {
  var _scope = this;
  let emailErrorMessage = this.validateEmail(this.username);
  if(emailErrorMessage){
    _scope.login.showErrorMessage = true;
    _scope.login.errorMessage = emailErrorMessage;
    return;
  }
  this.isValid=true;
  this.isSignupFieldsValid=true;
  if((this.username.length != 0) && !this.base.emailPattern.test(this.username)){
    this.isSignupFieldsValid = this.base.emailPattern.test(this.username);
    return;
  }
  if((this.password.length != 0) && !this.base.nonVulnerablePattern.test(this.password)){
   this.isSignupFieldsValid = this.base.nonVulnerablePattern.test(this.password);
   return;
  }
//this.enableTwoFactor = true;
  this.authService.signIn(this.username, this.password);
  }
  loginWithAD(type){
    this.authService.loginwithad(type)
  }
  signOut(){
    this.authenticationService.logout(true);
    this.inactivityTimerService.stopInactivityTimer();
  }
  updateauthCode(code){
    this.authService.totpAuthentication(code)
  }
  selectedVerificationType(type){
    this.authService.selectTypeOFMFA(type)
  }
}