import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../../app/shared/services/auth.service';
import { AuthssoService } from '../../../../app/shared/services/authsso.service';
import { InactivityTimerService } from '../../../../app/shared/services/inactivity-timer-service.service';
import { SharedModule } from '../../../../app/shared/shared.module';

@Component({
  selector: 'app-ad-loggedin',
  standalone:true,
  imports: [SharedModule],
  templateUrl: './ad-loggedin.component.html',
  styleUrls: ['./ad-loggedin.component.scss'],

})
export class AdLoggedinComponent implements OnInit {
  showError = false;
  constructor(private inactiveService:InactivityTimerService,private auth: AuthenticationService, public router: Router, private route: ActivatedRoute,private authsso:AuthssoService) { }

  ngOnInit() {
    const authData = this.authsso.getAzureadlogintokens()
    if(authData != undefined){

    this.auth.getAzureToken(authData).subscribe({
      next: (user) => {
            this.inactiveService.startInactivityTimer()
            let redirectRoute = '/workspace/launch-workspace';
            if(JSON.parse(localStorage.getItem("path"))){
              redirectRoute = JSON.parse(localStorage.getItem("path"));
            }
            this.router.navigate([redirectRoute]); 
      },
      error: (err) => {
        this.showError = true;
        console.error('Error storing token:', err);
      }
    });
  }
  }

}
