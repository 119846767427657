import { Component } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss'],
  imports: [SharedModule],
  standalone: true
})
export class TosComponent {

  constructor() { }

}
