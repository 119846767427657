export const environment = {
  production: true,
  ssoURL: 'https://pmp-dev.heartblr.org/sso',
  apiEndpointUrl: 'https://pmp-dev-api.heartblr.org/',
  adminRole: 'Sec-Azr-AADApp_AWS_APP_PMP_Researcher_Dev-Adm-Users',
  supportRole: 'Sec-Azr-AADApp_AWS_APP_PMP_Researcher_Dev-RO-Users',
  google_analytics: 'GTM-NL44KZQX',
  cognito: {
    UserPoolId: 'us-east-1_fQv2xDRSW',
    ClientId: '4f12vcr8ed71h4f6s7kpugbb1s',
    region: 'us-east-1',
    cognitoDomain:"aha-shared-dev-v2.auth.us-east-1.amazoncognito.com",
    identityProvider:"keyCloakAddiOidcProvider"
  },
};
