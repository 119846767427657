<h1 mat-dialog-title>Help</h1>
<div mat-dialog-content>
  <ul>
    <li>
      For PMP questions, check out the <a href="https://pmp.heart.org/documentation/PMP-FAQ.html">FAQ</a> and <a href="https://pmp.heart.org/documentation/user-guide/index.html">PMP User Guide</a>
    </li>
    <li>
      For technical problems or installation issues, <a href="https://pmp.heart.org/technical-support">file a ticket</a> with Technical Support
    </li>
    <li>
      For data questions, account questions, or additional support, please <a href="https://pmp.heart.org/contact">Contact Us</a>
    </li>
    </ul>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Close</button>
</div>