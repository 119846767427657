import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from '../../../../app/shared/services/loaderService';
import { AuthssoService } from '../../../../app/shared/services/authsso.service';
@Component({
selector: 'app-two-step-authentication',
standalone:true,
templateUrl: './two-step-autentication.component.html',
styleUrls: ['./two-step-autentication.component.scss'],
imports: [FormsModule,CommonModule],
})
export class TwoStepAutentication {
  @Output() updateauthCode: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedVerificationType: EventEmitter<any> = new EventEmitter<any>();
  showSecret:boolean = false;
  showAuthenticator:boolean = true;
  @Input() email!: string;
  @Input() qrCode:any
  @Input() errorMsg;
  @Input() SecretCode;
  @Input() mfaenabled;
  errorMessage:string = "";
  authCode:any="";
  displayQR = false;
  enableQR=false;
constructor(private loaderService:LoaderService,private router: Router,private authsso:AuthssoService) {}
ngOnInit(): void {
  if (this.qrCode != undefined) {
      this.displayQR = true;
      console.log(this.displayQR)
  }else{
    this.displayQR = false;
     console.log(this.displayQR)
  }
}
ngOnChanges(): void {
  this.errorMessage = this.errorMsg;
}
toggleSecret(){
  this.showSecret = !this.showSecret;
}

toggleAuthenticate(codeInputs: HTMLElement,value){
  this.authCode = "";
  this.errorMessage = "";
  this.clearInputs(codeInputs);
  this.selectedVerificationType.emit(value)
  this.showAuthenticator=!this.showAuthenticator;
}
moveToNext(event: KeyboardEvent, index: number) {
  const input = event.target as HTMLInputElement;
  if (input.value.length > 1) {
    input.value = input.value.slice(0, 1);  
  }
  if (event.key === 'Backspace') {
    if (input.value === '' && index > 0) {
      const prevInput = input.previousElementSibling as HTMLInputElement;
      if (prevInput) {
        prevInput.focus();
      }
    }
  } else if (input.value.length === 1) {
    const nextInput = input.nextElementSibling as HTMLInputElement;
    if (nextInput) {
      nextInput.focus();
    }
  }
}

revealQRCode(){
  this.enableQR=true;
}
combineValues(codeInputs: HTMLElement) {
  this.loaderService.showLoader();
  const inputElements = codeInputs.querySelectorAll<HTMLInputElement>('input');
  const combinedValue = Array.from(inputElements)
    .map((input) => input.value)
    .join('');

  if (combinedValue.length === 6 && /^\d+$/.test(combinedValue)) {
    //alert('Valid Code: ' + combinedValue);
    this.authCode = combinedValue;
    this.updateauthCode.emit(this.authCode);
  } else {
   // alert('Invalid Code: The code must be exactly 6 digits.');
   this.errorMessage = "Invalid Code: The code must be exactly 6 digits."
  }

  
}
navigateHelp(){
  this.router.navigate(['/help']);
}
maskEmail(email: string): string {
  const [localPart, domain] = email.split('@');
  if (!localPart || !domain) {
    return email; 
  }

  const visibleStart = localPart.slice(0, 2); 
  const maskedLocalPart = `${visibleStart}${'*'.repeat(localPart.length - 2)}`; 

  const domainName = domain.split('.')[0]; 
  const domainExtension = domain.slice(domain.indexOf('.')); 
  const maskedDomain = `${domainName[0]}**`; 

  return `${maskedLocalPart}@${maskedDomain}${domainExtension}`;
}
clearInputs(codeInputs: HTMLElement) {
  const inputs = codeInputs.querySelectorAll('input');
  inputs.forEach((input) => {
    (input as HTMLInputElement).value = '';
  });
  // Focus on the first input after clearing
  if (inputs[0]) {
    (inputs[0] as HTMLInputElement).focus();
  }
}
cancelMFA(){
  this.authsso.mfaEnabled.next(false);
  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    this.router.navigate(['/sso']);
});
}
}