<app-title-subtitle [title]="'Tools & Features'"
  [subtitle]="'Ready-Made Resources. Artificial Intelligence. Flexible Customization.'"></app-title-subtitle>
<div class="row text-center">
  <div class="col-12 ">
    <button (click)="scrollToElement('contactForm')" class="btn main-section-primary-btn btn-transparent-red">REQUEST A DEMO</button>
  </div>
</div>
<div class="container tools-content-block">
  <div class="row">
    <div class="col-12 col-lg-6">
      <img src="assets/images/tools/tools.svg" class="tools-img" />
    </div>
    <div class="col-12 col-lg-6">
      <div class="tools-title">Designed for ease of use</div>
      <div class="tools-subtitle">The Precision Medicine Platform’s secure HIPAA compliant and FedRAMP certified
        workspace environments allow researchers to share even the largest datasets and find fast, accurate answers in minutes, rather than months or years.<br /> <a target="_blank" href="https://pmp.heart.org/workspace/about">Detailed Information on Tools & Applications</a></div>

        <div style="margin-top: 30px;"></div>
        <div class="row row-no-margin">

          <div class="pricing-content-block col-6">
            <div class="pricing-line"></div>
            <div class="pricing-content">
              <div class="pricing-subtitle">Jupyter Notebook</div>
            </div>
          </div>

          <div class="pricing-content-block col-6">
            <div class="pricing-line"></div>
            <div class="pricing-content">
              <div class="pricing-subtitle">Keras</div>
            </div>
          </div>

        </div>


        <div class="row row-no-margin">

          <div class="pricing-content-block col-6">
            <div class="pricing-line"></div>
            <div class="pricing-content">
              <div class="pricing-subtitle">Tensor Flow</div>
            </div>
          </div>

          <div class="pricing-content-block col-6">
            <div class="pricing-line"></div>
            <div class="pricing-content">
              <div class="pricing-subtitle">Pandas</div>
            </div>
          </div>

        </div>


        <div class="row row-no-margin">

          <div class="pricing-content-block col-6">
            <div class="pricing-line"></div>
            <div class="pricing-content">
              <div class="pricing-subtitle">SAS</div>
            </div>
          </div>

          <div class="pricing-content-block col-6">
            <div class="pricing-line"></div>
            <div class="pricing-content">
              <div class="pricing-subtitle">Shiny</div>
            </div>
          </div>

        </div>


        <div class="row row-no-margin">
          <div class="pricing-content-block col-6">
            <div class="pricing-line"></div>
            <div class="pricing-content">
              <div class="pricing-subtitle">Plotly</div>
            </div>
          </div>

        </div>
        

        
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-12 col-lg-6">
      <app-mini-box [title]="'Machine Learning'"
        [subtitle]="'Machine learning is an application of artificial intelligence that allows computers and systems to evolve behaviors based on empirical data. The Precision Medicine Platform’s machine learning tools have the ability to automatically learn and improve from experience without being explicitly programmed supplies researchers with more information and faster analysis.'"></app-mini-box>
    </div>
    <div class="col-12 col-lg-6">
      <app-mini-box [title]="'Cloud Computation'"
        [subtitle]="'Don’t get hamstrung by your computer’s limited hardware or processing power anymore. The platform’s cloud-based system allows users to harness significant processing capacity, without bogging down personal computers. Even better? Users only pay for the resources that they use.'"></app-mini-box>
    </div>
  </div>
</div>

<div class="spacer-50px"></div>

<div class="container">
  <div class="row">
    <div class="col-12 col-lg-12">
      <app-contact-box [fontSize]="36" [color]="'#222328'" theme="light" titleFont="36px"
        title="Looking for more specific answers?"
        ></app-contact-box>
    </div>
  </div>
</div>